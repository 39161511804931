import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { AcceptanceActions } from 'APP/actions/AcceptanceActions';
import { OptimizelyData } from 'APP/actions/actions.interfaces';
import { Analytics } from 'APP/Analytics';
import { hasComplementsToUpload } from 'APP/components/FileUpload/FileUploadHelpers';
import { trackOptimizelyConsent } from 'APP/components/Offers/offerHelpers/businessOfferAnalitycs';
import { ApplicationConstants } from 'APP/constants/ApplicationConstants';
import { OfferConstants } from 'APP/constants/OfferConstants';
import { fetchApplicationsAction } from 'APP/feature/applications/applicationsSliceActions';
import { getCookie } from 'APP/helpers/CookieHelpers';
import { BizApplication, IOwner } from 'APP/interfaces/business/application';
import { BizOffer } from 'APP/interfaces/feature/business/offers.interfaces';
import { IRootState, MyReactSDKClient } from 'APP/interfaces/general.interfaces';
import { Routes } from 'APP/Routes';
import Router from 'next/router';

export const postBeneficialOwners = createAsyncThunk(
    'businessOffer/postBeneficialOwners',
    async (params: { beneficial_owners: IOwner[]; application: BizApplication }, thunkAPI) => {
        try {
            const response = await AcceptanceActions.postBeneficialOwners({
                beneficial_owners: params.beneficial_owners.map(owner => ({
                    application_id: params.application.id,
                    application_revision: params.application.revision,
                    id_nr: owner.ssn,
                    email: owner.email,
                    extent_of_control: owner.extent_of_control,
                })),
            });
            return response;
        } catch (error: unknown) {
            Sentry.captureException(error);
            if (error instanceof Error) {
                return thunkAPI.rejectWithValue({ message: error.message });
            } else {
                return thunkAPI.rejectWithValue({ message: 'An unknown error occurred' });
            }
        }
    }
);

export const postAcceptanceOffer = createAsyncThunk(
    'businessOffer/postAcceptanceOffer',
    async (
        {
            application,
            offer,
            optimizelyData,
            optimizely,
        }: {
            application: BizApplication;
            offer: BizOffer;
            optimizelyData: OptimizelyData; // take it form redux store instead (when every component is connected to redux store)
            optimizely: MyReactSDKClient;
        },
        thunkAPI
    ) => {
        try {
            const consentCookie = getCookie('consent');
            const state = thunkAPI.getState() as IRootState;
            const { parameters, businessRequirements } = state.businessOfferPageSlice;
            const response = await AcceptanceActions.post({
                application_id: application.id,
                application_type: application.type,
                revision: application.revision,
                responses: [{ id: offer.id, type: OfferConstants.TYPES.BUSINESS }],
                parameters: { ...parameters, ...businessRequirements },
                extra_parameters: {
                    optimizely: optimizelyData,
                },
            });
            if (!hasComplementsToUpload(offer.complements)) {
                // add test for this
                thunkAPI.dispatch(fetchApplicationsAction());
                Router.push(Routes.getAcceptanceRoute(application.id));
            }

            Analytics.pushOfferAccepted({
                applicationInfo: Analytics.createApplicationInfo(application),
                offerInfo: Analytics.createOfferInfo(offer),
            });
            trackOptimizelyConsent(optimizely, consentCookie);
            return response;
        } catch (error: unknown) {
            Sentry.captureException(error);
            if (error instanceof Error) {
                return thunkAPI.rejectWithValue({ message: error.message });
            } else {
                return thunkAPI.rejectWithValue({ message: 'An unknown error occurred' });
            }
        }
    }
);

export const onAcceptOfferAction = createAsyncThunk(
    'acceptance/onAcceptOffer',
    async (
        {
            optimizelyData,
            optimizely,
        }: {
            optimizelyData: OptimizelyData;
            optimizely: MyReactSDKClient;
        },
        thunkAPI
    ) => {
        const state = thunkAPI.getState() as IRootState;
        const { application, offer, beneficial_owners } = state.businessSlice;
        const owners = beneficial_owners.owners;

        try {
            if (owners?.length) {
                await thunkAPI
                    .dispatch(
                        postBeneficialOwners({
                            beneficial_owners: owners,
                            application,
                        })
                    )
                    .unwrap();
            }

            await thunkAPI.dispatch(
                postAcceptanceOffer({
                    application,
                    offer,
                    optimizelyData,
                    optimizely,
                })
            );

            const includesUploadComplements = hasComplementsToUpload(offer.complements);
            return { status: 'success', hasComplements: includesUploadComplements };
        } catch (error: unknown) {
            Sentry.captureException(error);
            if (error instanceof Error) {
                return thunkAPI.rejectWithValue({ message: error.message });
            } else {
                return thunkAPI.rejectWithValue({ message: 'An unknown error occurred' });
            }
        }
    }
);

export const postAcceptanceOfferPrivateBusiness = createAsyncThunk(
    'privateBusinessOffer/postAcceptanceOffer',
    async (
        { optimizelyData, optimizely }: { optimizelyData: OptimizelyData; optimizely: MyReactSDKClient },
        thunkAPI
    ) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const { parameters, businessRequirements } = state.businessOfferPageSlice;
            const { offer, application } = state.businessSlice;
            const consentCookie = getCookie('consent');
            const hasComplements = hasComplementsToUpload(offer.complements);

            const response = await AcceptanceActions.post({
                application_id: application.id,
                application_type: ApplicationConstants.TYPE.BUSINESS,
                revision: application.revision,
                responses: [{ id: offer.id, type: OfferConstants.TYPES.BUSINESS }],
                parameters: { ...parameters, ...businessRequirements },
                extra_parameters: {
                    optimizely: optimizelyData,
                },
            });
            if (!hasComplements) {
                thunkAPI.dispatch(fetchApplicationsAction());
                Router.push(Routes.getAcceptanceRoute(application.id));
            }
            Analytics.pushOfferAccepted({
                applicationInfo: Analytics.createApplicationInfo(application),
                offerInfo: Analytics.createOfferInfo(offer),
            });
            trackOptimizelyConsent(optimizely, consentCookie);

            return { response, hasComplements };
        } catch (error: unknown) {
            Sentry.captureException(error);
            if (error instanceof Error) {
                return thunkAPI.rejectWithValue({ message: error.message });
            } else {
                return thunkAPI.rejectWithValue({ message: 'An unknown error occurred' });
            }
        }
    }
);
