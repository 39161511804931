import { ApiStatus } from 'APP/constants/ApiStatusConstants';
import { IAcceptance } from 'APP/interfaces/applications.interfaces';
import { IRevisionSlice } from 'APP/interfaces/general.interfaces';

export const revisionInitialState: IRevisionSlice = {
    step: 1,
    status: 'idle',
    stepName: 'index',
    hasInitiatedLoanValues: false,
    statusComponentShowing: false,
    customerRevisionAllowed: true,
    application: {
        applicants: [],
        id: '',
        customer_revision_allowed: false,
        type: '',
        products: [],
        revision: 0,
        status: '',
        acceptance: {} as IAcceptance,
        potential_business_customer: false,
        company: {
            type: 'EN',
            name: undefined,
        },
        offers: [],
        smart_sorted_offers: [],
        withdrawn_offers: [],
        denials: [],
        created_at: '',
        responses_expired_at: '',
        campaigns: [],
        meta_property_questions_allowed: false,
        meta_created_by_gui: false,
    },
    coApplicant: null,
    hasCoApplicantInitally: false,
    totalLoanAmount: 0,
    initialTotalLoanAmount: 0,
    solveLoanAmount: 0,
    initialSolveLoanAmount: 0,
    amortizeLength: 0,
    initialAmortizeLength: 0,
    accommodationCost: 0,
    initialAccommodationCost: 0,
    accommodationType: '',
    smartRevisionData: {
        apiStatus: ApiStatus.IDLE,
        recommendedTotalLoanAmount: 0,
        recommendedAmountToCollect: 0,
        recommendedAmortizeLength: 0,
        apiPostEventStatus: ApiStatus.IDLE,
    },
    ['coApplicant.email']: '',
    ['coApplicant.phone']: '',
    ['coApplicant.ssn']: '',
    ['coApplicant.civilState']: '',
    ['coApplicant.numberOfChildren']: '',
    ['coApplicant.accommodation']: '',
    ['coApplicant.accommodationCost']: '',
    ['coApplicant.employment']: '',
    ['coApplicant.monthlyIncome']: '',
    ['coApplicant.hiredSinceYear']: '',
    ['coApplicant.hiredSinceMonth']: '',
    ['coApplicant.hiredToYear']: '',
    ['coApplicant.hiredToMonth']: '',
    ['coApplicant.company']: '',
    currentLabel: 'Medsökandes personuppgifter',
    nextLabel: 'Bostad & Arbete',
    revisionBoxShow: false,
};
