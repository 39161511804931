import { createSlice } from '@reduxjs/toolkit';
import { ApiStatus } from 'APP/constants/ApiStatusConstants';

import { fetchMorgageApplicationsAction } from './mortgagesSliceActions';

const initialState = {
    mortgageApplications: [],
    apiStatus: ApiStatus.IDLE,
};

const mortgagesSlice = createSlice({
    name: 'mortgageApplications',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchMorgageApplicationsAction.fulfilled, (state, action) => {
                state.apiStatus = ApiStatus.COMPLETED;
                state.mortgageApplications = action.payload.data;
            })
            .addCase(fetchMorgageApplicationsAction.pending, state => {
                state.apiStatus = ApiStatus.STARTED;
            })
            .addCase(fetchMorgageApplicationsAction.rejected, state => {
                state.apiStatus = ApiStatus.FAILED;
            });
    },
});

export default mortgagesSlice.reducer;
