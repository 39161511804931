import { BankIdColorIcon, Box, Button, Stack, StatusCircle, Typography } from '@lendoab/aphrodite';
import React, { ReactElement } from 'react';

export interface PendingProps {
    onCancel: () => void;
    message: string;
}

export default function Pending(props: PendingProps): ReactElement {
    const { onCancel, message } = props;

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            paddingX={['medium', 'medium', '3xl']}
            paddingTop={['medium', 'medium', '3xl']}
            paddingBottom={['xl', 'xl', '3xl']}
            style={{ minHeight: '100%' }}
        >
            <Stack paddingTop={['7xl', '7xl', 'xl']} alignItems="center" space={['medium', 'medium', '2xl']}>
                <StatusCircle size="medium" status="loading" color="blue-30">
                    <BankIdColorIcon size={['xl', 'xl', '4xl']} />
                </StatusCircle>

                <Typography.Title level={3} color="gray-10" align="center">
                    {message}
                </Typography.Title>
            </Stack>

            <Button fluid onClick={onCancel} marginTop={['medium', 'medium', '2xl']}>
                Avbryt
            </Button>
        </Box>
    );
}
