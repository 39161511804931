import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { CampaignActions } from 'APP/actions/CampaignActions';
import { ApplicationHelpers } from 'APP/helpers/ApplicationHelpers';
import { IApplication } from 'APP/interfaces/applications.interfaces';

import { fetchApplicationsAction } from '../applications/applicationsSliceActions';

export interface ICampaignValues {
    clearingNumber: string;
    accountNumber: string;
}

export const updateCampaign = createAsyncThunk(
    'campaign/post',
    async (
        {
            values,
            application,
        }: {
            values: ICampaignValues;
            application: IApplication;
        },
        thunkAPI
    ) => {
        try {
            const response = await CampaignActions.patch(
                application.id,
                ApplicationHelpers.getCampaign(application)?.id,
                {
                    clearing_number: values.clearingNumber,
                    account_number: values.accountNumber,
                }
            );
            thunkAPI.dispatch(fetchApplicationsAction());
            return response;
        } catch (err: unknown) {
            Sentry.captureException(err);
            return err;
        }
    }
);
