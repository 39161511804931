import { BankIdColorIcon, Box, Stack, StatusCircle, Typography } from '@lendoab/aphrodite';
import React, { ReactElement } from 'react';

export interface SuccessProps {
    message: ReactElement | string;
}

export default function Success(props: SuccessProps): ReactElement {
    const { message } = props;

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            paddingX={['medium', 'medium', '3xl']}
            paddingTop={['medium', 'medium', '3xl']}
            paddingBottom={['xl', 'xl', '3xl']}
            style={{ minHeight: '100%' }}
        >
            <Stack paddingTop={['7xl', '7xl', 'xl']} alignItems="center" space={['medium', 'medium', '2xl']}>
                <StatusCircle size="medium" status="complete" gradientTo="#29C466" gradientFrom="#059142">
                    <BankIdColorIcon size={['xl', 'xl', '4xl']} />
                </StatusCircle>

                <Typography.Title level={3} align="center" color="gray-10">
                    {message}
                </Typography.Title>
            </Stack>
        </Box>
    );
}
