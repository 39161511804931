import { PROGRESS, VIEWS } from '@frontend/bank-id/enums';
import { cancelAction, pollAction, requestAction } from '@frontend/bank-id/feature/actions';
import { getViewFromProgress } from '@frontend/bank-id/helpers';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface State {
    view?: VIEWS;
    progress?: PROGRESS;
    details: object;
    qrData?: string | null;
    reference?: string;
    autoStartToken?: string | null;
    pollCount: number;
}

const initialState = {
    view: undefined,
    progress: undefined,
    details: {},
    qrData: undefined,
    reference: undefined,
    autoStartToken: undefined,
    pollCount: 0,
} satisfies State as State;

const slice = createSlice({
    name: 'bank-id',
    initialState,
    reducers: {
        setView(state, { payload }: PayloadAction<VIEWS>) {
            state.view = payload;
        },
        resetState(_, { payload }: PayloadAction<VIEWS.DEVICE_PROMPT | VIEWS.QR_CODE_PROMPT | undefined>) {
            return {
                ...initialState,
                view: payload,
            };
        },
    },
    extraReducers: builder => {
        // ----- request action ----
        builder.addCase(requestAction.pending, () => {});
        builder.addCase(requestAction.fulfilled, (state: State, { payload }) => {
            state.qrData = payload?.data?.qr_data;
            state.reference = payload?.data?.reference;
            state.autoStartToken = payload?.data?.auto_start_token;
            state.pollCount++;
        });
        builder.addCase(requestAction.rejected, (state: State) => {
            return {
                ...initialState,
                view: VIEWS.ERROR,
            };
        });

        // ----- poll action ----
        builder.addCase(pollAction.pending, () => {});
        builder.addCase(pollAction.fulfilled, (state: State, { payload }) => {
            state.qrData = payload?.data?.qr_data;
            state.progress = payload.data?.progress;
            state.view = getViewFromProgress(payload.data?.progress, state.view);

            switch (payload.data?.progress) {
                case PROGRESS.CREATED:
                case PROGRESS.USER_SIGN:
                case PROGRESS.OUTSTANDING_TRANSACTION:
                    state.pollCount++;
                    break;
                case PROGRESS.COMPLETE:
                    if (payload.data?.details) {
                        state.details = payload.data?.details;
                    }
                    state.reference = undefined;
                    break;
                default:
                    state.reference = undefined;
            }
        });
        builder.addCase(pollAction.rejected, (state: State) => {
            return {
                ...initialState,
                view: VIEWS.ERROR,
            };
        });

        // ----- cancel action ----
        builder.addCase(cancelAction.pending, (state: State) => {
            state.reference = undefined;
        });
        builder.addCase(cancelAction.fulfilled, () => {});
        builder.addCase(cancelAction.rejected, () => {});
    },
});

export const { setView, resetState } = slice.actions;
export default slice.reducer;
