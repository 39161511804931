import * as Sentry from '@sentry/node';

/**
 * @param {string} token
 */
function setCSRFToken(token) {
    try {
        window.localStorage.setItem('csrf', token);
    } catch (err) {}
}

/**
 * @returns {string}
 */
function getCSRFToken() {
    try {
        const csrf = window.localStorage.getItem('csrf');
        if (!csrf) return '';
        return csrf;
    } catch (err) {
        return '';
    }
}

class FetchError extends Error {
    constructor(message, statusCode, statusText, data) {
        super(message);

        this.statusCode = statusCode;
        this.statusText = statusText;
        this.data = data;
    }
}

/**
 * Performs a http request
 * @param {string} endpoint
 * @param {Object} param1
 */
export function client(endpoint, { body, ...customConfig } = {}) {
    const headers = {};

    const csrfTokenHeader = getCSRFToken();

    const config = {
        ...customConfig,
        credentials: 'same-origin',
        cache: 'no-store',
        headers: {
            Pragma: 'no-cache',
            'Cache-Control': 'no-cache',
            ...(csrfTokenHeader !== '' && { 'x-csrf-token': csrfTokenHeader }),
            'content-type': 'application/json',
            ...headers,
            ...customConfig.headers,
        },
    };

    if (body) {
        config.body = JSON.stringify(body);
    }

    return fetch(`/api/${endpoint}`, config).then(async response => {
        const csrfHeader = response.headers.get('x-csrf-token');
        if (csrfHeader) {
            Sentry.addBreadcrumb({
                message: `Fetch CSRF token. Correlation ID: ${response.headers.get('x-correlation-id')}`,
                level: 'error',
            });
            setCSRFToken(csrfHeader);
        }

        // if the response is a 204 no content is recieved, return empty object
        if (response.status === 204) {
            return {};
        }

        const data = await response.json();

        if (response.ok) {
            return data;
        } else {
            Sentry.setTag('correlation-id', response.headers.get('x-correlation-id'));
            if (response.status !== 401 && response.status !== 404) {
                Sentry.captureEvent({
                    message: `Fetch request against: /api/${endpoint} failed.`,
                    level: 'fatal',
                    tags: {
                        endpoint: `/api/${endpoint}`,
                        statusCode: response.status,
                        statusText: response.statusText,
                        data: data,
                    },
                });
            }
            if (endpoint === '/events' && response.status === 400) {
                //To not show modal error message in the screen
                return {};
            }

            throw new FetchError(
                data && data.message ? data.message : response.statusText,
                response.status,
                response.statusText,
                data
            );
        }
    });
}

export const Transport = {
    /**
     * Performs a get request
     * @param {string} endpoint
     */
    get(endpoint) {
        return client(endpoint, { method: 'GET' });
    },

    /**
     * Performs a post request
     * @param {string} endpoint
     * @param {Object} data
     */
    post(endpoint, data) {
        return client(endpoint, { method: 'POST', body: data });
    },

    /**
     * Performs a put request
     * @param {string} endpoint
     * @param {Object} data
     */
    put(endpoint, data) {
        return client(endpoint, { method: 'PUT', body: data });
    },

    /**
     * Performs a patch request
     * @param {string} endpoint
     * @param {Object} data
     */
    patch(endpoint, data) {
        return client(endpoint, { method: 'PATCH', body: data });
    },

    /**
     * Performs a delete request
     * @param {string} endpoint
     */
    delete(endpoint) {
        return client(endpoint, { method: 'DELETE' });
    },
};
