import { Integration } from 'APP/components/Offers/Business/Monto/ChooseIntegration';
import { ApiStatus, MontoConnectionApiStatus } from 'APP/constants/ApiStatusConstants';
import { ComplementsFlow, MontoFieldFormat, MontoSteps } from 'APP/constants/montoConstants';

export const montoInitialState = {
    apiStatuses: {
        integration: ApiStatus.IDLE,
        createdMontoConnectionStatus: ApiStatus.IDLE,
        currentMontoConnectionStatus: MontoConnectionApiStatus.PENDING,
    },
    complementsFlow: ComplementsFlow.Idle,
    isOfferPageFlow: null,
    step: MontoSteps.Idle,
    selectedIntegration: {},
    selectedIntegrationFields: [],
    integrations: [],
    createdMontoConnectionData: {},
};
type ComplementsFlowType = 'Idle' | 'FileUpload' | 'Monto';

export interface IMontoState {
    apiStatuses: {
        integration: number;
        createdMontoConnectionStatus: number;
        currentMontoConnectionStatus: string;
    };
    complementsFlow: ComplementsFlowType;
    isOfferPageFlow: boolean;
    step: string;
    selectedIntegration: Integration;
    integrations: Integration[];
    createdMontoConnectionData: {
        connection_info: {
            connection_id: string;
            connect_url: string | URL;
        };
    };
}

export interface IntegrationField {
    format?: typeof MontoFieldFormat;
    key: string;
    label?: string;
    value: null | string;
}

interface ConnectionInfo {
    connection_id: string;
    status: string;
    connect_url: string;
    integration: {
        key: string;
        label: string;
    };
}

export interface ConnectionData {
    id: string;
    monto_company_id: string;
    lendo_company_id: string;
    response_id: string;
    created_at: string;
    updated_at: string;
    connection_info: ConnectionInfo;
}
