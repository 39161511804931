import { createSlice } from '@reduxjs/toolkit';
import { ApiStatus } from 'APP/constants/ApiStatusConstants';
import { CampaignHelpers, doesCampaignIdExist } from 'APP/helpers/CampaignHelpers';

import { updateCampaign } from './campaignSliceActions';
import { campaignSliceInitialState } from './campaignSliceInitialState';

const initialState = campaignSliceInitialState;

const campaignSlice = createSlice({
    name: 'campaign',
    initialState,
    reducers: {
        setExistingCampaign: (state, action) => {
            state.existingCampaign = action.payload;
            state.campaignDataStatus = CampaignHelpers.getCampaignAccountDataStatus(action.payload);
        },
        setHasCampaign: (state, action) => {
            state.hasCampaign = action.payload;
        },
        updateActiveCampaigns: (state, action) => {
            const { campaigns, meta_created_by_gui } = action.payload;
            const campaignStatus = doesCampaignIdExist(campaigns, meta_created_by_gui);
            state.activeCampaigns.Kreddy = campaignStatus.ULA_KREDDY;
            state.activeCampaigns.Ula = campaignStatus.ULA_DEFAULT;
            state.activeCampaigns.MobileApp = campaignStatus.ULA_MOBILE_APP;
            state.activeCampaigns.Lunar = campaignStatus.LUNAR;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(updateCampaign.fulfilled, state => {
                state.apiStatus.updateCampaign = ApiStatus.COMPLETED;
            })
            .addCase(updateCampaign.pending, state => {
                state.apiStatus.updateCampaign = ApiStatus.STARTED;
            })
            .addCase(updateCampaign.rejected, state => {
                state.apiStatus.updateCampaign = ApiStatus.FAILED;
            });
    },
});

export const { setExistingCampaign, setHasCampaign, updateActiveCampaigns } = campaignSlice.actions;
export default campaignSlice.reducer;
