import { Box, Button, ExclamationDefaultIcon, Stack, StatusCircle, Typography } from '@lendoab/aphrodite';
import React, { ReactElement } from 'react';

export interface WarningProps {
    onCancel: () => void;
    message?: string;
    title: string;
    primaryButton?: {
        label: ReactElement | string;
        onClick: () => void;
    };
}
export default function Warning(props: WarningProps): ReactElement {
    const { primaryButton, onCancel, title, message } = props;

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            paddingX={['medium', 'medium', '3xl']}
            paddingTop={['medium', 'medium', '3xl']}
            paddingBottom={['xl', 'xl', '3xl']}
            style={{ minHeight: '100%' }}
        >
            <Stack paddingTop={['7xl', '7xl', 'xl']} alignItems="center" space={['medium', 'medium', '2xl']}>
                <StatusCircle size="medium" status="fixed" color="orange">
                    <ExclamationDefaultIcon size={['xl', 'xl', '4xl']} />
                </StatusCircle>

                <Stack space={['xs', 'xs', 'medium']}>
                    <Typography.Title color="gray-10" level={3} align="center">
                        {title}
                    </Typography.Title>
                    {message && (
                        <Typography.Title level={5} fontWeight="book" align="center" color="gray-30">
                            {message}
                        </Typography.Title>
                    )}
                </Stack>
            </Stack>

            <Box display="flex" flexDirection="column" fluid marginTop={['medium', 'medium', '2xl']}>
                {primaryButton?.onClick ? (
                    <Button primary fluid marginBottom="medium" onClick={primaryButton.onClick}>
                        {primaryButton.label}
                    </Button>
                ) : null}
                <Button fluid onClick={onCancel}>
                    Avbryt
                </Button>
            </Box>
        </Box>
    );
}
