import { Transport } from 'APP/actions/transport';
import { IApplication } from 'APP/interfaces/applications.interfaces';

export const ApplicationActions = {
    async all() {
        return Transport.get('applications');
    },

    async getAvailableApplicationsByType() {
        return Transport.get('applications/active-types');
    },

    async getAllMortgageApplications() {
        return Transport.get('applications/mortgage');
    },

    /**
     * @param {string} id
     */
    async get(id: string): Promise<IApplication> {
        const res = await ApplicationActions.all();
        if (!res.data.applications || res.data.applications.length < 1) {
            throw new Error('Could not find an application');
        }

        const application = await res.data.applications.find((a: IApplication) => a.id === id);
        return application;
    },

    inboxViewedWithApplicationEvent(inboxViewedData: {
        application_id: string;
        revision: number;
    }): Promise<{ status: string }> {
        const event_name = 'inbox_viewed';
        return Transport.post('events', { data: { ...inboxViewedData, event_name } });
    },
};
