type MyBodyInit = Blob | FormData | URLSearchParams | ReadableStream | string | Record<string, unknown>;
interface CustomConfig {
    method?: 'GET' | 'POST' | 'PATCH' | 'DELETE';
    headers?: Record<string, string>;
    body?: MyBodyInit;
}

export interface ErrorResponseData {
    data?: unknown[];
    statusCode: number;
    statusText: string;
}

function setCSRFToken(token: string): void {
    try {
        window.localStorage.setItem('csrf', token);
    } catch (err) {}
}

function getCSRFToken(): string {
    try {
        const csrf = window.localStorage.getItem('csrf');
        if (!csrf) return '';
        return csrf;
    } catch (err) {
        return '';
    }
}

export function client<T = unknown>(endpoint: string, config: CustomConfig = {}): Promise<T> {
    const { body, headers } = config;

    const csrfTokenHeader = getCSRFToken();

    const requestInit: MyBodyInit = {
        method: config.method || 'GET',
        headers: {
            'content-type': 'application/json',
            ...(csrfTokenHeader !== '' && { 'x-csrf-token': csrfTokenHeader }),
            ...headers,
        },
    };

    if (body) {
        requestInit.body = JSON.stringify(body);
    }

    return fetch(`/api/${endpoint}`, requestInit).then(async response => {
        const csrfHeader = response.headers.get('x-csrf-token');
        const data = await response.json();
        if (csrfHeader) {
            setCSRFToken(csrfHeader);
        }
        if (response.ok) {
            return data;
        } else {
            return Promise.reject({
                data,
                statusCode: response.status,
                statusText: response.statusText,
            } as ErrorResponseData);
        }
    });
}

export const Transport = {
    get<T>(endpoint: string): Promise<T> {
        return client(endpoint, { method: 'GET' });
    },

    post<T>(endpoint: string, data?: MyBodyInit): Promise<T> {
        return client(endpoint, { method: 'POST', body: data });
    },

    patch<T>(endpoint: string, data: MyBodyInit): Promise<T> {
        return client(endpoint, { method: 'PATCH', body: data });
    },

    delete<T>(endpoint: string): Promise<T> {
        return client(endpoint, { method: 'DELETE' });
    },
};
