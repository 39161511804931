import { ACTIONS } from '@frontend/bank-id/enums/index';

export default {
    [ACTIONS.AUTH]: {
        devicePromptViewTitle: 'Identifiera dig med BankID',
        devicePromptViewWhyDoWeAsk: 'Varför ber vi om BankID-identifiering?',
        devicePromptViewWhyDoWeAskTooltip:
            'För din och bankens säkerhet ber vi dig att verifiera dig med ditt BankID. Det förhindrar att någon obehörig använder sig av dina uppgifter.',
        errorViewTitle: 'Identifieringen avbröts',
    },

    [ACTIONS.SIGN]: {
        devicePromptViewTitle: 'Signera med BankID',
        devicePromptViewWhyDoWeAsk: 'Varför ber vi om BankID-signering?',
        devicePromptViewWhyDoWeAskTooltip:
            'För att garantera din och bankens säkerhet ber vi dig att använda ditt BankID för att skriva under dokument och avtal. Detta förhindrar att någon obehörig använder sig av dina uppgifter.',
        errorViewTitle: 'Signeringen avbröts',
    },
};
