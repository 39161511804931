import { createSlice } from '@reduxjs/toolkit';
import { ApiStatus } from 'APP/constants/ApiStatusConstants';
import { OfferConstants } from 'APP/constants/OfferConstants';

import { fetchAcceptance, postAcceptance, postAcceptanceAutogiroAccount } from './acceptanceSliceActions';
import { acceptanceSliceInitialState } from './acceptanceSliceInitialState';

const initialState = acceptanceSliceInitialState;

const acceptanceSlice = createSlice({
    name: 'acceptancePage',
    initialState,
    reducers: {
        setAcceptance: (state, action) => {
            state.acceptance = action.payload.data;
        },
        updateApplicationWithAcceptedRevision: (state, action) => {
            state.applicationWithAcceptedRevision = action.payload;
        },
        setUpsells: (state, action) => {
            switch (action.payload.type) {
                case OfferConstants.TYPES.LOAN_INSURANCE:
                    state.upsells.loanInsurance.id = action.payload.id;
                    state.upsells.loanInsurance.active = action.payload.status === 'active';
                    state.upsells.loanInsurance.userAccepted = action.payload.userAccepted;
                    break;
                case OfferConstants.TYPES.SAFETY_INSURANCE:
                    state.upsells.safetyInsurance.id = action.payload.id;
                    state.upsells.safetyInsurance.active = action.payload.status === 'active';
                    state.upsells.safetyInsurance.userAccepted = action.payload.userAccepted;
                    break;
                case OfferConstants.TYPES.ACCIDENT_INSURANCE:
                    state.upsells.accidentInsurance.id = action.payload.id;
                    state.upsells.accidentInsurance.active = action.payload.status === 'active';
                    state.upsells.accidentInsurance.userAccepted = action.payload.userAccepted;
                    break;
                default:
                    break;
            }
        },
        updateUpsells: (state, action) => {
            switch (action.payload.type) {
                case OfferConstants.TYPES.LOAN_INSURANCE:
                    state.upsells.loanInsurance.id = action.payload.id;
                    state.upsells.loanInsurance.userAccepted = !state.upsells.loanInsurance.userAccepted;
                    break;
                case OfferConstants.TYPES.SAFETY_INSURANCE:
                    state.upsells.safetyInsurance.id = action.payload.id;
                    state.upsells.safetyInsurance.userAccepted = !state.upsells.safetyInsurance.userAccepted;
                    break;
                case OfferConstants.TYPES.ACCIDENT_INSURANCE:
                    state.upsells.accidentInsurance.id = action.payload.id;
                    state.upsells.accidentInsurance.userAccepted = !state.upsells.accidentInsurance.userAccepted;
                    break;
                default:
                    break;
            }
        },
        setBankIdAutogiroIsVerified: (state, action) => {
            state.bankIdAutogiroIsVerified = action.payload;
        },
        setBankIdReference: (state, action) => {
            state.bankIdReference = action.payload;
        },
        setApiPostAutogiro: (state, action) => {
            state.apiStatus.postAutogiroData = action.payload.postAutogiroData;
        },
        resetAcceptanceSlice: () => initialState,
        resetAcceptanceFetchStatus: state => {
            state.apiStatus.fetchAcceptance = ApiStatus.IDLE;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAcceptance.fulfilled, (state, action) => {
                state.apiStatus.fetchAcceptance = ApiStatus.COMPLETED;
                state.acceptance = action.payload.data;
            })
            .addCase(fetchAcceptance.pending, state => {
                state.apiStatus.fetchAcceptance = ApiStatus.STARTED;
            })
            .addCase(fetchAcceptance.rejected, state => {
                state.apiStatus.fetchAcceptance = ApiStatus.FAILED;
            })
            .addCase(postAcceptance.fulfilled, state => {
                state.apiStatus.postAcceptance = ApiStatus.COMPLETED;
            })
            .addCase(postAcceptance.pending, state => {
                state.apiStatus.postAcceptance = ApiStatus.STARTED;
            })
            .addCase(postAcceptance.rejected, state => {
                state.apiStatus.postAcceptance = ApiStatus.FAILED;
            })
            .addCase(postAcceptanceAutogiroAccount.fulfilled, state => {
                state.apiStatus.postAutogiroData = ApiStatus.COMPLETED;
            })
            .addCase(postAcceptanceAutogiroAccount.pending, state => {
                state.apiStatus.postAutogiroData = ApiStatus.STARTED;
            })
            .addCase(postAcceptanceAutogiroAccount.rejected, state => {
                state.apiStatus.postAutogiroData = ApiStatus.FAILED;
            });
    },
});

export const {
    updateApplicationWithAcceptedRevision,
    setUpsells,
    updateUpsells,
    setAcceptance,
    resetAcceptanceSlice,
    setBankIdAutogiroIsVerified,
    setBankIdReference,
    setApiPostAutogiro,
    resetAcceptanceFetchStatus,
} = acceptanceSlice.actions;

export default acceptanceSlice.reducer;
