export const RECIPIENTS = {
    ADMIN: 'admin',
    CUSTOMER: 'customer',
    PARTNER: 'partner',
};

export const RECIPIENTS_NAMES = {
    [RECIPIENTS.ADMIN]: 'Kundtjänst',
    [RECIPIENTS.CUSTOMER]: 'Kund',
};

export const MESSAGE_DIRECTION = {
    INCOMING: 'incoming',
    OUTGOING: 'outgoing',
};
