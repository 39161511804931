import { createSlice } from '@reduxjs/toolkit';
import { States } from 'APP/components/Offers/offerHelpers/offerBusinessConstants';

import { offerPageInitialState } from './initialState';
import {
    onAcceptOfferAction,
    postAcceptanceOffer,
    postAcceptanceOfferPrivateBusiness,
    postBeneficialOwners,
} from './offerPageSliceActions';

const initialState = offerPageInitialState;

const businessOfferPageSlice = createSlice({
    name: 'businessOfferPageSlice',
    initialState,
    reducers: {
        updateStep: (state, action) => {
            state.status = action.payload;
        },
        updateOfferParameters: (state, action) => {
            state.parameters = action.payload;
        },
        updateBusinessRequirements: (state, action) => {
            state.businessRequirements = action.payload;
        },
        resetBusinessRequirements: state => {
            state.businessRequirements = {};
        },

        resetBusinessOfferState() {
            return initialState;
        },
        updateOptimizelyData: (state, action) => {
            state.optimizelyData = action.payload;
        },
        updateBizOfferID: (state, action) => {
            state.bizOfferID = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(postBeneficialOwners.pending, state => {
                state.status = States.PENDING_BENEFICIAL_OWNERS;
            })
            .addCase(postBeneficialOwners.fulfilled, state => {
                state.status = States.PENDING;
            })
            .addCase(postBeneficialOwners.rejected, (state, action) => {
                state.status = States.REJECTED;
                if (action.error.message) {
                    state.error = { message: action.error.message };
                } else {
                    state.error = undefined;
                }
            })
            .addCase(postAcceptanceOffer.pending, state => {
                state.status = States.PENDING;
            })
            .addCase(postAcceptanceOffer.fulfilled, state => {
                state.status = States.RESOLVED;
            })
            .addCase(postAcceptanceOffer.rejected, (state, action) => {
                state.status = States.REJECTED;
                if (action.error.message) {
                    state.error = { message: action.error.message };
                } else {
                    state.error = undefined;
                }
            })
            .addCase(onAcceptOfferAction.pending, state => {
                state.status = States.PENDING;
            })
            .addCase(onAcceptOfferAction.fulfilled, (state, action) => {
                if (action.payload?.hasComplements) {
                    state.status = States.FILE_UPLOAD_OR_MONTO;
                } else {
                    state.status = States.RESOLVED;
                }
            })
            .addCase(onAcceptOfferAction.rejected, (state, action) => {
                state.status = States.REJECTED;
                if (action.error.message) {
                    state.error = { message: action.error.message };
                } else {
                    state.error = undefined;
                }
            })
            .addCase(postAcceptanceOfferPrivateBusiness.pending, state => {
                state.status = States.PENDING;
            })
            .addCase(postAcceptanceOfferPrivateBusiness.fulfilled, (state, action) => {
                if (action.payload?.hasComplements) {
                    state.status = States.FILE_UPLOAD_OR_MONTO;
                } else {
                    state.status = States.RESOLVED;
                }
            })
            .addCase(postAcceptanceOfferPrivateBusiness.rejected, (state, action) => {
                state.status = States.REJECTED;
                if (action.error.message) {
                    state.error = { message: action.error.message };
                } else {
                    state.error = undefined;
                }
            });
    },
});

export const {
    resetBusinessOfferState,
    updateStep,
    updateOfferParameters,
    updateOptimizelyData,
    updateBizOfferID,
    updateBusinessRequirements,
    resetBusinessRequirements,
} = businessOfferPageSlice.actions;
export default businessOfferPageSlice.reducer;
