export function getNow() {
    return new Date().toUTCString();
}

export function isAfter(date, dateToCompare = getNow()) {
    return new Date(date) < new Date(dateToCompare);
}

export function isBefore(date, dateToCompare = getNow()) {
    return new Date(date) < new Date(dateToCompare);
}

export function isValidDateFormat(date) {
    return !isNaN(Date.parse(date));
}

export function isValidDateObject(date) {
    return date instanceof Date && !isNaN(date);
}

export function addDays(date, days) {
    const currentDate = new Date(date);
    return formatDateToISO(currentDate.setDate(currentDate.getDate() + days));
}

export function formatDateToISO(date) {
    return new Date(date).toISOString();
}

export function formatDateToCleanedISOString(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        throw new Error('Invalid date format');
    }
    return date.toISOString().split('T')[0];
}
