import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const generalSlice = createSlice({
    name: 'generalSlice',
    initialState,
    reducers: {
        resetReduxStates: () => {},
    },
});

export const { resetReduxStates } = generalSlice.actions;
export default generalSlice.reducer;
