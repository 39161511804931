import { Transport } from 'APP/actions/transport';

export const MortgageConversationsActions = {
    async getConversations(applicationID) {
        return Transport.get(`mortgage-chat/conversations?application_id=${applicationID}`, 'data');
    },
    async createConversation(conversation) {
        return Transport.post(`mortgage-chat/conversations`, { data: conversation }, 'data');
    },
    async createMessage(data) {
        return Transport.post(`mortgage-chat/messages`, { data }, 'data');
    },
    async markAsSeen(messageID, applicationID) {
        return Transport.post(
            `mortgage-chat/messages/${messageID}/mark_seen`,
            { data: { application_id: applicationID } },
            'data'
        );
    },
};
