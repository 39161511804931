import { TRACKING_METHODS, VIEWS } from './index';

/* eslint-disable no-shadow */
export enum EVENTS {
    // OUTCOMES
    VIEW_CHANGED = 'VIEW_CHANGED',
    VERIFIED = 'VERIFIED',
    ERROR_OCCURRED = 'ERROR_OCCURRED',
    INITIATED = 'INITIATED',

    // INTERACTIONS
    OPEN_ON_THIS_DEVICE_CLICKED = 'OPEN_ON_THIS_DEVICE_CLICKED',
    OPEN_ON_ANOTHER_DEVICE_CLICKED = 'OPEN_ON_ANOTHER_DEVICE_CLICKED',
    RETRY_CLICKED = 'RETRY_CLICKED',
    CANCEL_CLICKED = 'CANCEL_CLICKED',
    BACK_CLICKED = 'BACK_CLICKED',
    TOOLTIP_CLICKED = 'TOOLTIP_CLICKED',
}

type ERROR_EVENT = {
    event: EVENTS.ERROR_OCCURRED;
    payload: {
        error: string;
        trackingMethod: TRACKING_METHODS;
    };
};

type VIEW_CHANGED_EVENT = {
    event: EVENTS.VIEW_CHANGED;
    payload: {
        view: VIEWS;
        trackingMethod?: TRACKING_METHODS;
    };
};

type VERIFIED_EVENT = {
    event: EVENTS.VERIFIED;
    payload: {
        trackingMethod: TRACKING_METHODS;
    };
};
type INITIATED_EVENT = {
    event: EVENTS.INITIATED;
    payload: {
        trackingMethod: TRACKING_METHODS;
    };
};

type EVENTS_WITHOUT_PAYLOAD = {
    event:
        | EVENTS.BACK_CLICKED
        | EVENTS.CANCEL_CLICKED
        | EVENTS.OPEN_ON_ANOTHER_DEVICE_CLICKED
        | EVENTS.OPEN_ON_THIS_DEVICE_CLICKED
        | EVENTS.RETRY_CLICKED
        | EVENTS.TOOLTIP_CLICKED;
    payload?: undefined;
};

export type EVENTS_CALLBACK =
    | ERROR_EVENT
    | VIEW_CHANGED_EVENT
    | VERIFIED_EVENT
    | INITIATED_EVENT
    | EVENTS_WITHOUT_PAYLOAD;
