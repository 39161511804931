import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { RevisionActions } from 'APP/actions/RevisionActions';
import { IApplication } from 'APP/interfaces/applications.interfaces';
import { IRevisionApplication, IRootState } from 'APP/interfaces/general.interfaces';

interface RejectValues {
    statusCode: number;
}

interface CustomError extends Error {
    statusCode?: number;
}

export const postRevisionAction = createAsyncThunk<IApplication, IRevisionApplication, { rejectValue: RejectValues }>(
    'revision/post',
    async (revisionData: IRevisionApplication, thunkAPI) => {
        const state = thunkAPI.getState() as IRootState;
        const { application } = state.revisionSlice;
        try {
            const response = await RevisionActions.create(application.id, revisionData);
            return response.data;
        } catch (error: unknown) {
            if (error instanceof Error) {
                return thunkAPI.rejectWithValue({ statusCode: 500 });
            }
            return thunkAPI.rejectWithValue({ statusCode: 500 });
        }
    }
);

export const fetchSmartRevisionDataAction = createAsyncThunk(
    'smartRevisionData/get',
    async ({ applicationId, revisionId }: { applicationId: string; revisionId: number }, thunkAPI) => {
        try {
            const res = await RevisionActions.get(applicationId, revisionId);
            return res.data;
        } catch (err: unknown) {
            const error = err as CustomError;

            if (error && error.statusCode !== 404) {
                Sentry.captureException(err, { fingerprint: ['fetchSmartRevisionDataAction'] });
            }
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const postSmartRevisionDataEventAction = createAsyncThunk(
    'smartRevisionDataEvent/post',
    async (
        {
            applicationId,
            userId,
            eventName,
            revision,
        }: { applicationId: string; userId: string; eventName: string; revision: number },
        thunkAPI
    ) => {
        try {
            const res = await RevisionActions.post(applicationId, userId, eventName, revision);
            return res.data;
        } catch (err: unknown) {
            const error = err as CustomError;

            if (error && error.statusCode !== 404) {
                Sentry.captureException(err, { fingerprint: ['postSmartRevisionDataEventAction'] });
            }
            return thunkAPI.rejectWithValue(err);
        }
    }
);
