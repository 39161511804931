import { createAsyncThunk } from '@reduxjs/toolkit';
import { Transport } from 'APP/actions/transport';
import { IValidationPayload } from 'APP/interfaces/feature/emailValidation';

//Private
export const privateApplicantEmailValidationAction = createAsyncThunk<
    {
        email: string;
        data: boolean;
    },
    string
>('privateApplicantEmailValidation/post', async email => {
    const response = await Transport.post('email/validate', { email: email });
    const data: IValidationPayload = { ...response, email };
    return data;
});

export const privateNewEmailValidationAction = createAsyncThunk('privateNewEmailValidation/post', async email => {
    const response = await Transport.post('email/validate', { email: email });
    const data = { ...response, email };
    return data;
});

export const privateUpdateEmailAction = createAsyncThunk(
    'privateUpdateEmail/patch',
    async ({ email, id }: { email: string; id: string }) => {
        const response = await Transport.patch(`applicants/private/${id}`, { data: { email: email } });
        return response;
    }
);
