import { Box, Card, CheckIcon, Stack, StatusCircle, Text } from '@lendoab/aphrodite';
import PropTypes from 'prop-types';
import React from 'react';

export default function MarginalenDocumentsApprovedConfirmation({ offer }) {
    return (
        <Card borderRadius="small" flexDirection="column" display="flex" padding={['medium', '2xl', '2xl']}>
            <Box display="flex" justifyContent="center" alignItems="center">
                <StatusCircle
                    gradientTo="#29C466"
                    gradientFrom="#059142"
                    status="complete"
                    paddingBottom="xs"
                    size="small"
                >
                    <CheckIcon color="gray-10" size="xl" />
                </StatusCircle>
            </Box>
            <Text
                align="center"
                weight="bold"
                size={['medium', 'large', 'large']}
                marginBottom="medium"
                marginTop={['medium', '2xl', '2xl']}
            >
                Dina dokument är granskade och godkända!
            </Text>
            <Text size="xs" marginBottom="medium" marginTop={['medium', '2xl', '2xl']}>
                Dina dokument är nu granskade av en handläggare hos Lendo och har skickats vidare till{' '}
                {offer.product.name}.
            </Text>
            <Stack space="medium">
                <Box>
                    <Text size="xs" weight="bold">
                        Vad händer nu?
                    </Text>
                    <Text size="xs">
                        {offer.product.name} kommer att granska din ansökan och återkomma till oss om vi behöver
                        komplettera din ansökan med fler underlag. Det kan vara exempelvis arbetsgivarintyg,
                        lönespecifikation eller värdering som behöver skickas in.
                    </Text>
                    <Text size="xs" marginTop="xs">
                        Om du behöver komplettera din ansökan kommer en handläggare från Lendo att kontakta dig.
                    </Text>
                </Box>
                <Box>
                    <Text size="xs" weight="bold">
                        När ansökan är godkänd
                    </Text>
                    <Text size="xs">
                        Dina lånehandlingar kommer att finns tillgängliga att hämta hem från {offer.product.name}s
                        hemsida enligt följande:
                    </Text>
                    <Box component="ol" marginTop="small" style={{ marginLeft: '28px' }}>
                        <Stack space="xs">
                            <Box component="li">
                                <Text size="xs">{`Välj "Mina Sidor" uppe i högre hörnet.`}</Text>
                            </Box>
                            <Box component="li">
                                <Text size="xs">Logga in med Mobilt Bank-id eller Bank-id.</Text>
                            </Box>
                            <Box component="li">
                                <Text size="xs">{`Väl inloggad kommer du/ni se dina/era lånehandlingar, under fliken "lån" som ni sedan godkänner
                    med Mobilt Bank-ID.`}</Text>
                            </Box>
                        </Stack>
                    </Box>
                </Box>
            </Stack>
        </Card>
    );
}

MarginalenDocumentsApprovedConfirmation.propTypes = {
    offer: PropTypes.object.isRequired,
};
