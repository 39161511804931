import { Box, ButtonRow, ButtonRowButton, Input, Modal, ModalContent, Text } from '@lendoab/aphrodite';
import { newValidator, useForm } from '@lendoab/use-form';
import { phone, required } from '@lendoab/web-validations';
import * as Sentry from '@sentry/react';
import { CallBackActions } from 'APP/actions/CallBackActions';
import { OpenHoursHelpers } from 'APP/helpers/OpenHoursHelpers';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';

import styles from './CallMeModal.module.scss';

function createEffect(type, args = {}) {
    return {
        type,
        ...args,
        status: 'idle',
        markAsStarted() {
            this.status = 'started';
        },
    };
}

const callMeModalContext = createContext({ status: 'idle' });

export function useCallMeModal() {
    return useContext(callMeModalContext);
}

export function CallMeModalProvider({ children }) {
    const [open, setOpen] = useState(false);

    const openModal = useCallback(event => {
        if (OpenHoursHelpers.isOpen()) return;

        if (event && typeof event.preventDefault === 'function') {
            event.preventDefault();
        }
        setOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <callMeModalContext.Provider value={{ open, openModal, closeModal }}>{children}</callMeModalContext.Provider>
    );
}

CallMeModalProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

function callMeModalReducer(state, event) {
    const oldEffects = state.effects.filter(effect => effect.type === 'idle');

    switch (state.status) {
        case 'idle':
        case 'resolved':
        case 'rejected':
            if (event.type === 'CLOSE') {
                return {
                    ...state,
                    status: 'idle',
                    effects: [...oldEffects, createEffect('close')],
                };
            }

            if (event.type === 'SUBMIT') {
                return {
                    ...state,
                    status: 'pending',
                    effects: [...oldEffects, createEffect('submit', { phone: event.phone })],
                };
            }

            return state;
        case 'pending':
            if (event.type === 'RESOLVE') {
                return {
                    ...state,
                    effects: oldEffects,
                    status: 'resolved',
                };
            }

            if (event.type === 'REJECT') {
                return {
                    ...state,
                    effects: [...oldEffects, createEffect('captureError', { error: event.error })],
                    status: 'rejected',
                };
            }

            return state;

        default:
            return state;
    }
}

export function CallMeModal() {
    const { open, closeModal } = useContext(callMeModalContext);

    const [state, dispatch] = useReducer(callMeModalReducer, { status: 'idle', effects: [] });

    const { getInputProps, getFieldMessage, handleSubmit } = useForm({
        fields: {
            phone: {
                value: '',
                validators: [
                    newValidator(required, 'error', 'Vänligen ange ett telefonnummer'),
                    newValidator(phone, 'error', 'Vänligen ange ett giltigt telefonnummer'),
                ],
            },
        },
        onSubmit(values) {
            dispatch({ type: 'SUBMIT', phone: values.phone });
        },
    });

    const { effects } = state;
    useEffect(() => {
        if (effects.length < 1) {
            return;
        }

        for (const effect of effects) {
            if (effect.status !== 'idle') {
                continue;
            }

            effect.markAsStarted();

            if (effect.type === 'submit') {
                CallBackActions.postPhoneNumber({ phone: effect.phone })
                    .then(() => {
                        dispatch({ type: 'RESOLVE' });
                    })
                    .catch(error => {
                        dispatch({ type: 'REJECT', error });
                    });
            }

            if (effect.type === 'close') {
                closeModal();
            }

            if (effect.type === 'captureError') {
                Sentry.captureException(effect.error);
            }
        }
    }, [effects, closeModal]);

    return (
        <Modal style={{ zIndex: 5 }} open={open} onClose={() => dispatch({ type: 'CLOSE' })}>
            <ModalContent component="form" onSubmit={handleSubmit} padding="none" className={styles.content}>
                <Box alignItems="flex-start" flexDirection="column" display="flex" padding="medium">
                    <Text component="label" weight="bold" htmlFor="callMeModal-phone" size="xs" marginBottom="small">
                        Fyll i ditt telefonnummer nedan så ringer vi dig så snart som möjligt.
                    </Text>

                    <Input
                        fluid
                        id="callMeModal-phone"
                        type="tel"
                        error={getFieldMessage('phone')}
                        {...getInputProps('phone')}
                        placeholder="Telefonnnummer"
                    />

                    {state.status === 'pending' ? (
                        <Text marginTop="medium" size="xs">
                            Skickar ditt telefonnummer
                        </Text>
                    ) : null}

                    {state.status === 'resolved' ? (
                        <Text marginTop="medium" size="xs">
                            Vi kommer ringa dig snart!
                        </Text>
                    ) : null}

                    {state.status === 'rejected' ? (
                        <Text marginTop="medium" size="xs">
                            Något gick tyvärr fel
                        </Text>
                    ) : null}
                </Box>

                <ButtonRow>
                    <ButtonRowButton onClick={() => dispatch({ type: 'CLOSE' })}>Stäng</ButtonRowButton>
                    <ButtonRowButton type="submit" disabled={state.status === 'pending'} primary>
                        Skicka
                    </ButtonRowButton>
                </ButtonRow>
            </ModalContent>
        </Modal>
    );
}
