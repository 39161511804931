import externalRoutes from 'APP/config/externalRoutes';
import { IOfferProduct } from 'APP/interfaces/general.interfaces';

export function getProductLogoSvg(product: IOfferProduct): string {
    const partnerInternalName = product?.partner_information?.internal_name;
    const logoName = product?.logo_name || 'logo';

    return `${externalRoutes.cdn}/partners/${partnerInternalName}/logos/${logoName}.svg`;
}

export function getKreddyLogo(): string {
    return `${externalRoutes.cdn}/img/logos/kreddy_logo.svg`;
}
