import { Box, Card, CheckIcon, StatusCircle, Text } from '@lendoab/aphrodite';
import PropTypes from 'prop-types';
import React from 'react';

export default function LandshypotekDocumentsApprovedConfirmation({ offer, formType = 'new' }) {
    return (
        <Card borderRadius="small" flexDirection="column" display="flex" padding={['medium', '2xl', '2xl']}>
            <Box display="flex" justifyContent="center" alignItems="center">
                <StatusCircle
                    gradientTo="#29C466"
                    gradientFrom="#059142"
                    status="complete"
                    paddingBottom="xs"
                    size="small"
                >
                    <CheckIcon color="gray-10" size="xl" />
                </StatusCircle>
            </Box>
            <Text align="center" weight="bold" size={['medium', 'large', 'large']} marginTop={['medium', '2xl', '2xl']}>
                {formType === 'new' ? 'Du har valt ett erbjudande!' : 'Dina dokument är granskade och godkända!'}
            </Text>
            {formType !== 'new' && (
                <Text size="xs" marginBottom="medium" marginTop={['medium', '2xl', '2xl']}>
                    Dina dokument är nu granskade av en handläggare hos Lendo och har skickats vidare till{' '}
                    {offer.product.name}.
                </Text>
            )}
            <Box>
                <Text size="xs" weight="bold">
                    Vad händer nu?
                </Text>
                <Text size="xs">
                    Någon från {offer.product.name} kommer höra av sig via telefon inom några arbetsdagar för att
                    färdigställa ansökan. Du får i samband med det reda på vilka kompletteringar vi behöver från dig för
                    att behandla klart din ansökan.
                </Text>
                <Text size="xs" marginTop="xs">
                    Har du frågor angående din ansökan? kontakta Lendos kundtjänst på{' '}
                    <a href="tel: 0771 - 13 13 10">0771 - 13 13 10</a>
                </Text>
            </Box>
        </Card>
    );
}

LandshypotekDocumentsApprovedConfirmation.propTypes = {
    offer: PropTypes.object.isRequired,
    formType: PropTypes.string,
};
