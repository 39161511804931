import { Box, Card, CheckIcon, Stack, StatusCircle, Text } from '@lendoab/aphrodite';
import PropTypes from 'prop-types';
import React from 'react';

export default function SkandiaDocumentsApprovedConfirmation({ offer }) {
    return (
        <Card borderRadius="small" flexDirection="column" display="flex" padding={['medium', '2xl', '2xl']}>
            <Box display="flex" justifyContent="center" alignItems="center">
                <StatusCircle
                    gradientTo="#29C466"
                    gradientFrom="#059142"
                    status="complete"
                    paddingBottom="xs"
                    size="small"
                >
                    <CheckIcon color="gray-10" size="xl" />
                </StatusCircle>
            </Box>
            <Text align="center" weight="bold" size="large" marginBottom="medium" marginTop={['medium', '2xl', '2xl']}>
                Dina dokument är granskade och godkända!
            </Text>
            <Text size="xs" marginBottom="medium" marginTop={['medium', '2xl', '2xl']}>
                Dina dokument är nu granskade av en handläggare hos Lendo och har skickats vidare till Skandia.
            </Text>
            <Stack space="medium">
                <Box>
                    <Text size="xs" weight="bold">
                        Vad händer nu?
                    </Text>
                    <Text size="xs">
                        {offer.product} kommer att granska din ansökan och återkomma till oss om vi behöver komplettera
                        din ansökan med fler underlag. Det kan vara exempelvis arbetsgivarintyg, lönespecifikation eller
                        värdering som behöver skickas in.
                    </Text>
                    <Text size="xs" marginTop="xs">
                        Om du behöver komplettera din ansökan kommer en handläggare från Lendo att kontakta dig.
                    </Text>
                </Box>
                <Box>
                    <Text size="xs" weight="bold">
                        När ansökan är godkänd
                    </Text>
                    <Text size="xs">
                        Inom några arbetsdagar kommer du behöva logga in Skandias portal och välja lånevillkor.
                    </Text>
                    <Box component="ol" marginTop="small" style={{ marginLeft: '28px' }}>
                        <Stack space="xs">
                            <Box component="li">
                                <Text size="xs">
                                    Logga in på{' '}
                                    <a href="https://www.skandia.se/lana/pagaende-arenden/">
                                        https://www.skandia.se/lana/pagaende-arenden/{' '}
                                    </a>
                                    för att komma in på det pågående ärendet.
                                </Text>
                            </Box>
                            <Box component="li">
                                <Text size="xs">{`Börja med att antingen skapa ett allt-i-ett-konto under "Konto", tryck "Allt i Ett-konto", därefter tryck "Ansök om Allt i Ett-konto".`}</Text>
                            </Box>
                            <Box component="li">
                                <Text size="xs">
                                    Gå därefter in till det pågående ärendet. Detta konto behövs för att de sen ska
                                    kunna skicka ut lånehandlingar till dig.
                                </Text>
                            </Box>
                        </Stack>
                    </Box>
                </Box>
            </Stack>
        </Card>
    );
}
SkandiaDocumentsApprovedConfirmation.propTypes = {
    offer: PropTypes.object.isRequired,
};
