import { Box, Text } from '@lendoab/aphrodite';
import React, { ReactElement } from 'react';

import styles from './NextSteps.module.scss';

interface NextStepsProps {
    steps: string[];
    [key: string]: unknown;
}

interface StepProps {
    text: string;
    step: number;
    isLast: boolean;
}

function NextSteps(props: NextStepsProps): ReactElement {
    const { steps, ...rest } = props;

    return (
        <Box {...rest}>
            {steps.map((text, index) => (
                <Step key={`step-${index}`} step={index + 1} text={text} isLast={steps.length === index + 1} />
            ))}
        </Box>
    );
}

function Step(props: StepProps): ReactElement {
    const { text, step, isLast } = props;

    return (
        <Box display="flex">
            <Box
                position="relative"
                display="flex"
                justifyContent="center"
                marginRight="small"
                className={styles.indicatorContainer}
            >
                <Box
                    borderRadius="full"
                    backgroundColor="green-light"
                    color="white"
                    fontSize="xxs"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={styles.indicatorBubble}
                >
                    {step}
                </Box>
                <Box display={isLast ? 'none' : 'flex'} position="absolute" className={styles.stepTail} />
            </Box>
            <Box paddingBottom={isLast ? 'none' : ['medium', '2xl']}>
                <Text size="xs" style={{ whiteSpace: 'pre-line' }}>
                    {text}
                </Text>
            </Box>
        </Box>
    );
}

export default NextSteps;
