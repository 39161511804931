export const pbPartnerName = {
    coop: 'medmera_bank',
    banky: 'banky',
    sevenDay: 'seven_day',
    ikano: 'ikano_bank',
    tryggkredit: 'trygg_kredit',
    brixo: 'brixo',
    equilo: 'equilo',
    goodCash: 'good_cash',
    avida: 'avida_finans',
    marginalen: 'marginalen_bank',
    santander: 'santander',
    saldo: 'saldo',
    loanstep: 'loanstep',
    wasa_kredit: 'wasa_kredit',
};
