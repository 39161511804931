import { createListenerMiddleware } from '@reduxjs/toolkit';
import { getShortBusinessComplementsTexts } from 'APP/content/businessComplements';
import { fetchAcceptance } from 'APP/feature/acceptancePage/acceptanceSliceActions';
import { updateBusinessAcceptance, updateBusinessApplication, updateBusinessOffer } from 'APP/feature/business/slice';
import { updateIsOfferPageFlow } from 'APP/feature/monto/montoSlice';
import { updateComplements } from 'APP/feature/offerPage/offerSlice';
import { getOfferAction } from 'APP/feature/offerPage/offerSliceActions';

import { resetBusinessOfferState } from '../offerPage/offerPageSlice';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    actionCreator: getOfferAction.fulfilled,
    effect: async (action, listenerApi) => {
        const { application, offer } = action.payload;

        listenerApi.dispatch(updateBusinessApplication(application));
        listenerApi.dispatch(updateBusinessOffer(offer));
        listenerApi.dispatch(updateComplements(getShortBusinessComplementsTexts(offer.complements)));
        listenerApi.dispatch(resetBusinessOfferState()); // reset the business offer page (not the actual offer) flow state
    },
});

listenerMiddleware.startListening({
    actionCreator: fetchAcceptance.fulfilled,
    effect: async (action, listenerApi) => {
        const { data } = action.payload;
        listenerApi.dispatch(updateBusinessApplication(data.application));
        listenerApi.dispatch(updateBusinessOffer(data.offer));
        listenerApi.dispatch(updateBusinessAcceptance(data.acceptance));
        listenerApi.dispatch(updateIsOfferPageFlow(false));
        listenerApi.dispatch(updateComplements(getShortBusinessComplementsTexts(data.offer.complements)));
    },
});

export default listenerMiddleware;
