export const OfferConstants = {
    TYPES: {
        BUSINESS: 'business_loan_offer',
        PRIVATE: 'loan_offer',
        DENIAL: 'denial',
        LOAN_INSURANCE: 'loan_insurance',
        SAFETY_INSURANCE: 'safety_insurance',
        ACCIDENT_INSURANCE: 'accident_insurance',
        CARD_OFFER: 'card_offer',
    },

    BUSINESS_LOAN_TYPES: {
        BASIC: 'basic_business_loan',
        REVOLVER: 'revolver_loan',
        EU_LOAN: 'eu_loan',
        EU_LOAN_GUARANTOR: 'eu_loan_guarantor',
        STATE: 'state_guarantee',
        FLEXIBLE_AMORTIZATION: 'flexible_amortization',
        STARTUP_LOAN: 'startup_loan',
        STARTUP_LOAN_WITH_INTEREST_DISCOUNT: 'startup_loan_with_interest_discount',
        EU_LOAN_100_GUARANTOR: 'eu_loan_100_guarantor',
    },

    REPAYMENT_TYPES: {
        STRAIGHT: 'straight',
        ANNUITY: 'annuity',
    },

    ALLOWED_ACCOUNT_TYPES: {
        BANK_ACCOUNT: 'account_type_bank_account',
        BG: 'account_type_bg',
        PG: 'account_type_pg',
    },
    OFFER_STEPS: {
        STEP1: 'step_1',
        STEP2: 'step_2',
    },
};
