import { IBeneficialOwner } from 'APP/actions/actions.interfaces';
import { IApplication } from 'APP/interfaces/applications.interfaces';
import { ErrorType, IAuthData, IEffect } from 'APP/interfaces/authentication.interfaces';

interface EffectArgs {
    authData?: IAuthData;
    id?: string;
    method?: 'token' | 'bankId';
    ssn?: string;
    reference?: string;
    applications?: IApplication[];
    error?: ErrorType;
    step?: string;
    beneficialOwners?: IBeneficialOwner[];
    parameters?: { [key: string]: unknown };
    url?: string;
}
export function createEffect(type: string, args: EffectArgs = {}): IEffect {
    return {
        type,
        status: 'idle',
        markAsStarted(): void {
            this.status = 'started';
        },
        ...args,
    };
}
