import { ApiStatus } from 'APP/constants/ApiStatusConstants';
import { BankIdConstants, BankIdStatus } from 'APP/constants/BankIdConstants';
import { StringMap } from 'APP/interfaces/general.interfaces';

export const offerSliceInitialState: OfferSliceInitialState = {
    step: 'step_1',
    apiStatus: {
        postOffer: ApiStatus.IDLE,
        getOffer: ApiStatus.IDLE,
    },
    showSwitcher: false,
    activeOffer: {},
    productRequirements: {},
    upsells: {
        loanInsurance: {
            id: '',
            active: false,
            userAccepted: false,
        },
        safetyInsurance: {
            id: '',
            active: false,
            userAccepted: false,
        },
    },
    isNotPEP: true,
    bankId: {
        autostartToken: '',
        reference: '',
        apiStatus: {
            getReference: ApiStatus.IDLE,
            poll: ApiStatus.IDLE,
        },
        status: BankIdStatus.IDLE,
        progressStatus: BankIdConstants.PROGRESS.IDLE,
        pollCount: 0,
    },
    bizActiveOffers: [],
    bizExpiredOffers: [],
    application: {
        company_info: {},
        id: '',
    },
    fileUploadComplements: [],
};

interface OfferSliceInitialState {
    step: string;
    apiStatus: {
        postOffer: number;
        getOffer: number;
    };
    showSwitcher: boolean;
    activeOffer: StringMap;
    productRequirements: StringMap;
    upsells: {
        loanInsurance: {
            id: string;
            active: boolean;
            userAccepted: boolean;
        };
        safetyInsurance: {
            id: string;
            active: boolean;
            userAccepted: boolean;
        };
    };
    isNotPEP: boolean;
    bankId: {
        autostartToken: string;
        reference: string;
        apiStatus: {
            getReference: number;
            poll: number;
        };
        status: string;
        progressStatus: string;
        pollCount: number;
    };
    application: {
        company_info: StringMap;
        id: string;
    };
    fileUploadComplements: string[];
    bizActiveOffers: [];
    bizExpiredOffers: [];
}
