export const openHours = {
    schedule: [
        {
            start: '2020-12-21',
            end: '2020-12-27',
            name: 'jul',
            hours: {
                MONDAY: {
                    open: '08.00',
                    close: '17.00',
                },
                TUESDAY: {
                    open: '08.00',
                    close: '17.00',
                },
                WEDNESDAY: {
                    open: '08.00',
                    close: '17.00',
                },
                THURSDAY: {
                    open: false,
                },
                FRIDAY: {
                    open: false,
                },
                SATURDAY: {
                    open: '10.00',
                    close: '14.00',
                },
            },
        },
        {
            start: '2020-12-28',
            end: '2020-01-03',
            name: 'nyår',
            hours: {
                MONDAY: {
                    open: '08.00',
                    close: '17.00',
                },
                TUESDAY: {
                    open: '08.00',
                    close: '17.00',
                },
                WEDNESDAY: {
                    open: '08.00',
                    close: '17.00',
                },
                THURSDAY: {
                    open: '10.00',
                    close: '14.00',
                },
                FRIDAY: {
                    open: false,
                },
            },
        },
    ],
    default: {
        MONDAY: {
            open: '08:00',
            close: '20:00',
        },
        TUESDAY: {
            open: '08:00',
            close: '20:00',
        },
        WEDNESDAY: {
            open: '08:00',
            close: '20:00',
        },
        THURSDAY: {
            open: '08:00',
            close: '20:00',
        },
        FRIDAY: {
            open: '09:00',
            close: '17:00',
        },
        SATURDAY: {
            open: '10:00',
            close: '14:00',
        },
        SUNDAY: {
            open: '10:00',
            close: '14:00',
        },
    },
};
