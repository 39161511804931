import { BankIdProps } from '@frontend/bank-id/BankId/BankId';
import { TOOLTIP_CONTENT_WIDTH, TOOLTIP_Z_INDEX } from '@frontend/bank-id/constants';
import TEXTS from '@frontend/bank-id/constants/texts';
import {
    ArrowLeftIcon,
    BankIdColorIcon,
    BankIdIcon,
    Box,
    Button,
    InfoIcon,
    Inline,
    Stack,
    Tooltip,
    Typography,
    useRect,
} from '@lendoab/aphrodite';
import { createPositionFn } from '@lendoab/lendose-shared';
import React, { ReactElement, useRef } from 'react';

import styles from './DevicePrompt.module.scss';

export interface DevicePromptProps {
    action: BankIdProps['action'];
    onBack: () => void;
    onTooltipClicked?: () => void;
    openOnThisDevice: () => void;
    openOnAnotherDevice: () => void;
}

export default function DevicePrompt(props: DevicePromptProps): ReactElement {
    const { action, onBack, openOnAnotherDevice, openOnThisDevice, onTooltipClicked } = props;
    const containerRef = useRef(null);
    const containerRect = useRect(containerRef);

    return (
        <Stack space="medium" padding="medium">
            <Button borderless size="small" startIcon={<ArrowLeftIcon />} onClick={onBack}>
                Tillbaka
            </Button>

            <Box display="flex" justifyContent="center" fluid>
                <BankIdColorIcon size="3xl" />
            </Box>

            <Stack space="xs">
                <Typography.Title align="center" level={3} color="gray-10">
                    {TEXTS[action].devicePromptViewTitle}
                </Typography.Title>

                <Typography.Body align="center" fontWeight="book" color="gray-30">
                    Välj ett alternativ för att fortsätta
                </Typography.Body>
            </Stack>

            <Box display="flex" flexDirection="column" alignItems="center" fluid>
                <Button primary onClick={openOnThisDevice} className={styles.button} marginBottom="xs">
                    Öppna BankID <BankIdIcon size="large" />
                </Button>

                <Button borderless onClick={openOnAnotherDevice} className={styles.button}>
                    BankID på annan enhet
                </Button>
            </Box>

            <Box display="flex" justifyContent="center" fluid>
                <Inline
                    space="base"
                    justifyContent="center"
                    display="flex"
                    fluid
                    style={{ maxWidth: TOOLTIP_CONTENT_WIDTH }}
                    ref={containerRef}
                >
                    <Typography.Body component="span" fontWeight="medium" color="gray-30">
                        {TEXTS[action].devicePromptViewWhyDoWeAsk}
                    </Typography.Body>

                    {containerRect && (
                        <Tooltip
                            positionFn={createPositionFn(containerRect)}
                            onOpen={onTooltipClicked}
                            trigger={
                                <InfoIcon
                                    color="blue-primary"
                                    size="medium"
                                    aria-label="se mer info"
                                    data-testid="device-prompt-tooltip-trigger"
                                />
                            }
                            style={{ zIndex: TOOLTIP_Z_INDEX }}
                            pointerClassName={styles.tooltipArrow}
                        >
                            <Stack space="base" data-testid="device-prompt-tooltip-content">
                                <Typography.Body fontWeight="bold">
                                    {TEXTS[action].devicePromptViewWhyDoWeAsk}
                                </Typography.Body>
                                <Typography.Body color="gray-30">
                                    {TEXTS[action].devicePromptViewWhyDoWeAskTooltip}
                                </Typography.Body>
                            </Stack>
                        </Tooltip>
                    )}
                </Inline>
            </Box>
        </Stack>
    );
}
