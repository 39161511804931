import { ApiStatus } from 'APP/constants/ApiStatusConstants';

export const acceptanceSliceInitialState = {
    apiStatus: {
        fetchAcceptance: ApiStatus.IDLE,
        postAcceptance: ApiStatus.IDLE,
        postAutogiroData: ApiStatus.IDLE,
    },
    applicationWithAcceptedRevision: {},
    acceptance: {},
    insuranceState: {
        loanInsurance: '',
        safetyInsurance: '',
        accidentInsurance: '',
    },
    upsells: {
        loanInsurance: {
            id: '',
            active: false,
            userAccepted: false,
        },
        safetyInsurance: {
            id: '',
            active: false,
            userAccepted: false,
        },
        accidentInsurance: {
            id: '',
            active: false,
            userAccepted: false,
        },
    },
    bankIdAutogiroIsVerified: false,
    bankIdReference: '',
};
