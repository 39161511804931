/* eslint-disable no-shadow */
export enum ACTIONS {
    AUTH = 'auth', // not used for SBAB case
    SIGN = 'sign',
}

// STATUS enums are values coming from SBAB API endpoint
export enum STATUS {
    INITIATED = 'INITIATED', // VIEWS.QR_CODE_PROMPT
    PENDING = 'PENDING', // VIEWS.POLLING
    COMPLETE = 'COMPLETE',
    FAILED = 'FAILED', // VIEWS.ERROR
}

// HINT_CODE for PENDING status
export enum HINT_CODES {
    OUTSTANDING_TRANSACTION = 'OUTSTANDING_TRANSACTION', // VIEWS.QR_CODE_PROMPT or VIEWS.DEVICE_PROMPT
    STARTED = 'STARTED', // VIEWS.POLLING
    USER_SIGN = 'USER_SIGN', // VIEWS.POLLING
    USER_MRTD = 'USER_MRTD', // VIEWS.POLLING
    NO_CLIENT = 'NO_CLIENT', // VIEWS.PENDING
    FAILED = 'FAILED', // VIEWS.ERROR
    EXPIRED_TRANSACTION = 'EXPIRED_TRANSACTION', // VIEWS.ERROR
    CERTIFICATE_ERROR = 'CERTIFICATE_ERROR', // VIEWS.ERROR
    USER_CANCEL = 'USER_CANCEL', // VIEWS.ERROR
    CANCELLED = 'CANCELLED', // VIEWS.ERROR
    START_FAILED = 'START_FAILED', // VIEWS.ERROR
    UNKNOWN = 'UNKNOWN', // VIEWS.ERROR
}

// VIEW enums are values used only in the frontend to show appropriate views inside the component
export enum VIEWS {
    INITIATING = 'INITIATING',
    DEVICE_PROMPT = 'DEVICE_PROMPT',
    SSN_PROMPT = 'SSN_PROMPT',
    QR_CODE_PROMPT = 'QR_CODE_PROMPT',
    POLLING = 'POLLING',
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export enum TESTING_TYPE {
    IDENTIFY = 'identify', // not used for SBAB case
    SIGN = 'sign',
}
