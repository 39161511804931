import { PROGRESS } from '@frontend/bank-id/enums';
import { Transport } from '@frontend/shared';
import { createAsyncThunk } from '@reduxjs/toolkit';

export interface PostResponseInterface {
    data: {
        reference: string;
        // Depends if BankID has been started on this device or other device,
        //  we will either get auto_start_token or qr_data string values.
        auto_start_token: string | null;
        qr_data: string | null;
    };
}

export interface PostPayloadInterface {
    endpoint: string;
    ssn?: string;
    sameDevice?: boolean;
}

export interface GetResponseInterface {
    data: {
        qr_data: string;
        progress: PROGRESS;
        details?: object;
    };
}

export interface GetPayloadInterface {
    endpoint: string;
    reference: string;
}

interface RejectValues {
    statusCode: number;
}

export const requestAction = createAsyncThunk<
    PostResponseInterface,
    PostPayloadInterface,
    {
        rejectValue: RejectValues;
    }
>('requestAction', async ({ endpoint, ssn, sameDevice }, thunkAPI) => {
    try {
        if (ssn) {
            return await Transport.post(endpoint, { ssn });
        }
        return await Transport.post(endpoint, { same_device: sameDevice });
    } catch (error) {
        return thunkAPI.rejectWithValue(error as RejectValues);
    }
});

export const pollAction = createAsyncThunk<
    GetResponseInterface,
    GetPayloadInterface,
    {
        rejectValue: RejectValues;
    }
>('pollAction', async ({ endpoint, reference }, thunkAPI) => {
    try {
        return await Transport.get(`${endpoint}/${reference}`);
    } catch (error) {
        return thunkAPI.rejectWithValue(error as RejectValues);
    }
});

export const cancelAction = createAsyncThunk<
    void,
    GetPayloadInterface,
    {
        rejectValue: RejectValues;
    }
>('cancelAction', async ({ endpoint, reference }, thunkAPI) => {
    try {
        return await Transport.post(`${endpoint}/${reference}/cancel`);
    } catch (error) {
        return thunkAPI.rejectWithValue(error as RejectValues);
    }
});
