import {
    BankIdIcon,
    Box,
    Button,
    CheckCircleIcon,
    CoAppDefaultIcon,
    Column,
    Columns,
    CrossIcon,
    Modal,
    ModalContent,
    PrivateIcon,
    Stack,
    Typography,
} from '@lendoab/aphrodite';
import { Divider } from 'APP/components/Divider';
import { ApplicantConstants } from 'APP/constants/ApplicantConstants';
import { OfferHelpers } from 'APP/helpers/OfferHelpers';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

export default function SbabDoubleSigningModal(props) {
    const { applicants, offer, open, onClose, onClick } = props;

    return (
        <Modal open={open} onClose={() => {}} style={{ zIndex: 9 }}>
            <ModalContent padding="2xl" fluid style={{ maxWidth: 850 }} borderRadius="small">
                <Stack space="2xl">
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Stack space="xs">
                            <Typography.Title level={4} color="gray-10">
                                Signera med BankID
                            </Typography.Title>
                            <Typography.Body>Du och din medsökande behöver inte signera samtidigt.</Typography.Body>
                        </Stack>
                        <Button borderless square size="xs" onClick={onClose}>
                            <CrossIcon color="blue" size="small" />
                        </Button>
                    </Box>

                    <Box>
                        {applicants.length > 0
                            ? applicants.map((applicant, index) => (
                                  <Fragment key={applicant.id}>
                                      <ApplicantRow
                                          applicant={applicant}
                                          offer={offer}
                                          onClick={onClick}
                                          marginBottom="large"
                                      />
                                      {applicants.length > index + 1 ? <Divider marginY="large" /> : null}
                                  </Fragment>
                              ))
                            : null}
                    </Box>
                </Stack>
            </ModalContent>
        </Modal>
    );
}

SbabDoubleSigningModal.propTypes = {
    applicants: PropTypes.array.isRequired,
    offer: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
};

function ApplicantRow(props) {
    const { applicant, offer, onClick } = props;
    const hasApplicantSigned = OfferHelpers.isOfferSignedByApplicant(offer, applicant.ssn);

    return (
        <Columns space="medium" collapseBelow="tablet">
            <Column display="flex" alignItems="center">
                <Box display="flex" alignItems="center">
                    <Adornment applicant={applicant} signed={hasApplicantSigned} marginRight="small" />
                    <Applicant applicant={applicant} signed={hasApplicantSigned} />
                </Box>
            </Column>
            <Column display="flex" justifyContent="flex-end">
                <BankIdButton
                    fluid
                    style={{ maxWidth: 220 }}
                    signed={hasApplicantSigned}
                    onClick={() => onClick(applicant)}
                />
            </Column>
        </Columns>
    );
}

ApplicantRow.propTypes = {
    applicant: PropTypes.object.isRequired,
    offer: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};

function Adornment(props) {
    const { applicant, signed, ...rest } = props;

    if (signed) {
        return (
            <Box display="flex" {...rest}>
                <CheckCircleIcon color="green-50" />
            </Box>
        );
    }

    return (
        <Box display="flex" {...rest}>
            {applicant.type === ApplicantConstants.TYPES.MAIN ? <PrivateIcon /> : <CoAppDefaultIcon />}
        </Box>
    );
}

Adornment.propTypes = {
    applicant: PropTypes.object.isRequired,
    signed: PropTypes.bool,
};

function Applicant(props) {
    const { applicant, signed, ...rest } = props;

    if (signed) {
        return (
            <Box {...rest}>
                <Typography.Body color="dark" fontWeight="medium">
                    {applicant.full_name} har signerat
                </Typography.Body>
            </Box>
        );
    }

    return (
        <Box {...rest}>
            <Typography.Body color="dark" fontWeight="medium">
                Signering för {applicant.full_name}
            </Typography.Body>
        </Box>
    );
}

Applicant.propTypes = {
    applicant: PropTypes.object.isRequired,
    signed: PropTypes.bool,
};

function BankIdButton(props) {
    const { signed, ...rest } = props;

    if (signed) {
        return null;
    }

    return (
        <Button primary {...rest}>
            Starta signering
            <BankIdIcon />
        </Button>
    );
}

BankIdButton.propTypes = {
    signed: PropTypes.bool,
};
