export const EXTENT_OF_CONTROL = {
    CONTROL_MORE_THAN_0_NO_MORE_THAN_25: 'CONTROL_MORE_THAN_0_NO_MORE_THAN_25',
    CONTROL_MORE_THAN_25_NO_MORE_THAN_50: 'CONTROL_MORE_THAN_25_NO_MORE_THAN_50',
    CONTROL_MORE_THAN_50_NO_MORE_THAN_75: 'CONTROL_MORE_THAN_50_NO_MORE_THAN_75',
    CONTROL_MORE_THAN_75: 'CONTROL_MORE_THAN_75',
    CONTROL_MORE_THAN_75_LESS_THAN_100: 'CONTROL_MORE_THAN_75_LESS_THAN_100',
    CONTROL_100: 'CONTROL_100',
    VIEWED_AS_BENEFICIAL_OWNER: 'VIEWED_AS_BENEFICIAL_OWNER',
};

export const SHOWING_PEP_INFO_MODAL = 'showingPEPInfoModal';
export const SHOWING_PEP_MODAL = 'showingPEPModal';
export const IDLE = 'idle';
export const PEP = 'pep';
export const PEP_MORE = 'pep more';
export const BENEFICIAL_OWNER = 'beneficialOwner';
