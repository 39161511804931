import { openHours as openHoursConfig } from 'APP/content/openHours';

const dayNumToName = {
    0: 'SUNDAY',
    1: 'MONDAY',
    2: 'TUESDAY',
    3: 'WEDNESDAY',
    4: 'THURSDAY',
    5: 'FRIDAY',
    6: 'SATURDAY',
};

export const OpenHoursHelpers = {
    /**
     * This method uses the config file config/customer_support/open_hours
     *
     * @param {Date} currentDateTime Todays date.
     * @param {Object} openHours object of open hours.
     * @param {array} schedules An array of schedules.
     *
     * @return {object} Returns object containing the current day with open and close time
     */
    getHoursToday(currentDateTime, openHours, schedules = []) {
        const currentDayString = dayNumToName[currentDateTime.getDay()];

        // Set the result to the default
        let result = openHours[currentDayString];

        if (schedules.length < 1) return result;

        schedules.forEach(schedule => {
            const startDate = new Date(schedule.start);
            const endDate = new Date(schedule.end);

            if (currentDateTime > startDate && (!schedule.end || currentDateTime < endDate)) {
                if (Object.keys(schedule.hours).includes(currentDayString)) {
                    result = schedule.hours[currentDayString];
                }
            }
        });

        return result;
    },

    /**
     * Method will return a boolean indicating if support is currently open or not.
     *
     * @param {Date} currentDateTime The current time to compare against
     * @param {object} todaysHours Object containing open and close hours
     * @param {string} todaysHours.open The hour support opens
     * @param {string} todaysHours.close The hour support closes
     *
     * @throws Throws an error if the supplied argument is incorrect.
     */
    checkSupportOpen(currentDateTime, todaysHours) {
        if (!currentDateTime || !todaysHours || !todaysHours.open || !todaysHours.close)
            throw new Error('The supplied argument is incorrect');

        const openDateTime = new Date();
        openDateTime.setHours(parseInt(todaysHours.open));

        const closeDateTime = new Date();
        closeDateTime.setHours(parseInt(todaysHours.close));

        return currentDateTime >= openDateTime && currentDateTime <= closeDateTime;
    },

    /**
     * Checks if customer service is open.
     *
     * @return {boolean} True if open, false if not.
     */
    isOpen() {
        const today = new Date();
        const todaysHours = OpenHoursHelpers.getHoursToday(today, openHoursConfig.default, openHoursConfig.schedule);

        try {
            return OpenHoursHelpers.checkSupportOpen(today, todaysHours);
        } catch (e) {
            return false;
        }
    },
};
