import { ApplicantConstants } from 'APP/constants/ApplicantConstants';
import { IApplicationApplicant } from 'APP/interfaces/applications.interfaces';

export const ApplicantHelpers = {
    /**
     * Get applicant type from an SSN and an array of applicants.
     * @param {Array<object>} applicants
     * @param {string} ssn
     */
    getApplicantType(applicants: IApplicationApplicant[], ssn: string): string | null {
        if (applicants.length > 0) {
            const user = applicants.find(applicant => applicant?.ssn === ssn);

            return user ? user.type : null;
        }

        return null;
    },

    getApplicant(applicants: IApplicationApplicant[], ssn: string): IApplicationApplicant | undefined | null {
        if (applicants?.length > 0) {
            return applicants.find(applicant => applicant?.ssn === ssn);
        }

        return null;
    },

    /**
     * Validates if the applicant type is either MAIN or CO
     * @param {string} type
     */
    isValidApplicantType(type: string): string | boolean {
        return type && (type === ApplicantConstants.TYPES.MAIN || type === ApplicantConstants.TYPES.CO);
    },

    mainApplicant: (applicants: IApplicationApplicant[]): IApplicationApplicant | boolean | undefined =>
        applicants?.length > 0 && applicants.find(applicant => applicant.type === ApplicantConstants.TYPES.MAIN),

    coApplicant: (applicants: IApplicationApplicant[]): IApplicationApplicant | boolean | undefined =>
        applicants?.length > 0 && applicants.find(applicant => applicant.type === ApplicantConstants.TYPES.CO),

    getApplicantSsn(applicant: IApplicationApplicant): string | null {
        if (applicant) {
            return applicant.ssn;
        }
        return null;
    },

    isApplicantSelfEmployed(applicant: IApplicationApplicant): boolean {
        if (applicant?.employment) {
            return (
                applicant.employment.type === ApplicantConstants.OCCUPATION.MORTGAGE_EMPLOYMENT_TYPES.SELF_EMPLOYMENT
            );
        }
        return false;
    },

    isApplicantMarried(applicant: IApplicationApplicant): boolean {
        if (applicant) {
            return applicant.civil_state === ApplicantConstants.CIVIL_STATUS.MARRIED;
        }
        return false;
    },

    isApplicantLivingTogether(applicant: IApplicationApplicant): boolean {
        if (applicant) {
            return applicant.civil_state === ApplicantConstants.CIVIL_STATUS.LIVING_TOGETHER;
        }
        return false;
    },

    hasCoApplicant(applicants: IApplicationApplicant[]): boolean {
        if (applicants?.length > 0) {
            const coApplicant = applicants.filter(applicant => applicant.type === ApplicantConstants.TYPES.CO);
            if (coApplicant?.length === 1) {
                return true;
            }
            return false;
        }
        return false;
    },
};
