import { createSlice } from '@reduxjs/toolkit';
import { ApiStatus } from 'APP/constants/ApiStatusConstants';
import { getNumberOfUnseenMessagesFromConversation } from 'APP/helpers/ConversationHelpers';

import { fetchConversationsAction } from './conversationsSliceActions';

const initialState = {
    conversations: [],
    numberOfUnseenMessages: 0,
    apiStatus: ApiStatus.IDLE,
};

const conversationsSlice = createSlice({
    name: 'conversations',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchConversationsAction.fulfilled, (state, action) => {
                state.apiStatus = ApiStatus.COMPLETED;
                state.conversations = action.payload.data;
                state.numberOfUnseenMessages =
                    action?.payload?.data?.length > 0
                        ? getNumberOfUnseenMessagesFromConversation(action.payload.data[0])
                        : 0;
            })
            .addCase(fetchConversationsAction.pending, state => {
                state.apiStatus = ApiStatus.STARTED;
            })
            .addCase(fetchConversationsAction.rejected, state => {
                state.apiStatus = ApiStatus.FAILED;
            });
    },
});

export default conversationsSlice.reducer;
