import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { States } from 'APP/components/Offers/offerHelpers/offerBusinessConstants';
import { ApiStatus, MontoConnectionApiStatus } from 'APP/constants/ApiStatusConstants';

import { ComplementsFlow, MontoSteps } from '../../constants/montoConstants';
import {
    createMontoConnectionAction,
    getIntegrationsAction,
    getMontoConnectionStatusAction,
} from './montoSliceActions';
import { ConnectionData, montoInitialState } from './montoSliceInitialState';

const initialState = montoInitialState;

export const montoSlice = createSlice({
    name: States.MONTO,
    initialState,
    extraReducers: builder => {
        // getIntegrationsAction
        builder.addCase(getIntegrationsAction.fulfilled, (state, action) => {
            state.apiStatuses.integration = ApiStatus.COMPLETED;
            state.integrations = action.payload.data;
        });
        builder.addCase(getIntegrationsAction.pending, state => {
            state.apiStatuses.integration = ApiStatus.STARTED;
        });
        builder.addCase(getIntegrationsAction.rejected, (state, action) => {
            state.apiStatuses.integration = ApiStatus.FAILED;
            Sentry.captureException(action.error.message);
        });

        // createMontoConnectionAction
        builder.addCase(createMontoConnectionAction.fulfilled, (state, action) => {
            state.apiStatuses.createdMontoConnectionStatus = ApiStatus.COMPLETED;
            state.createdMontoConnectionData = action.payload.data;
        });
        builder.addCase(createMontoConnectionAction.pending, state => {
            state.apiStatuses.createdMontoConnectionStatus = ApiStatus.STARTED;
        });
        builder.addCase(createMontoConnectionAction.rejected, (state, action) => {
            state.apiStatuses.createdMontoConnectionStatus = ApiStatus.FAILED;
            Sentry.captureException(action.error.message);
        });

        // getConnectionsStatus
        builder.addCase(getMontoConnectionStatusAction.fulfilled, (state, action) => {
            const validStatuses = ['AUTHED', 'ENABLED', 'SYNCED', 'DOWNLOADED'];
            const validItem = action.payload.data.find((item: ConnectionData) =>
                validStatuses.includes(item.connection_info.status)
            );
            if (validItem) {
                state.apiStatuses.currentMontoConnectionStatus = validItem.connection_info.status;
            }
        });
        builder.addCase(getMontoConnectionStatusAction.pending, state => {
            state.apiStatuses.currentMontoConnectionStatus = MontoConnectionApiStatus.PENDING;
        });
        builder.addCase(getMontoConnectionStatusAction.rejected, (state, action) => {
            if (action.error.message === 'could not get connections for given company id') {
                state.apiStatuses.currentMontoConnectionStatus = MontoConnectionApiStatus.IDLE;
            } else {
                state.apiStatuses.currentMontoConnectionStatus = MontoConnectionApiStatus.FAILED;
                Sentry.captureException(action.error.message);
            }
        });
    },

    reducers: {
        putSelectedIntegration: (state, action) => {
            state.selectedIntegration = action.payload;
        },
        updateMontoStep: (state, action) => {
            state.step = action.payload;
        },
        updateComplementsFlow: (state, action) => {
            state.complementsFlow = action.payload;
        },
        updateIsOfferPageFlow: (state, action) => {
            state.isOfferPageFlow = action.payload;
        },
        updateCreatedMontoConnectionStatus: (state, action) => {
            state.apiStatuses.createdMontoConnectionStatus = action.payload;
        },
        updateCurrentMontoConnectionStatus: (state, action) => {
            state.apiStatuses.currentMontoConnectionStatus = action.payload;
        },
        updateSelectedIntegrationFields: (state, action) => {
            state.selectedIntegrationFields = action.payload;
        },
        resetMontoState: state => {
            state.apiStatuses = {
                integration: ApiStatus.IDLE,
                createdMontoConnectionStatus: ApiStatus.IDLE,
                currentMontoConnectionStatus: MontoConnectionApiStatus.PENDING,
            };
            state.complementsFlow = ComplementsFlow.Idle;
            state.step = MontoSteps.Idle;
            state.selectedIntegration = {};
            state.createdMontoConnectionData = {};
            state.selectedIntegrationFields = [];
            state.integrations = [];
        },
    },
});

export const {
    putSelectedIntegration,
    resetMontoState,
    updateIsOfferPageFlow,
    updateMontoStep,
    updateComplementsFlow,
    updateCurrentMontoConnectionStatus,
    updateSelectedIntegrationFields,
    updateCreatedMontoConnectionStatus,
} = montoSlice.actions;
export default montoSlice.reducer;
