export const BankIdConstants = {
    PROGRESS: {
        IDLE: 'idle',
        OUTSTANDING_TRANSACTION: 'OUTSTANDING_TRANSACTION', // we have started polling but user has not opened bankid app
        USER_SIGN: 'USER_SIGN', // bank id app is open but we are waiting for the 6 digits codes
        COMPLETE: 'COMPLETE', // user entered 6 digit codes
        USER_CANCEL: 'USER_CANCEL', // user cancel bankid
        NO_CLIENT: 'NO_CLIENT', // user not open bankid app
        NO_USER: 'NO_USER',
    },
    MAX_ATTEMPTS: 30,
    WAIT_TIME: 1000,
};

export const BankIdStatus = {
    IDLE: 'idle',
    PENDING_AUTH: 'pending_auth',
    PENDING_POLL: 'pending_poll',
    REJECTED: 'rejected',
    COMPLETED: 'completed',
};
