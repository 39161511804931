import { ApiStatus } from 'APP/constants/ApiStatusConstants';
import { IRevisionSlice } from 'APP/interfaces/general.interfaces';

export function hasSmartRevision(state: IRevisionSlice): boolean {
    if (
        state.smartRevisionData.recommendedTotalLoanAmount > 0 &&
        state.smartRevisionData.recommendedAmountToCollect > 0 &&
        state.smartRevisionData.recommendedAmortizeLength > 0 &&
        state.smartRevisionData.apiStatus === ApiStatus.COMPLETED
    ) {
        return true;
    }
    return false;
}
