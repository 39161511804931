import { IDLE } from 'APP/constants/BeneficialOwner';
import { BizAcceptance, BizApplication, IBeneficialOwners } from 'APP/interfaces/business/application';
import { BizOffer } from 'APP/interfaces/feature/business/offers.interfaces';

export const offerInitialState: BizOffer = {
    id: '',
    application_id: '',
    revision: 1,
    amount: 0,
    total_cost: 0,
    amortize_length: 0,
    amount_to_solve_external: 0,
    amount_to_solve_internal: 0,
    product_id: '',
    product: {
        id: '',
        name: '',
        partner: '',
        internal_name: '',
        logo_name: '',
        partner_information: {
            id: '',
            name: '',
            internal_name: '',
        },
    },
    monthly_cost_first: 0,
    administration_fee: 0,
    autogiro_fee: 0,
    complements: [],
    interest_rate_effective: 0,
    monthly_cost_average: 0,
    interest_rate_nominal: 0,
    monthly_cost_last: 0,
    repayment_type: '',
    setup_fee: 0,
    upsells: [],
    type: '',
    business_loan_type: '',
    early_redemption_fee: false,
    finalize_acceptance_url: '',
};

export const applicationInitialState: BizApplication = {
    id: '',
    revision: 1,
    type: '',
    status: '',
    products: [],
    applicants: [],
    company: {},
    created_at: '',
    responses_expired_at: '',
    beneficial_owners: [],
    campaign_id: '',
    meta_property_questions_allowed: false,
    company_info: {
        company_data: { name: '' },
    },
    offers: [],
    private_business: false,
};

export const beneficialOwnersInitialState: IBeneficialOwners = {
    status: IDLE,
    isNotPEP: true,
    confirmData: true,
    controlUsingStocks: true,
    owners: [],
    owner: { ssn: '', email: '' },
};

export const acceptanceInitialState: BizAcceptance = {
    application_id: '',
    id: '',
    revision: 1,
    parameters: {},
    responses: [
        {
            response: {
                files: [],
            },
        },
    ],
};
