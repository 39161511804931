export const ApplicationStatus = {
    IDLE: 'idle',
    PENDING: 'pending',
    RESOLVED: 'resolved',
    ACTIVE: 'active',
    REJECTED: 'rejected',
    FULFILLED: 'fulfilled',
    COMPLETED: 'completed',
};
export type ApplicationStatus = (typeof ApplicationStatus)[keyof typeof ApplicationStatus];
