export const INTERACTIONS = {
    CHANGE_WITH_INPUT: 'change with input',
    CHANGE_WITH_SLIDER: 'change with slider',
    CHANGE_WITH_BUTTON: 'change with button',
    CHANGE: 'change',
    PRESS: 'press',
    VIEW: 'view',
    CLICK: 'click',
};

export const FieldType = {
    Button: 'button',
    Checkbox: 'checkbox',
    Hint: 'hint',
    Input: 'input',
    Radio: 'radio',
    Slider: 'slider',
    Select: 'select',
};

export const FieldName = {
    LoanAmount: 'loan amount',
    AmortizeLength: 'amortize length',
    CollectLoans: 'collect loans',
    CollectAmount: 'collect amount',
    HasCoApplicant: 'has co applicant',
    CoApplicant: 'co applicant',
    Backwards: 'backwards',
    InsuranceAmount: 'insurance amount',
    InformationConsent: 'information consent',
};

export const StepName = {
    Index: 'index',
    CoApplicant: 'coApplicant',
    PersonalInformation: 'personalInformation',
    Accommodation: 'accommodation',
    Employment: 'employment',
    CarOwnership: 'carOwnership',
    ConfirmationPage: 'confirmationPage',
    ChooseCompany: 'chooseCompany',
    PurposeRevenue: 'purposeRevenue',
    Guarantor: 'guarantor',
    Debts: 'debts',
    Properties: 'properties',
};

export const Product = {
    Private: 'private',
    Business: 'business',
    Mortgage: 'mortgage',
    Insurance: 'insurance',
};

// We have two cookies:
// tracking_partner: contains the tracking id
// affiliate_data: depending on the partner, various tracking info

export const TRACKING_CONFIG = {
    DIRECT_TRACKING_SOURCE_ID: 298,
    ORGANIC_TRACKING_SOURCE_ID: 11608,
    DEFAULT_BLOCKET_TRACKING_ID: 328,
    DEFAULT_COOKIE_LIFE: 60, // Set in days,
    COMPRICER_PARTNER_ID: 11563,
    COOKIE_NAMES: {
        TRACKING_PARTNER_ID: 'tracking_partner',
        AFFILIATE_DATA: 'affiliate_data',
    },
};
