import { createSlice } from '@reduxjs/toolkit';
import { BizAcceptance, BizApplication, IBeneficialOwners } from 'APP/interfaces/business/application';
import { BizOffer } from 'APP/interfaces/feature/business/offers.interfaces';

import {
    acceptanceInitialState,
    applicationInitialState,
    beneficialOwnersInitialState,
    offerInitialState,
} from './initialStates';

export interface IBusinessInitialState {
    application: BizApplication;
    offer: BizOffer;
    beneficial_owners: IBeneficialOwners;
    acceptance: BizAcceptance;
}

export const businessSliceInitialState: IBusinessInitialState = {
    application: applicationInitialState,
    offer: offerInitialState,
    beneficial_owners: beneficialOwnersInitialState,
    acceptance: acceptanceInitialState,
};

export const businessSlice = createSlice({
    name: 'businessSlice',
    initialState: businessSliceInitialState,
    reducers: {
        updateBusinessApplication: (state, action) => {
            state.application = action.payload;
        },
        updateBusinessOffer: (state, action) => {
            state.offer = action.payload;
        },
        updateBeneficialOwners: (state, action) => {
            state.beneficial_owners = {
                ...state.beneficial_owners,
                ...action.payload,
            };
        },
        updateBusinessAcceptance: (state, action) => {
            state.acceptance = action.payload;
        },
        resetBusinessSlice: state => {
            state.application = businessSliceInitialState.application;
            state.offer = businessSliceInitialState.offer;
            state.beneficial_owners = businessSliceInitialState.beneficial_owners;
        },
    },
});

export const {
    updateBusinessApplication,
    updateBusinessOffer,
    updateBeneficialOwners,
    resetBusinessSlice,
    updateBusinessAcceptance,
} = businessSlice.actions;

export default businessSlice.reducer;
