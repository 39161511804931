import { Box, Stack, Typography } from '@lendoab/aphrodite';
import React from 'react';

export default function SkandiaGreenLoanInfo() {
    return (
        <Box>
            <Typography.Title level={4} style={{ marginBottom: 8 }} color="gray-10">
                Vad är ett grönt bolån?
            </Typography.Title>
            <Stack fluid space="medium">
                <Typography.Body>
                    Ett grönt bolån innebär att du som bor i en energieffektiv bostad kan få rabatt på din ränta. För
                    att du ska beviljas rabatten måste du ha en giltig energideklaration för klass A eller B.
                </Typography.Body>

                <Stack component="ul" paddingLeft="xl">
                    <Typography.Body component="li">
                        Du med energiklass A eller B får 0,10 procentenheter i ränteavdrag.
                    </Typography.Body>
                </Stack>

                <Typography.Body>
                    Vill du veta mer? Ring våra handläggare på
                    <Typography.Link marginLeft="base" style={{ whiteSpace: 'nowrap' }} href="tel:0771 13 13 10">
                        0771 13 13 10
                    </Typography.Link>
                    .
                </Typography.Body>
            </Stack>
        </Box>
    );
}
