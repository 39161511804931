import { createAsyncThunk } from '@reduxjs/toolkit';
import { Transport } from 'APP/actions/transport';

import { IntegrationField } from './montoSliceInitialState';

export const getIntegrationsAction = createAsyncThunk('getIntegrations/get', async () => {
    const response = await Transport.get(`monto/integrations`);
    return response;
});

interface ICreateMontoConnectionAction {
    integrationKey: string;
    fields?: IntegrationField[];
    responseID: string;
}
export const createMontoConnectionAction = createAsyncThunk(
    'createMontoConnection/post',
    async ({ integrationKey, fields, responseID }: ICreateMontoConnectionAction) => {
        const data = fields
            ? { integration_key: integrationKey, fields: fields, response_id: responseID }
            : { integration_key: integrationKey, fields: null, response_id: responseID };
        const response = await Transport.post(`monto/connections`, { data });

        return response;
    }
);

interface IGetMontoConnectionStatusAction {
    connection_id: string;
}

export const getMontoConnectionStatusAction = createAsyncThunk(
    'getConnectionStatus/get',
    async ({ company_id }: { company_id: string }) => {
        const response = await Transport.get(`monto/connections/${company_id}`);
        return response;
    }
);

interface IUpdateMontoConnectionStatusAction extends IGetMontoConnectionStatusAction {
    status: string;
}

export const updateMontoConnectionStatusAction = createAsyncThunk(
    'updateMontoConnectionStatus/patch',
    async ({ connection_id, status }: IUpdateMontoConnectionStatusAction) => {
        const response = await Transport.patch(`monto/connections/${connection_id}`, { status });
        return response;
    }
);
