import { Analytics } from 'APP/Analytics';
import { BusinessComplementTypes } from 'APP/content/businessComplements';
import { IComplement } from 'APP/interfaces/private/offers.interfaces';

export function hasComplementsToUpload(complements: IComplement[]): boolean {
    if (!complements) return false;

    for (let i = 0; i < complements.length; i++) {
        if (complements[i].type === BusinessComplementTypes.PL_STATEMENT) {
            return true;
        }
        if (complements[i].type === BusinessComplementTypes.BALANCE_SHEET) {
            return true;
        }
        if (complements[i].type === BusinessComplementTypes.BANK_STATEMENTS) {
            return true;
        }
    }

    return false;
}

export function hasOnlyBankStatementComplement(complements: IComplement[]): boolean {
    if (!complements) return false;
    if (complements.length === 1 && complements[0].type === BusinessComplementTypes.BANK_STATEMENTS) return true;
    return false;
}

// COPIED FROM PORTUGAL

// rfc2231Encode encodes strings according to the RFC2231 header parameter
// format.
export function rfc2231Encode(str: string): string {
    // encodeURIComponent will encode all characters with percent-encoding except
    // the characters in:
    //  * RFC 3986 section 2.2 Reserved Characters
    //  * RFC 3986 section 2.3 Unreserved Characters
    //
    // However, according to RFC2231 also the reserved characters should be
    // encoded. We therefore replace all characters not in the unreserved
    // character set with their percent encoding.
    //
    // By using encodeURIComponent we also get proper handling of composite UTF-8
    // characters.
    //
    // The % character must also be ignored to not "double encode" characters that
    // was encoded by encodeURIComponent.
    return encodeURIComponent(str).replace(/[^A-Za-z0-9\-_.~%]/g, c => {
        return `%${c.charCodeAt(0).toString(16).toUpperCase()}`;
    });
}

export function fileUploadPushFormFieldError(
    errorMessage: string,
    productInternalName: string,
    errorCode?: string
): void {
    Analytics.pushFormFieldError({
        formName: 'file upload',
        formStep: 1,
        fieldName: 'upload component',
        fieldType: 'input',
        fieldValue: 'file',
        errorMessage: errorMessage,
        errorCode: errorCode ? errorCode : '',
        variation: productInternalName,
    });
}
export function fileUploadpushFormFieldInteraction(fieldInteraction: string, productInternalName: string): void {
    Analytics.pushFormFieldInteraction({
        formName: 'file upload',
        formStep: 1,
        fieldName: 'upload component',
        fieldType: 'input',
        fieldValue: 'file',
        fieldInteraction: fieldInteraction,
        variation: productInternalName,
    });
}
