import isMobileLib from 'is-mobile';
import { useEffect, useState } from 'react';

export interface DeviceInterface {
    isMobileOrTablet: boolean;
    isChromeOnAppleDevice: boolean;
    isFirefoxOnAppleDevice: boolean;
    isOperaTouchOnAppleDevice: boolean;
    isOnAppleDevice: boolean;
}

const useDevice = (): DeviceInterface | undefined => {
    const [device, setDevice] = useState<DeviceInterface>();

    useEffect(() => {
        const isMobileOrTablet: boolean = isMobileLib({
            tablet: true,
            featureDetect: true,
        });
        const isChromeOnAppleDevice: boolean = Boolean(navigator.userAgent.match(/CriOS/));
        const isFirefoxOnAppleDevice: boolean = Boolean(navigator.userAgent.match(/FxiOS/));
        const isOperaTouchOnAppleDevice: boolean = Boolean(navigator.userAgent.match(/OPT/));
        const isOnAppleDevice: boolean = Boolean(navigator.userAgent.match(/iPad|iPhone|iPod/));

        setDevice({
            isMobileOrTablet,
            isChromeOnAppleDevice,
            isFirefoxOnAppleDevice,
            isOperaTouchOnAppleDevice,
            isOnAppleDevice,
        });
    }, []);

    return device;
};

export default useDevice;
