import { Transport } from 'APP/actions/transport';

function wait(ms = 5000): Promise<void> {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}
export const OfferActions = {
    /**
     * @param {string} offerId
     */
    async get(offerId: string) {
        return Transport.get(`offers/${offerId}`);
    },

    /**
     * @param {string} offerId
     * @param {string} acceptanceId
     */
    async getAcceptance(offerId: string) {
        const res = await OfferActions.get(offerId);
        if (!res.data.acceptance) {
            throw new Error('No acceptance was found');
        }

        return res.data.acceptance;
    },
    /**
     * @param {string} offerId
     */
    async getMortgageOffer(offerId: string) {
        const res = await Transport.get(`mortgage-offers/${offerId}`);
        if (!res.data) {
            throw new Error('No mortgage offer was found');
        }
        return res.data;
    },

    /**
     * @param {string} offerId
     */
    async initiateSbabBankIdSigning<T = unknown>(offerId: string, data: unknown): Promise<T> {
        return Transport.post(`offers/${offerId}/mortgage/sign`, { data });
    },

    /**
     * @param {string} offerId
     */
    async getSbabBankIdStatus<T = unknown>(offerId: string): Promise<T> {
        await wait();
        return Transport.get(`offers/${offerId}/mortgage/sign/status`);
    },

    /**
     * @param {string} offerId
     */
    async cancelSbabBankIdSigning<T = unknown>(offerId: string, data: unknown): Promise<T> {
        return Transport.post(`offers/${offerId}/mortgage/sign/cancel`, { data });
    },
};
