/* eslint-disable no-shadow */
export enum ACTIONS {
    AUTH = 'auth',
    SIGN = 'sign',
}

// PROGRESS enums are values coming from backend/BankId
export enum PROGRESS {
    OUTSTANDING_TRANSACTION = 'OUTSTANDING_TRANSACTION', // VIEWS.QR_CODE_PROMPT
    CREATED = 'CREATED', // VIEWS.QR_CODE_PROMPT
    USER_SIGN = 'USER_SIGN', // VIEWS.POLLING
    COMPLETE = 'COMPLETE', // call onVerified function
    INVALID_PARAMETERS = 'INVALID_PARAMETERS', // VIEWS.ERROR
    USER_CANCEL = 'USER_CANCEL', // VIEWS.ERROR
    NO_CLIENT = 'NO_CLIENT', // VIEWS.ERROR
    NO_USER = 'NO_USER', // VIEWS.ERROR
}

// VIEW enums are values used only in the frontend to show appropriate views inside Auth or Sign components
export enum VIEWS {
    DEVICE_PROMPT = 'DEVICE_PROMPT',
    SSN_PROMPT = 'SSN_PROMPT',
    QR_CODE_PROMPT = 'QR_CODE_PROMPT',
    POLLING = 'POLLING',
    PENDING = 'PENDING',
    ERROR = 'ERROR',
}

export enum TESTING_TYPE {
    IDENTIFY = 'identify',
    SIGN = 'sign',
}

export enum TRACKING_METHODS {
    QR = 'qr',
    SSN = 'ssn',
}
