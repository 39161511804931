import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
    HINT_CODES,
    STATUS,
    VIEWS,
} from 'APP/components/Applications/Mortgage/MortgageCommitmentReceipts/PartnerSpecificCommitmentReceipts/Sbab/BankId/enums';

import { pollAction, requestAction } from './sbabBankIdActions';

export interface DeviceInterface {
    isMobileOrTablet: boolean;
    isChromeOnAppleDevice: boolean;
    isFirefoxOnAppleDevice: boolean;
    isOperaTouchOnAppleDevice: boolean;
    isOnAppleDevice: boolean;
}

export interface State {
    view?: VIEWS;
    status?: STATUS;
    qrData?: string;
    autoStartToken?: string;
    pollCount: number;
    hintCode?: HINT_CODES;
    signedAt?: string;
    ssn?: string;
    device: DeviceInterface;
}

export interface ApplicantResponse {
    applicant_external_id: string;
    applicant_ssn: string;
    autostart_token: string | undefined;
    hint_code: HINT_CODES | undefined;
    qr_string: string | undefined;
    signed_at: string | undefined;
    status: STATUS | undefined;
}

const initialState = {
    view: undefined,
    status: undefined,
    qrData: undefined,
    autoStartToken: undefined,
    pollCount: 0,
    hintCode: undefined,
    signedAt: undefined,
    ssn: undefined,
    device: {},
} as State;

const sbabBankIdSlice = createSlice({
    name: 'sbab-bank-id',
    initialState,
    reducers: {
        setView(state, { payload }: PayloadAction<VIEWS>) {
            state.view = payload;
        },
        setSsn(state, { payload }: PayloadAction<string>) {
            state.ssn = payload;
        },
        setDevice(state, { payload }: PayloadAction<DeviceInterface>) {
            state.device = payload;
        },
        resetState() {
            return {
                ...initialState,
            };
        },
    },
    extraReducers: builder => {
        // ----- request action ----
        builder.addCase(requestAction.fulfilled, (state: State) => {
            state.view = VIEWS.INITIATING;
            state.pollCount++;
        });
        builder.addCase(requestAction.rejected, () => {
            return {
                ...initialState,
                view: VIEWS.ERROR,
            };
        });

        // ----- poll action ----
        builder.addCase(pollAction.fulfilled, (state: State, { payload }) => {
            const applicant = payload?.data?.find((res: ApplicantResponse) => res.applicant_ssn === state.ssn);

            if (applicant) {
                state.qrData = applicant.qr_string;
                state.autoStartToken = applicant.autostart_token;
                state.hintCode = applicant.hint_code;
                state.signedAt = applicant.signed_at;
                state.status = applicant.status;

                // required to initiate the view in here (and check for device),
                // because we receive autoStartToken & qrData from the POST response
                if (state.view === VIEWS.INITIATING) {
                    if (state.device?.isMobileOrTablet) {
                        state.view = VIEWS.DEVICE_PROMPT;
                    } else {
                        state.view = VIEWS.QR_CODE_PROMPT;
                    }
                }

                switch (applicant?.status) {
                    case STATUS.PENDING:
                        state.view = getViewFromHintCode(applicant.hint_code, state.view, state.device);
                        if (state.view !== VIEWS.ERROR) {
                            state.pollCount++;
                        }
                        break;
                    case STATUS.INITIATED:
                        state.view = getViewFromStatus(applicant.status, state.view);
                        state.pollCount++;
                        break;
                    case STATUS.FAILED:
                    case STATUS.COMPLETE:
                        state.view = getViewFromStatus(applicant.status, state.view);
                        break;

                    default:
                        state.pollCount++;
                        break;
                }
            } else {
                state.pollCount++;
            }
        });
        builder.addCase(pollAction.rejected, () => {
            return {
                ...initialState,
                view: VIEWS.ERROR,
            };
        });
    },
});

export const { setView, setSsn, setDevice, resetState } = sbabBankIdSlice.actions;
export default sbabBankIdSlice.reducer;

export function getViewFromStatus(status: STATUS | undefined, currentView: VIEWS | undefined): VIEWS {
    switch (status) {
        case STATUS.INITIATED:
            return currentView ?? VIEWS.DEVICE_PROMPT;
        case STATUS.PENDING:
            return VIEWS.POLLING;
        case STATUS.COMPLETE:
            return VIEWS.SUCCESS;
        case STATUS.FAILED:
            return VIEWS.ERROR;
        default:
            return VIEWS.ERROR;
    }
}

export function getViewFromHintCode(
    hintCode: HINT_CODES | undefined,
    currentView: VIEWS | undefined,
    device: DeviceInterface
): VIEWS {
    switch (hintCode) {
        case HINT_CODES.OUTSTANDING_TRANSACTION:
            if (currentView === VIEWS.QR_CODE_PROMPT || currentView === VIEWS.DEVICE_PROMPT) {
                return currentView;
            }
            return device?.isMobileOrTablet ? VIEWS.DEVICE_PROMPT : VIEWS.QR_CODE_PROMPT;
        case HINT_CODES.NO_CLIENT:
            return VIEWS.PENDING;
        case HINT_CODES.STARTED:
        case HINT_CODES.USER_SIGN:
        case HINT_CODES.USER_MRTD:
            return VIEWS.POLLING;
        case HINT_CODES.EXPIRED_TRANSACTION:
        case HINT_CODES.CERTIFICATE_ERROR:
        case HINT_CODES.USER_CANCEL:
        case HINT_CODES.CANCELLED:
        case HINT_CODES.START_FAILED:
        case HINT_CODES.UNKNOWN:
            return VIEWS.ERROR;
        default:
            return VIEWS.ERROR;
    }
}
