export const States = {
    STEP_1: 'step_1',
    STEP_2: 'step_2',
    FILE_UPLOAD: 'fileUpload',
    FILE_UPLOAD_OR_MONTO: 'fileUploadOrMonto',
    MONTO: 'monto',
    PENDING: 'pending',
    REJECTED: 'rejected',
    RESOLVED: 'resolved',
    ACCEPTED: 'accepted',
    REQUIREMENTS: 'requirements',
    OVERVIEW: 'overview',
    RECEIPT: 'receipt',
    BENEFICIAL_OWNERS_DATA_MISSING: 'beneficial_owners.DATA_MISSING',
    BENEFICIAL_OWNERS_CONTROL_QUESTION: 'beneficial_owners.CONTROL_QUESTION',
    BENEFICIAL_OWNERS_DATA_EXISTS: 'beneficial_owners.DATA_EXISTS',
    PENDING_BENEFICIAL_OWNERS: 'pending_beneficial_owners',
    UPLOAD_COMPLEMENTS: 'uploadComplements',
} as const;

export const Effects = {
    TRACK_FLOW_STEP_IMPRESSION: 'trackFlowStepImpression',
    ACCEPT: 'accept',
    REDIRECT: 'redirect',
    SCROLL_TO_TOP: 'scrolToTop',
    CAPTURE_ERROR: 'captureError',
    TRACK_OFFER_ACCEPTED: 'trackAcceptedOffer',
    TRACK_OPTIMIZELY_ACCEPTED_OFFER: 'trackOptimizelyAcceptedOffer',
    TRACK_NEXT_STEP: 'trackNextStepButton',
    POST_BENEFICIAL_OWNERS: 'postBeneficialOwners',
} as const;
