import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiStatus } from 'APP/constants/ApiStatusConstants';
import { IEmailValidationState, IValidationPayload } from 'APP/interfaces/feature/emailValidation';

import {
    privateApplicantEmailValidationAction,
    privateNewEmailValidationAction,
    privateUpdateEmailAction,
} from './emailValidationSliceActions';

const initialState: IEmailValidationState = {
    private: {
        applicantEmail: {
            isApplicantEmailValid: null,
            apiStatus: ApiStatus.IDLE,
            applicantEmail: '',
        },
        applicantNewEmail: {
            isApplicantNewEmailValid: null,
            apiStatus: ApiStatus.IDLE,
            applicantNewEmail: '',
        },
        updateEmail: {
            apiStatus: ApiStatus.IDLE,
        },
    },
};

const emailValidationSlice = createSlice({
    name: 'emailValidation',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(
                privateApplicantEmailValidationAction.fulfilled,
                (state: IEmailValidationState, action: PayloadAction<IValidationPayload>) => {
                    state.private.applicantEmail.apiStatus = ApiStatus.COMPLETED;
                    state.private.applicantEmail.isApplicantEmailValid = action.payload.data;
                    state.private.applicantEmail.applicantEmail = action.payload.email;
                }
            )
            .addCase(privateApplicantEmailValidationAction.pending, state => {
                state.private.applicantEmail.apiStatus = ApiStatus.STARTED;
                state.private.applicantEmail.isApplicantEmailValid = false;
            })
            .addCase(privateApplicantEmailValidationAction.rejected, state => {
                state.private.applicantEmail.apiStatus = ApiStatus.FAILED;
                state.private.applicantEmail.isApplicantEmailValid = false;
            })
            .addCase(
                privateNewEmailValidationAction.fulfilled,
                (state: IEmailValidationState, action: PayloadAction<IValidationPayload>) => {
                    state.private.applicantNewEmail.apiStatus = ApiStatus.COMPLETED;
                    state.private.applicantNewEmail.isApplicantNewEmailValid = action.payload.data;
                    state.private.applicantNewEmail.applicantNewEmail = action.payload.email;
                }
            )
            .addCase(privateNewEmailValidationAction.pending, (state: IEmailValidationState) => {
                state.private.applicantNewEmail.apiStatus = ApiStatus.STARTED;
                state.private.applicantNewEmail.isApplicantNewEmailValid = false;
            })
            .addCase(privateNewEmailValidationAction.rejected, (state: IEmailValidationState) => {
                state.private.applicantNewEmail.apiStatus = ApiStatus.FAILED;
                state.private.applicantNewEmail.isApplicantNewEmailValid = false;
            })
            .addCase(privateUpdateEmailAction.fulfilled, (state: IEmailValidationState) => {
                state.private.updateEmail.apiStatus = ApiStatus.COMPLETED;
            })
            .addCase(privateUpdateEmailAction.pending, (state: IEmailValidationState) => {
                state.private.updateEmail.apiStatus = ApiStatus.STARTED;
            })
            .addCase(privateUpdateEmailAction.rejected, (state: IEmailValidationState) => {
                state.private.updateEmail.apiStatus = ApiStatus.FAILED;
            });
    },
    reducers: {
        makeEmailValidationIdle: state => {
            state.private.applicantNewEmail.apiStatus = ApiStatus.IDLE;
            state.private.applicantNewEmail.isApplicantNewEmailValid = null;
        },
    },
});
export const { makeEmailValidationIdle } = emailValidationSlice.actions;
export default emailValidationSlice.reducer;
