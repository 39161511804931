import { Box, StatusCircle } from '@lendoab/aphrodite';
import React, { ReactElement } from 'react';
import QRCode from 'react-qr-code';

import styles from './Qr.module.scss';

export interface QrProps {
    value?: string | null;
}

export function Qr(props: QrProps): ReactElement {
    const { value } = props;

    return (
        <Box
            display="inline-block"
            backgroundColor="white"
            borderColor="blue-90"
            borderStyle="solid"
            borderWidth="base"
            borderRadius="medium"
            padding={['large', 'large', '2xl']}
            className={styles.root}
        >
            <Box display="flex" justifyContent="center" alignItems="center">
                {value ? (
                    <QRCode className={styles.qrCode} value={value} data-testid="qr-code-svg" />
                ) : (
                    <Box display="flex" justifyContent="center" alignItems="center" className={styles.qrCode}>
                        <StatusCircle size="extra-small" status="loading" color="blue-30" data-testid="loader" />
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default Qr;
