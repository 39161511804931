import { createSlice } from '@reduxjs/toolkit';
import { ApiStatus } from 'APP/constants/ApiStatusConstants';

import { getApplicationsByTypeAction } from './applicationsActiveTypesSliceActions';

const initialState = {
    activeTypes: {},
    apiStatus: ApiStatus.IDLE,
};

const applicationsActiveTypesSlice = createSlice({
    name: 'applications-active-types',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getApplicationsByTypeAction.fulfilled, (state, action) => {
                state.apiStatus = ApiStatus.COMPLETED;
                state.activeTypes = action.payload.data;
            })
            .addCase(getApplicationsByTypeAction.pending, state => {
                state.apiStatus = ApiStatus.STARTED;
            })
            .addCase(getApplicationsByTypeAction.rejected, state => {
                state.apiStatus = ApiStatus.FAILED;
            });
    },
});

export default applicationsActiveTypesSlice.reducer;
