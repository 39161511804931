import { ArrowLeftIcon, BankIdIcon, Box, Button, Input, Stack, Typography } from '@lendoab/aphrodite';
import React, { ReactElement, useRef } from 'react';

// This component is intended to be used for development and testing (stage) purposes
// It is NOT intended to handle any validations nor to be rendered in production

export interface SsnPromptProps {
    onBack?: () => void;
    onSubmit: (ssn: string) => void;
}

export default function SsnPrompt({ onSubmit, onBack }: SsnPromptProps): ReactElement {
    const inputRef = useRef<HTMLInputElement>(null);

    function handleSubmit(): void {
        const ssn = inputRef.current?.value;
        if (!ssn) return;
        onSubmit(ssn);
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            paddingX={['medium', 'medium', '3xl']}
            paddingTop={['medium', 'medium', '3xl']}
            paddingBottom={['xl', 'xl', '3xl']}
            style={{ minHeight: '100%' }}
        >
            <Stack marginBottom="large" fluid space="base">
                <Typography.Body component="label" htmlFor="ssn" color="gray-30" fontWeight="bold">
                    Personnummer
                </Typography.Body>
                <Input type="tel" id="ssn" ref={inputRef} placeholder="ÅÅÅÅMMDDXXXX" />
            </Stack>
            <Stack space="xs">
                <Button fluid primary onClick={handleSubmit} data-testid="bank-id:login-button">
                    Skicka in <BankIdIcon />
                </Button>
                {onBack ? (
                    <Button fluid onClick={onBack}>
                        <ArrowLeftIcon />
                        <Typography.Body color="gray-10" weight="bold" component="span">
                            Tillbaka
                        </Typography.Body>
                    </Button>
                ) : null}
            </Stack>
        </Box>
    );
}
