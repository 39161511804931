import { Box, Image, Text } from '@lendoab/aphrodite';
import { BoxProps } from '@lendoab/aphrodite/dist/Box';
import * as Sentry from '@sentry/react';
import MortgageHelpers from 'APP/helpers/MortgageHelpers';
import { getProductLogoSvg } from 'APP/helpers/UrlHelpers';
import { IOfferProduct } from 'APP/interfaces/general.interfaces';
import React, { SyntheticEvent, useEffect, useState } from 'react';

import styles from './ProductLogo.module.scss';

interface ProductLogoProps extends BoxProps {
    product: IOfferProduct;
    disabled?: boolean;
}

export default function ProductLogo(props: ProductLogoProps): React.ReactElement {
    const { product, disabled, ...rest } = props;
    const [showImage, setShowImage] = useState(true);

    const productName = product?.logo_name || product?.internal_name;
    const prettyProductName = MortgageHelpers.getProductName(product);
    const src = productName ? getProductLogoSvg(product) : '';

    function onImageLoadFailed(event: SyntheticEvent<HTMLImageElement, Event>): void {
        setShowImage(false);
        Sentry.captureException(event);
    }

    useEffect(() => {
        if (!product) {
            setShowImage(false);
        }
    }, [product]);

    return (
        <Box display="flex" alignItems="center" className={styles.container} {...rest}>
            {showImage ? (
                <Image
                    className={styles.logo}
                    src={src}
                    alt={productName}
                    onError={onImageLoadFailed}
                    style={{ opacity: disabled ? '0.4' : '1' }}
                />
            ) : (
                <Text size="small" className={styles.text} color={disabled ? 'gray-80' : 'gray-10'}>
                    {prettyProductName || 'Bank logo'}
                </Text>
            )}
        </Box>
    );
}
