export const Steps = {
    index: 'index',
    step_1: 'step_1',
    step_2: 'step_2',
    bankID: 'bankID',
    step_3: 'step_3',
    step_4: 'step_4',
    step_5_public: 'step_5.public',
    step_5_private: 'step_5.private',
    properties: 'properties',
    properties_pending_fetch: 'pending.properties.fetch',
    properties_pending_submit: 'pending.properties.submit',
    rejected: 'rejected',
    resolved: 'resolved',
    pending: 'pending',
    active: 'active',
    autogiro: 'autogiro',
    autogiro_submission_summary: 'autogiro_submission_summary',
};
