// eslint-disable-next-line no-shadow
export enum States {
    INITIATING = 'initiating',
    IDLE = 'idle',
    PENDING = 'pending',
    SUCCESS = 'success',
    REJECTED = 'rejected',
    DISALLOWED = 'disallowed',
    EXPIRED = 'expired',
}
