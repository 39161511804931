import SbabDocumentsApprovedConfirmation from './SbabDocumentsApprovedConfirmation';
import SbabLoanPromiseOfferReceipt from './SbabLoanPromiseOfferReceipt';
import SbabOfferReceipt from './SbabOfferReceipt';
import TempSbabLoanPromiseOfferReceipt from './TempSbabLoanPromiseOfferReceipt';

export default {
    SbabOfferReceipt,
    SbabLoanPromiseOfferReceipt,
    TempSbabLoanPromiseOfferReceipt,
    SbabDocumentsApprovedConfirmation,
};
