import SbabGreenLoanInfo from 'APP/components/Applications/Mortgage/MortgageGreenLoan/PartnerSpecificGreenLoanInfo/SbabGreenLoanInfo';
import SkandiaGreenLoanInfo from 'APP/components/Applications/Mortgage/MortgageGreenLoan/PartnerSpecificGreenLoanInfo/SkandiaGreenLoanInfo';
import { PARTNER_INTERNAL_NAME } from 'APP/constants/MortgageConstants';

export default {
    [PARTNER_INTERNAL_NAME.SBAB]: {
        greenLoanInfo: {
            component: SbabGreenLoanInfo,
        },
    },
    [PARTNER_INTERNAL_NAME.SKANDIA]: {
        greenLoanInfo: {
            component: SkandiaGreenLoanInfo,
        },
    },
};
