/* eslint-disable react/prop-types */
import { createReturnUrl, DevicePrompt, Error, getBankIdAppUrl, Pending, QrCodePrompt } from '@frontend/bank-id';
import {
    Box,
    ButtonRow,
    ButtonRowButton,
    CheckCircleIcon,
    CheckIcon,
    Stack,
    StatusCircle,
    Typography,
} from '@lendoab/aphrodite';
import { cancelAction, pollAction, requestAction } from 'APP/feature/sbabBankId/sbabBankIdActions';
import { resetState, setDevice, setSsn, setView } from 'APP/feature/sbabBankId/sbabBankIdSlice';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ACTIONS, TESTING_TYPE, VIEWS } from './enums';

const POLL_START_COUNT = 0;
const POLL_TIMEOUT_TIME = 1000;

export default function SbabBankId(props) {
    const { offerId, ssn, onSuccess, onError, onBack, onCancel, device } = props;
    const { view, qrData, autoStartToken, pollCount } = useSelector(state => state.sbabBankIdSlice);
    const dispatch = useDispatch();

    const timeoutRef = useRef();

    useEffect(() => clearAndCancel, []);

    useEffect(() => {
        requestInitialData();
    }, []);

    useEffect(() => {
        if (pollCount > POLL_START_COUNT) {
            triggerPoll();
        }
    }, [pollCount]);

    async function requestInitialData() {
        try {
            dispatch(setSsn(ssn));
            dispatch(setDevice(device));
            dispatch(setView(VIEWS.INITIATING));

            return await dispatch(requestAction({ offerId, ssn })).unwrap();
        } catch (error) {
            handleOnError(error);
            return undefined;
        }
    }

    async function startPolling() {
        try {
            return await dispatch(pollAction({ offerId })).unwrap();
        } catch (error) {
            handleOnError(error);
            return undefined;
        }
    }

    async function handleOpenOnThisDevice() {
        try {
            dispatch(setView(VIEWS.PENDING));

            if (!autoStartToken) {
                throw new Error('No auto_start_token');
            }

            const testingType = TESTING_TYPE.SIGN;
            const redirectUrl = createReturnUrl(device, testingType);
            const bankIdAppUrl = getBankIdAppUrl(autoStartToken, redirectUrl);

            window.open(bankIdAppUrl, '_top');
        } catch (error) {
            handleOnError(error);
        }
    }

    function handleOnError(error) {
        clearTimeout(timeoutRef.current);
        dispatch(setView(VIEWS.ERROR));
        onError?.(error);
    }

    function triggerPoll() {
        timeoutRef.current = setTimeout(() => startPolling(), POLL_TIMEOUT_TIME);
    }

    function handleRetry() {
        dispatch(resetState());

        requestInitialData();
    }

    function handleOnCancel() {
        dispatch(resetState());

        clearAndCancel();
        onCancel?.();
    }

    function handleOnBack() {
        if (!device.isMobileOrTablet) {
            onBack();
        } else {
            dispatch(setView(VIEWS.DEVICE_PROMPT));
        }

        clearAndCancel();
    }

    function initQrCodeView() {
        dispatch(setView(VIEWS.QR_CODE_PROMPT));
    }

    function clearAndCancel() {
        clearTimeout(timeoutRef.current);

        if (pollCount > POLL_START_COUNT) {
            dispatch(cancelAction({ offerId, ssn }));
        }
    }

    return (
        <>
            {view === VIEWS.INITIATING ? <Pending message="Var vänlig vänta" onCancel={onBack} /> : null}

            {view === VIEWS.DEVICE_PROMPT ? (
                <DevicePrompt
                    action={ACTIONS.SIGN}
                    onBack={onBack}
                    openOnThisDevice={handleOpenOnThisDevice}
                    openOnAnotherDevice={initQrCodeView}
                />
            ) : null}

            {view === VIEWS.QR_CODE_PROMPT ? (
                <QrCodePrompt
                    qrData={qrData}
                    openOnThisDevice={handleOpenOnThisDevice}
                    isMobileOrTablet={device.isMobileOrTablet}
                    onBack={handleOnBack}
                />
            ) : null}

            {view === VIEWS.SUCCESS ? <Success message="Signeringen lyckades!" onDone={onSuccess} /> : null}

            {view === VIEWS.PENDING ? <Pending message="Väntar svar från BankID..." onCancel={handleOnCancel} /> : null}

            {view === VIEWS.POLLING ? (
                <Pending message="Skriv in din säkerhetskod i BankID-appen" onCancel={handleOnCancel} />
            ) : null}

            {view === VIEWS.ERROR ? (
                <Error
                    title="Signeringen avbröts"
                    message="Vänligen försök igen."
                    onRetry={handleRetry}
                    onCancel={handleOnCancel}
                />
            ) : null}
        </>
    );
}

function Success(props) {
    const { message, onDone } = props;

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ height: '100%' }}>
            <Box paddingTop="8xl" paddingX="2xl" paddingBottom="4xl">
                <Box display="flex" justifyContent="center" alignItems="center" marginBottom={['medium', '2xl', '2xl']}>
                    <StatusCircle
                        gradientTo="#29C466"
                        gradientFrom="#059142"
                        status="complete"
                        paddingBottom="xs"
                        size="medium"
                    >
                        <CheckIcon color="gray-10" size="2xl" />
                    </StatusCircle>
                </Box>
                <Stack alignItems="center" space="xs">
                    <Typography.Title level={3} color="gray-10" textAlign="center">
                        {message}
                    </Typography.Title>
                    <Box style={{ maxWidth: 528 }}>
                        <Typography.Body textAlign="center" color="gray-10">
                            För att få ett besked om lånelöfte behöver även din medsökande logga in på Mina sidor och
                            signera med BankID.
                        </Typography.Body>
                    </Box>
                </Stack>
            </Box>
            <ButtonRow>
                <ButtonRowButton primary onClick={onDone}>
                    Klar <CheckCircleIcon />
                </ButtonRowButton>
            </ButtonRow>
        </Box>
    );
}
