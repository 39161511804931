import { NavigationLink, PhoneIcon } from '@lendoab/aphrodite';
import { useCallMeModal } from 'APP/components/Modals/CallMeModal';
import { CustomerSupportConstants } from 'APP/constants/CustomerSupportConstants';
import { OpenHoursHelpers } from 'APP/helpers/OpenHoursHelpers';
import React from 'react';

export default function DesktopCallButton(props) {
    const { ...rest } = props;
    const { openModal } = useCallMeModal();

    if (OpenHoursHelpers.isOpen()) {
        return (
            <NavigationLink
                variant="basic"
                href={`tel:${CustomerSupportConstants.rawPhoneNumber}`}
                iconLeft={<PhoneIcon color="light" />}
                {...rest}
            >
                {CustomerSupportConstants.prettyPhoneNumber}
            </NavigationLink>
        );
    }

    return (
        <NavigationLink component="button" onClick={openModal} variant="basic" iconLeft={<PhoneIcon color="light" />}>
            {CustomerSupportConstants.prettyPhoneNumber}
        </NavigationLink>
    );
}
