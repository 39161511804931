export const PROPERTY_TYPES = {
    HOUSE: 'house',
    APARTMENT: 'apartment',
    HOLIDAY_HOME: 'holiday_home',
    TERRACED_HOUSE: 'terraced_house',
    TENANCY: 'tenancy',
};

export const HOUSING_TENURE_TYPES = {
    OWNERSHIP: 'ownership',
    TENANT_OWNERSHIP: 'tenant_ownership',
};

export const PURPOSE = {
    NEW: 'new',
    EXPAND: 'expand',
    REFINANCE: 'refinance',
    LOAN_PROMISE: 'loan_promise',
};

export const OFFER_STATES = {
    DRAFT: 'draft',
    OFFERED: 'offered',
    PICKED: 'picked',
    ACCEPTED: 'accepted',
    AWAITING_COMPLEMENTS: 'awaiting_complements',
    AWAITING_COMPLEMENTS_APPROVAL: 'awaiting_complements_approval',
    AWAITING_FINAL_ACCEPTANCE: 'awaiting_final_acceptance',
    AWAITING_BILL_OF_DEBT: 'awaiting_bill_of_debt',
    BILL_OF_DEBT_SENT: 'bill_of_debt_sent',
    AWAITING_PAYMENT: 'awaiting_payment',
    PAID: 'paid',
    DENIED_BY_CUSTOMER: 'denied_by_customer',
    WITHDRAWN: 'withdrawn',
};

export const DOCUMENT_SECTIONS = {
    AMORTIZATION_BASIS: 'AMORTIZATION_BASIS',
    ANNUAL_REPORT: 'ANNUAL_REPORT',
    APARTMENT_LIST: 'APARTMENT_LIST',
    CASH_DEPOSIT: 'CASH_DEPOSIT',
    EMPLOYER_CERTIFICATE: 'EMPLOYER_CERTIFICATE',
    OBJECT_DESCRIPTION: 'OBJECT_DESCRIPTION',
    SALARY_SPECIFICATION: 'SALARY_SPECIFICATION',
    VALUATION: 'VALUATION',
};

export const PROPERTY_TYPE_NAMES = {
    [PROPERTY_TYPES.HOUSE]: 'villa',
    [PROPERTY_TYPES.APARTMENT]: 'lägenhet',
    [PROPERTY_TYPES.HOLIDAY_HOME]: 'fritidshus',
    [PROPERTY_TYPES.TERRACED_HOUSE]: 'radhus',
    [PROPERTY_TYPES.TENANCY]: 'hyresrätt',
};

// These internal names match with the ones in database. These are not likely to change.
// Please bear in mind that changing these will affect things in FE like the offer receipt page for example.

export const PARTNER_INTERNAL_NAME = {
    HYPOTEKET: 'hypoteket',
    MARGINALEN: 'marginalen_bank',
    SVEA: 'svea_direkt',
    BLUESTEP: 'bluestep',
    LANDSHYPOTEK: 'landshypotek',
    NORDAX: 'nordax',
    SKANDIA: 'skandia',
    SBAB: 'sbab',
    TEST_PARTNER: 'testpartner',
};

export const PARTNER_ADDRESS_INFO = {
    [PARTNER_INTERNAL_NAME.HYPOTEKET]: 'Hypoteket Bolån Sverige AB, 556928-1156, Barnhusgatan 20, 111 23 Stockholm.',
    [PARTNER_INTERNAL_NAME.MARGINALEN]:
        'Marginalen Bank Bankaktiebolag (publ), 516406-0807, Adolf Fredriks Kyrkogata 8, 11137 Stockholm.',
    [PARTNER_INTERNAL_NAME.SVEA]: 'Svea Bank AB, 556158-7634, Evenemangsgatan 31, 169 79 Solna.',
    [PARTNER_INTERNAL_NAME.BLUESTEP]: 'Bluestep Bank AB (publ), reg. no. 556717-5129, Sveavägen 163, 113 46 Stockholm.',
    [PARTNER_INTERNAL_NAME.TEST_PARTNER]: 'Test parnter, reg. no. xxxxxx-xxxx, Sveavägen 1, 113 46 Stockholm.',
    [PARTNER_INTERNAL_NAME.SKANDIA]:
        'Långivare är Skandia AB (publ), 556647-7286, Box 23124, 104 35 Stockholm. (nov 2020)',
    [PARTNER_INTERNAL_NAME.LANDSHYPOTEK]:
        'Långivare är Landshypotek AB (publ), 556647-7286, Box 23124, 104 35 Stockholm. (nov 2020)',
};

export const REVISION_STATUS = {
    CREATED: 'created',
    CHANGE_CREATED: 'change_created',
    PREREQUISITES_COMPLETED: 'prerequisites_completed',
    APPROVED: 'approved',
    DENIED: 'denied',
    PRODUCTS_RESPONDED: 'products_responded',
    USER_ACCEPTED_OFFER: 'user_accepted_offer',
    BILL_OF_DEBT_SENT: 'bill_of_debt_sent',
    PAID_OUT: 'paid_out',
    CANCELLED: 'cancelled',
};

export const APPLICATION_STATUS = {
    WINDOW_OPEN_WITH_OFFERS: 'WINDOW_OPEN_WITH_OFFERS',
    WINDOW_OPEN_NO_OFFERS: 'WINDOW_OPEN_NO_OFFERS',
    OFFERS_EXPIRED: 'OFFERS_EXPIRED',
    WINDOW_CLOSED_NO_OFFERS: 'WINDOW_CLOSED_NO_OFFERS',
    NO_APPLICATION: 'NO_APPLICATION',
    APPLICATION_ACCEPTED: 'APPLICATION_ACCEPTED',
};

export const COMPLEMENT_STATUS = {
    REVIEW: 'review',
    SENDING_IN_PROGRESS: 'sending_in_progress',
    SENT_TO_PARTNER: 'sent_to_partner',
    COLLECTED_EXTERNALLY: 'collected_externally',
    REJECTED_BY_PARTNER: 'rejected_by_partner',
    REJECTED: 'rejected',
};

export const PARTNER_SPECIFIC_INFO = {
    hypoteket: {
        getComplementsDependency: () => [PURPOSE.EXPAND, PURPOSE.REFINANCE],
        getDocumentsDependency: () => [
            DOCUMENT_SECTIONS.AMORTIZATION_BASIS,
            DOCUMENT_SECTIONS.EMPLOYER_CERTIFICATE,
            DOCUMENT_SECTIONS.SALARY_SPECIFICATION,
        ],
        getAddressInfo: () => 'Hypoteket Bolån Sverige AB, 556928-1156, Barnhusgatan 20, 111 23 Stockholm.',
    },
    sbab: {
        getComplementsDependency: () => [],
        getDocumentsDependency: mortgagePurpose => {
            switch (mortgagePurpose) {
                case PURPOSE.NEW:
                    return [
                        DOCUMENT_SECTIONS.EMPLOYER_CERTIFICATE,
                        DOCUMENT_SECTIONS.SALARY_SPECIFICATION,
                        DOCUMENT_SECTIONS.OBJECT_DESCRIPTION,
                        DOCUMENT_SECTIONS.CASH_DEPOSIT,
                        DOCUMENT_SECTIONS.APARTMENT_LIST,
                    ];

                default:
                    return [
                        DOCUMENT_SECTIONS.AMORTIZATION_BASIS,
                        DOCUMENT_SECTIONS.EMPLOYER_CERTIFICATE,
                        DOCUMENT_SECTIONS.SALARY_SPECIFICATION,
                        DOCUMENT_SECTIONS.APARTMENT_LIST,
                        DOCUMENT_SECTIONS.VALUATION,
                        DOCUMENT_SECTIONS.ANNUAL_REPORT,
                    ];
            }
        },
        getAddressInfo: () =>
            // sbab address does not exit in the database
            '',
    },
    svea: {
        getComplementsDependency: () => [],
        getDocumentsDependency: () => [],
        getAddressInfo: () => 'Svea Bank AB, 556158-7634, Evenemangsgatan 31, 169 79 Solna.',
    },
    bluestep: {
        getComplementsDependency: () => [],
        getDocumentsDependency: () => [],
        getAddressInfo: () => 'Bluestep Bank AB (publ), reg. no. 556717-5129, Sveavägen 163, 113 46 Stockholm.',
    },
    testpartner: {
        getComplementsDependency: () => [],
        getDocumentsDependency: () => [],
        getAddressInfo: () => 'Test parnter, reg. no. xxxxxx-xxxx, Sveavägen 1, 113 46 Stockholm.',
    },
};

export const USPS = {
    GREEN_LOAN: 'green_loan',
    DIGITAL_SIGNING: 'digital_signing',
    NOT_MOVING_ACCOUNTS: 'not_moving_accounts',
};

export const LOAN_USPS_NAMES = {
    [USPS.GREEN_LOAN]: 'Grönt bolån',
    [USPS.DIGITAL_SIGNING]: 'Signera lånehandlingar digitalt',
    [USPS.NOT_MOVING_ACCOUNTS]: 'Inget krav på flytt av lönekonto',
};

export const LOAN_PROMISE_USPS_NAMES = {
    [USPS.GREEN_LOAN]: 'Grönt bolån',
    [USPS.DIGITAL_SIGNING]: 'Signering krävs för giltigt lånelöfte',
    [USPS.NOT_MOVING_ACCOUNTS]: 'Inget krav på flytt av lönekonto',
};
