import { BankIcon, CoAppDefaultIcon, LendoIcon, PrivateIcon } from '@lendoab/aphrodite';
import { ApplicantConstants } from 'APP/constants/ApplicantConstants';
import { RECIPIENTS as RecipientsEnum } from 'APP/constants/ConversationConstants';
import { IConversation, IMessage, IRecipient } from 'APP/interfaces/helpers';

export function getRecipientIcon(recipientType: string, applicantType: string) {
    switch (recipientType) {
        case RecipientsEnum.CUSTOMER:
            if (applicantType && applicantType === ApplicantConstants.TYPES.CO) {
                return CoAppDefaultIcon;
            } else {
                return PrivateIcon;
            }
        case RecipientsEnum.ADMIN:
            return LendoIcon;
        case RecipientsEnum.PARTNER:
            return BankIcon;

        default:
            return PrivateIcon;
    }
}

export function getRecipientFromConversation(
    recipients: IRecipient[],
    conversation: IConversation
): IRecipient | undefined {
    return recipients.find(recipient =>
        conversation.type === RecipientsEnum.PARTNER
            ? recipient.partner_id === conversation.partner_id
            : recipient.applicant_ssn === conversation.applicant_ssn
    );
}

export function getLastUnseenMessage(messages: IMessage[]): IMessage | null {
    if (messages?.length > 0) {
        const unseenMessages = messages.filter(message => !message.seen);
        return unseenMessages[unseenMessages.length - 1];
    }

    return null;
}

export function getNumberOfUnseenMessagesFromConversation(conversation: IConversation) {
    return conversation?.total_unseen_outgoing; // total_unseen is viewed from Admin perspective, therefore total_unseen_outgoing is used
}
