import { createAsyncThunk } from '@reduxjs/toolkit';
import { Transport } from 'APP/actions/transport';
import { IRootState } from 'APP/interfaces/general.interfaces';

export const fetchApplicationsAction = createAsyncThunk('applications/get', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as IRootState;
    const isMortgageApplicationExist = state.applicationsActiveTypesSlice.activeTypes.mortgage;

    const response = await Transport.get('applications');
    if (isMortgageApplicationExist) {
        const responseMortgage = await Transport.get('applications/mortgage');
        return response.data.applications.concat(responseMortgage.data.applications);
    } else {
        return response.data.applications;
    }
});
