export const SAFETY_INSURANCE_FORM = 'safetyInsuranceForm';
export const PRIVATE_APPLICATION_FORM = 'applicationForm';
export const BUSINESS_APPLICATION_FORM = 'businessApplicationForm';
export const CONTACT_FORM = 'ContactForm';
export const LOAN_CALCULATOR_FORM = 'loanCalculatorForm';
export const PRIVATE_APPLICATION_STATE = 'privateState';
export const BUSINESS_APPLICATION_STATE = 'businessState';
export const COLLABORATION_PARTNER_FORM = 'collaborationPartnerForm';
export const MY_ECONOMY_FORM = 'minEkonomiForm';
export const CONSENT_COOKIE_NAME = 'consent';
export const COMPRICER_QUERY_PARAMS = 'compricerQueryParams';
export const SEEN_COOKIE_NAME = 'seenCookieBanner';

export const INSURANCE_AUTOGIRO = 'insuranceAutogiroForm';
export const BankIdConstants = {
    PROGRESS: {
        IDLE: 'idle',
        OUTSTANDING_TRANSACTION: 'OUTSTANDING_TRANSACTION', // we have started polling but user has not opened bankid app
        USER_SIGN: 'USER_SIGN', // bank id app is open but we are waiting for the 6 digits codes
        COMPLETE: 'COMPLETE', // user entered 6 digit codes
        USER_CANCEL: 'USER_CANCEL', // user cancel bankid
        NO_CLIENT: 'NO_CLIENT', // user not open bankid app
        NO_USER: 'NO_USER',
    },
    MAX_ATTEMPTS: 30,
    WAIT_TIME: 1000,
};

export const BANKID_STATUSES = {
    IDLE: 'idle',
    QR_CODE_PENDING: 'pending',
    SUCCESS: 'success',
    REJECTED: 'rejected',
    MISSING_COMPANIES: 'missing_companies',
    PENDING_SSN: 'pending_ssn',
    PENDING_POLL: 'pending_polling',
    AWAITING_POLL: 'awaiting_polling',
    USER_SIGN: 'user_sign',
    PENDING_AUTH: 'pending_auth',
    COMPLETED: 'completed',
};
