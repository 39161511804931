import {
    Alert,
    Box,
    Button,
    Card,
    CheckCircleIcon,
    Column,
    Columns,
    Stack,
    Text,
    Typography,
} from '@lendoab/aphrodite';
import { Divider } from 'APP/components/Divider';
import ProductLogo from 'APP/components/ProductLogo/ProductLogo';
import { ApplicantConstants } from 'APP/constants/ApplicantConstants';
import { PROPERTY_TYPE_NAMES, PROPERTY_TYPES } from 'APP/constants/MortgageConstants';
import { ApplicantHelpers } from 'APP/helpers/ApplicantHelpers';
import { applyThousandSeparator } from 'APP/helpers/number-formatters';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';

function getPropertyPrefix(propertyType) {
    if (propertyType === PROPERTY_TYPES.APARTMENT || propertyType === PROPERTY_TYPES.HOUSE) {
        return 'en';
    } else return 'ett';
}

export default function TempSbabLoanPromiseOfferReceipt(props) {
    const { offer, mortgageApplication } = props;

    const mainApplicant = ApplicantHelpers.mainApplicant(mortgageApplication.applicants);
    const coApplicant = ApplicantHelpers.coApplicant(mortgageApplication.applicants);

    const { SELF_EMPLOYMENT, PROBATIONARY_EMPLOYMENT, TEMPORARY_EMPLOYMENT, SUBSTITUTE_EMPLOYMENT, HOURLY_EMPLOYMENT } =
        ApplicantConstants.OCCUPATION.MORTGAGE_EMPLOYMENT_TYPES;

    const tempEmploymentTypes = [
        PROBATIONARY_EMPLOYMENT,
        TEMPORARY_EMPLOYMENT,
        SUBSTITUTE_EMPLOYMENT,
        HOURLY_EMPLOYMENT,
    ];

    const sbabOfferAcceptedInfo = [
        'Banken behöver godkänna objektet innan kontraktsskrivning.',
        'Om befintligt boende finns ska den vara såld före utbetalning.',
        'Inkomst kan behöva styrkas vid nyttjande av lånelöfte så tänk på att vara förberedd med ett arbetsgivarintyg, dina tre senaste lönespecifikationer och en objektbeskrivning.',
    ];

    return (
        <Box>
            <Typography.Title color="gray-10" level={3} fontWeight="bold" style={{ marginBottom: 16 }}>
                Du har fått ett lånelöfte!
            </Typography.Title>
            <Card borderRadius="small" paddingBottom={['xl', '2xl']} paddingTop="none" paddingX="none">
                <Box
                    fluid
                    padding="medium"
                    display="flex"
                    alignItems="center"
                    backgroundColor="blue-99"
                    borderTopLeftRadius="small"
                    borderTopRightRadius="small"
                >
                    <CheckCircleIcon size="small" color="green-dark" />
                    <Text size="xs" marginLeft="xs">
                        Lånelöfte för {getPropertyPrefix(mortgageApplication.loan_promise_property.type)}{' '}
                        <Text component="span" size="xs" weight="bold">
                            {PROPERTY_TYPE_NAMES[mortgageApplication.loan_promise_property.type]}
                        </Text>
                    </Text>
                </Box>
                <Stack fluid space="xs" paddingX={['xl', '2xl']} paddingTop={['xl', '2xl']}>
                    {offer?.product && <ProductLogo product={offer.product} justifyContent="center" />}
                    <Text fontWeight="bold" align="center" marginTop={['xs', 'medium']} size="xs">
                        Du kan buda upp till:
                    </Text>
                    <Text size={['large', 'xl']} color="green-40" align="center" weight="bold">
                        {applyThousandSeparator(offer.amount + mortgageApplication.down_payment)} kr
                    </Text>
                </Stack>

                <Divider marginTop={['xl', '2xl']} marginBottom={['xl', '2xl']} />
                <Box paddingX={['medium', '2xl']}>
                    <Columns flexWrap={['wrap', 'nowrap']}>
                        <Column width={[12, 12, 4]} marginBottom={['medium', 'none']}>
                            <Text size="xs" weight="bold">
                                Lånebelopp
                            </Text>
                            <Text size="xs">{applyThousandSeparator(offer.amount)} kr</Text>
                        </Column>
                        <Column width={[12, 12, 4]} marginBottom={['medium', 'none']}>
                            <Text size="xs" weight="bold">
                                Kontantinsats
                            </Text>
                            <Text size="xs">{applyThousandSeparator(mortgageApplication.down_payment)} kr</Text>
                        </Column>
                        {mortgageApplication.loan_promise_property.monthly_cost ? (
                            <Column width={[12, 12, 4]}>
                                <Text size="xs" weight="bold">
                                    Högsta månadsavgift
                                </Text>
                                <Text size="xs">
                                    {applyThousandSeparator(mortgageApplication.loan_promise_property.monthly_cost)}{' '}
                                    kr/mån
                                </Text>
                            </Column>
                        ) : null}
                    </Columns>
                </Box>
                <Divider marginTop={['xl', '2xl']} marginBottom={['xl', '2xl']} />
                <Box paddingX={['medium', '2xl']}>
                    <Text weight="bold" size="small">
                        Villkor
                    </Text>
                    <ul style={{ paddingLeft: '18px' }}>
                        <li>
                            <Text size="xs" paddingBottom="xs">
                                Lånelöftet är giltigt t.o.m{' '}
                                <Text component="span" size="xs" weight="bold">
                                    {dayjs(offer.expires_at).format('LL')}
                                </Text>
                            </Text>
                        </li>
                        {sbabOfferAcceptedInfo.map((info, i) => (
                            <li key={i}>
                                <Text size="xs" paddingBottom="xs">
                                    {info}
                                </Text>
                            </li>
                        ))}
                        {(mainApplicant.employment.type === SELF_EMPLOYMENT ||
                            coApplicant?.employment?.type === SELF_EMPLOYMENT) && (
                            <li>
                                <Text size="xs" paddingBottom="xs">
                                    Som egenföretagare krävs oftast ett års aktivitet och en taxerad inkomst eller ett
                                    pågående konsultavtal med resultat- och balansrapporter.
                                </Text>
                            </li>
                        )}
                        {(tempEmploymentTypes.includes(mainApplicant.employment.type) ||
                            tempEmploymentTypes.includes(coApplicant?.employment?.type)) && (
                            <li>
                                <Text size="xs" paddingBottom="xs">
                                    Du behöver vara godkänd av Arbetslöshetskassan och en individuell bedömning sker vid
                                    vunnen budgivning.
                                </Text>
                            </li>
                        )}
                        <li>
                            <Text size="xs" paddingBottom="xs">
                                Tänk på att det här är ett lånelöfte, det innebär att du inte kan skriva kontrakt förrän
                                du fått ett beviljat lån.
                            </Text>
                        </li>
                    </ul>
                    <Box paddingTop={['none', 'medium']}>
                        <Text weight="bold" size="small">
                            Vad händer nu?
                        </Text>
                        <ol style={{ paddingLeft: '28px' }}>
                            <li>
                                <Text size="xs">
                                    Läs igenom villkoren och hämta ditt lånelöfte genom att logga in på{' '}
                                    <Typography.Link
                                        marginLeft="base"
                                        color="blue-40"
                                        style={{ textDecoration: 'underline', whiteSpace: 'nowrap' }}
                                        fontWeight="medium"
                                        href="https://www.sbab.se"
                                    >
                                        www.sbab.se
                                    </Typography.Link>
                                </Text>
                            </li>
                            <li>
                                <Text size="xs"> Börja att buda på ditt drömboende!</Text>
                            </li>
                        </ol>
                    </Box>
                    <Alert marginTop="xl" variant="info">
                        <Typography.Body color="gray-10" fontWeight="bold">
                            När du vunnit en budgivning är det viktigt att du får bostaden godkänd och lånet beviljat
                            innan du skriver på kontraktet. Ring oss på
                            <Typography.Link
                                marginLeft="base"
                                style={{ whiteSpace: 'nowrap' }}
                                href="tel:0771 13 13 10"
                            >
                                0771 13 13 10{' '}
                            </Typography.Link>
                            för att få snabbare hjälp med din bolåneansökan.
                        </Typography.Body>
                    </Alert>
                </Box>
                <Divider marginTop={['xl', '2xl']} marginBottom={['xl', '2xl']} />
                <Box paddingX={['medium', '2xl']}>
                    <Button
                        type="button"
                        fluid
                        primary
                        href="https://www.lendo.se/bolan/lan-ny-bostad"
                        component="a"
                        target="_blank"
                    >
                        Ansök om nytt bolån
                    </Button>
                    <Text size="xxs" marginTop="medium">
                        Lånelöftet förmedlas av Lendo AB, och långivaren är SBAB.
                    </Text>
                </Box>
            </Card>
        </Box>
    );
}

TempSbabLoanPromiseOfferReceipt.propTypes = {
    offer: PropTypes.object.isRequired,
    applicant: PropTypes.object.isRequired,
    mortgageApplication: PropTypes.object.isRequired,
};
