import { v4 as uuidv4 } from 'uuid';

/**
 * An utility function to generate unique id.
 *
 * @return {String} Unique ID
 */

export function createUUID(): string {
    return uuidv4();
}

export function determineApplicantSex(swedishSsn: string): string {
    if (!swedishSsn) {
        return swedishSsn;
    }
    const digits = `${swedishSsn}`.split('');
    const checkDigit = Number(digits.slice(-2)[0]);
    const odd = checkDigit % 2 === 1;
    return odd ? 'Male' : 'Female';
}

/**
 * Normalize comma seperated numbers like this: "1,2 324" to 1.2324
 *
 * Or, "1,022.55" to 1022.55
 * @param {String} value Number with comma to normalize
 * @returns number|NaN
 */
export function normalizeCommaSeparatedNumber(value: string): number {
    if (value && typeof value === 'string') {
        const valueWithoutSpace = value.replace(/ /g, '');
        if (value.match(/\./gi) !== null) {
            return Number(valueWithoutSpace.replace(/,/g, ''));
        }
        return Number(valueWithoutSpace.replace(/,/g, '.'));
    }

    return Number(value);
}

/**
 * Hashes a given string using the SHA-256 algorithm.
 *
 * @param {string} string - The input string to be hashed.
 * @returns {Promise<string>} - A promise that resolves to the hexadecimal representation of the hash.
 */
export async function hash(string: string): Promise<string> {
    const utf8 = new TextEncoder().encode(string);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(bytes => bytes.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

/**
 * Normalizes a given string for Google Analytics by trimming whitespace
 * and converting it to lowercase.
 *
 * @param value - The string to be normalized.
 * @returns The normalized string.
 */
export function normalizeForGA(value: string): string {
    const trimmed = value.trim();
    const lowerCased = trimmed.toLowerCase();
    return lowerCased;
}

/**
 * Returns the result of normalizing and hashing an email address. For this use case, Google Ads
 * requires removal of any '.' characters preceding `gmail.com` or `googlemail.com`.
 *
 * @param emailAddress the email address to normalize and hash.
 */
export function normalizeEmailAddressForGA(emailAddress: string): string {
    let normalizedEmail = normalizeForGA(emailAddress);
    const emailParts = normalizedEmail.split('@');
    if (emailParts.length > 1 && /^(gmail|googlemail)\.com\s*$/.test(emailParts[1])) {
        // Removes any '.' characters from the portion of the email address before the domain if the
        // domain is gmail.com or googlemail.com.
        emailParts[0] = emailParts[0].replace(/\./g, '');
        normalizedEmail = `${emailParts[0]}@${emailParts[1]}`;
    }
    return normalizedEmail;
}

export const hashAndNormalizeUserData = async (userData: {
    email: string;
    phone: string;
}): Promise<Record<string, string>> => {
    const hashedEmail = await hash(normalizeEmailAddressForGA(userData.email));
    const hashedPhone = await hash(normalizeForGA(userData.phone));
    return {
        hashedEmail,
        hashedPhone,
    };
};
