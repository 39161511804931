import { useDevice } from '@frontend/bank-id';
import { Box, Modal, ModalContent } from '@lendoab/aphrodite';
import PropTypes from 'prop-types';
import React from 'react';

import SbabBankId from './SbabBankId';
import styles from './SbabBankIdModal.module.scss';

export default function SbabSigningModal(props) {
    const { applicant, open, offer, onSuccess, onClose } = props;
    const device = useDevice();

    if (!device) {
        // Puts a placeholder with the correct min-height so the layout doesnt flicker.
        return <Box className={styles.wrapper} />;
    }

    return (
        <Modal open={open} onClose={() => {}}>
            <ModalContent padding="none" borderRadius="medium" className={styles.bankIdModalContent}>
                <SbabBankId
                    offerId={offer.id}
                    ssn={applicant.ssn}
                    device={device}
                    onSuccess={onSuccess}
                    onBack={onClose}
                    onCancel={onClose}
                />
            </ModalContent>
        </Modal>
    );
}

SbabSigningModal.propTypes = {
    open: PropTypes.bool,
    offer: PropTypes.object.isRequired,
    applicant: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};
