import { createAsyncThunk } from '@reduxjs/toolkit';
import { OfferActions } from 'APP/actions/OfferActions';

import { ApplicantResponse } from './sbabBankIdSlice';

export interface PostResponseInterface {
    data: {
        ssn: string;
    };
}

export interface PostPayloadInterface {
    offerId: string;
    ssn: string;
}

export interface GetResponseInterface {
    data: [ApplicantResponse];
}

export interface GetPayloadInterface {
    offerId: string;
    ssn: string;
}

interface RejectValues {
    statusCode: number;
}

export const requestAction = createAsyncThunk<
    PostResponseInterface,
    PostPayloadInterface,
    {
        rejectValue: RejectValues;
    }
>('requestAction', async ({ offerId, ssn }, thunkAPI) => {
    try {
        return await OfferActions.initiateSbabBankIdSigning(offerId, { ssn });
    } catch (error: unknown) {
        return thunkAPI.rejectWithValue(error as RejectValues);
    }
});

export const pollAction = createAsyncThunk<
    GetResponseInterface,
    GetPayloadInterface,
    {
        rejectValue: RejectValues;
    }
>('pollAction', async ({ offerId }, thunkAPI) => {
    try {
        return await OfferActions.getSbabBankIdStatus(offerId);
    } catch (error: unknown) {
        return thunkAPI.rejectWithValue(error as RejectValues);
    }
});

export const cancelAction = createAsyncThunk<
    void,
    GetPayloadInterface,
    {
        rejectValue: RejectValues;
    }
>('cancelAction', async ({ offerId, ssn }, thunkAPI) => {
    try {
        return await OfferActions.cancelSbabBankIdSigning(offerId, { ssn });
    } catch (error: unknown) {
        return thunkAPI.rejectWithValue(error as RejectValues);
    }
});
