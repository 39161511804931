export const MontoSteps = {
    Idle: 'IDLE',
    ChooseIntegrations: 'CHOOSE_INTEGRATIONS',
    Consent: 'CONSENT',
    FillFields: 'FILL_FIELDS',
    RedirectStep: 'REDIRECT_STEP',
};

export const ComplementsFlow = {
    Idle: 'IDLE',
    FileUpload: 'FILE_UPLOAD',
    Monto: 'MONTO',
};

export const AuthType = {
    Redirect: 'REDIRECT',
    Credentials: 'CREDENTIALS',
};

export const MontoFieldFormat = {
    PLAIN: 'PLAIN',
    NUMERIC: 'NUMERIC',
};
