import { Box, ResponsiveProp } from '@lendoab/aphrodite';
import { ResponsiveSpace } from '@lendoab/aphrodite/dist/Box';
import { Property } from 'csstype';
import React, { ReactElement } from 'react';

interface DividerProps {
    className?: string;
    marginY?: ResponsiveSpace;
    marginTop?: ResponsiveSpace;
    marginBottom?: ResponsiveSpace;
    display?: ResponsiveProp<Property.Display>;
}

export function Divider(props: DividerProps): ReactElement {
    const { marginY, marginTop, marginBottom, display, ...rest } = props;

    return (
        <Box
            fluid
            backgroundColor="blue-soft"
            marginY={marginY}
            marginTop={marginTop}
            marginBottom={marginBottom}
            display={display}
            style={{ height: 1 }}
            {...rest}
        />
    );
}
