import { Box, PhoneIcon, Text } from '@lendoab/aphrodite';
import { useCallMeModal } from 'APP/components/Modals/CallMeModal';
import { CustomerSupportConstants } from 'APP/constants/CustomerSupportConstants';
import { OpenHoursHelpers } from 'APP/helpers/OpenHoursHelpers';
import React from 'react';

export default function MobileCallButton(props) {
    const { ...rest } = props;
    const { openModal } = useCallMeModal();

    if (OpenHoursHelpers.isOpen()) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                backgroundColor="transparent"
                component="a"
                href={`tel:${CustomerSupportConstants.rawPhoneNumber}`}
                alignItems="center"
                padding="none"
                style={{ border: 'none' }}
                marginLeft={['auto', 'xl']}
                {...rest}
            >
                <PhoneIcon color="light" />
                <Text paddingTop="base" component="span" size="xxs" color="light">
                    RING
                </Text>
            </Box>
        );
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            backgroundColor="transparent"
            component="button"
            onClick={openModal}
            cursor="pointer"
            alignItems="center"
            padding="none"
            style={{ border: 'none' }}
            marginLeft={['auto', 'xl']}
            marginRight={['medium', 'xl']}
        >
            <PhoneIcon color="light" />
            <Text paddingTop="base" component="span" size="xxs" color="light">
                RING
            </Text>
        </Box>
    );
}
