import { sorters as bizSorters } from 'APP/constants/bizSorters';
import { sorters as consumerSorters } from 'APP/constants/consumerSorters';

const { ApiStatus } = require('APP/constants/ApiStatusConstants');

export const applicationsSliceInitialState = {
    allApplications: [],
    private: [],
    business: [],
    mortgage: [],
    relevantApplication: {},
    bankyOffer: {
        hasBankyOffer: false,
        isBankyOfferInRelevantApplication: false,
        isOnlyBankyOffer: false,
    },
    apiStatus: ApiStatus.IDLE,
    AbTests: {
        controlCtaOffers: false,
    },
    activeSorter: {
        private: {
            id: consumerSorters.lowestEffectiveInterestRate.id,
            label: consumerSorters.lowestEffectiveInterestRate.label,
            trackingLabel: consumerSorters.lowestEffectiveInterestRate.trackingLabel,
        },
        business: {
            id: bizSorters.highestAmount.id,
            label: bizSorters.highestAmount.label,
            trackingLabel: bizSorters.highestAmount.trackingLabel,
        },
    },
};
