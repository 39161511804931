export const ApiStatus = {
    IDLE: 10,
    STARTED: 11,
    COMPLETED: 12,
    FAILED: 13,
};

export const MontoConnectionApiStatus = {
    IDLE: 'IDLE',
    ENABLED: 'ENABLED',
    PENDING: 'PENDING',
    DISABLED_MANUALLY: 'DISABLED_MANUALLY',
    DISABLED_BY_SYSTEM: 'DISABLED_BY_SYSTEM',
    FAILED: 'FAILED', // custom status not from monto
    AUTHED: 'AUTHED',
};
