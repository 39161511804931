import { configureStore, EnhancedStore, StoreEnhancer, ThunkDispatch, Tuple, UnknownAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch as reduxUseDispatch, useSelector as reduxUseSelector } from 'react-redux';

import reducer, { State } from './slice';

const store = (): EnhancedStore<
    State,
    UnknownAction,
    Tuple<
        [
            StoreEnhancer<{
                dispatch: ThunkDispatch<State, undefined, UnknownAction>;
            }>,
            StoreEnhancer,
        ]
    >
> =>
    configureStore({
        reducer,
        devTools: typeof process !== 'undefined' && process.env?.NODE_ENV === 'production' ? false : true,
    });

export type RootState = ReturnType<ReturnType<typeof store>['getState']>;

export const useSelector: TypedUseSelectorHook<RootState> = reduxUseSelector;

type Dispatch = ReturnType<typeof store>['dispatch'];

export const useDispatch: () => Dispatch = reduxUseDispatch;
export default store;
