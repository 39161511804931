import { AnyAction, combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import abTestsSlice from './abTests/abTestsSlice';
import acceptanceSlice from './acceptancePage/acceptanceSlice';
import applicationsSlice from './applications/applicationsSlice';
import mortgagesSlice from './applications/mortgagesSlice';
import applicationsActiveTypesSlice from './applicationsActiveTypes/applicationsActiveTypesSlice';
import listenerMiddleware from './business/middlewares/listenerMiddleware';
import businessOfferPageSlice from './business/offerPage/offerPageSlice';
import businessSlice from './business/slice';
import campaignSlice from './campaign/campaignSlice';
import conversationsSlice from './conversations/conversationsSlice';
import emailValidationSlice from './emailValidation/emailValidationSlice';
import monto from './monto/montoSlice';
import offerSlice from './offerPage/offerSlice';
import propertiesSlice from './properties/propertiesSlice';
import revisionSlice from './revision/revisionSlice';
import sbabBankIdSlice from './sbabBankId/sbabBankIdSlice';
import withdrawnSlice from './withdrawn/withdrawnSlice';

const showDevTools: boolean = process.env.NODE_ENV !== 'production';

const combinedReducer = combineReducers({
    emailValidationSlice,
    applicationsSlice,
    mortgagesSlice,
    businessSlice,
    applicationsActiveTypesSlice,
    conversationsSlice,
    propertiesSlice,
    offerSlice,
    withdrawnSlice,
    abTestsSlice,
    monto,
    acceptanceSlice,
    campaignSlice,
    businessOfferPageSlice,
    revisionSlice,
    sbabBankIdSlice,
});

type RootState = ReturnType<typeof combinedReducer>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const rootReducer: Reducer<RootState, AnyAction> = (state, action) => {
    if (action.type === 'generalSlice/resetReduxStates') {
        // eslint-disable-next-line no-param-reassign
        state = undefined;
    }
    return combinedReducer(state, action);
};

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().prepend(listenerMiddleware.middleware),
    devTools: showDevTools,
});

type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types

export default store;
