import { PROGRESS, TESTING_TYPE, VIEWS } from '@frontend/bank-id/enums';
import { DeviceInterface } from '@frontend/bank-id/hooks/useDevice';

export function getViewFromProgress(progress: PROGRESS, currentView: VIEWS | undefined): VIEWS {
    switch (progress) {
        case PROGRESS.USER_SIGN:
            return VIEWS.POLLING;

        case PROGRESS.COMPLETE:
        case PROGRESS.CREATED:
        case PROGRESS.OUTSTANDING_TRANSACTION:
            return currentView ?? VIEWS.ERROR;

        case PROGRESS.INVALID_PARAMETERS:
        case PROGRESS.USER_CANCEL:
        case PROGRESS.NO_CLIENT:
        case PROGRESS.NO_USER:
        default:
            return VIEWS.ERROR;
    }
}

export function createReturnUrl(device: DeviceInterface, testingType: TESTING_TYPE): string {
    // Here we create the URL that the BankID app should open when the authentication
    // is completed. On iOS all links open in Safari, even when another browser is
    // set as the default browser. So here we check if we're in another browser and
    // if so we replace the protocol with one provided by the specific browser so we
    // know it opens correctly. In some browsers we need to specify the full URL, in
    // some we can just say "Go back to Chrome".

    if (!device.isOnAppleDevice) {
        // BankID recommends to have empty string as returnURL unless its iOS.
        return '';
    }

    if (device.isChromeOnAppleDevice) {
        return encodeURIComponent('googlechrome://');
    }
    if (device.isFirefoxOnAppleDevice) {
        return encodeURIComponent('firefox://');
    }
    if (device.isOperaTouchOnAppleDevice) {
        return encodeURIComponent(
            `${window.location.href.replace('http', 'touch-http')}#initiated=true&testingType=${testingType}`
        );
    }

    return encodeURIComponent(`${window.location.href}#initiated=true&testingType=${testingType}`);
}

export function getBankIdAppUrl(token: string, returnUrl: string): string {
    return `bankid:///?autostarttoken=${token}&redirect=${returnUrl}`;
}
