const thousandsGroupRegex = /(\d)(?=(\d{3})+(?!\d))/g;

export function applyThousandSeparator(str, thousandSeparator = ' ') {
    const string = typeof str === 'string' ? str : str.toString();
    let index = string.search(/[1-9]/);
    index = index === -1 ? string.length : index;
    return (
        string.substring(0, index) +
        string.substring(index, string.length).replace(thousandsGroupRegex, '$1' + thousandSeparator)
    );
}

export function formatPercent(percentage, fixedAmount = 2) {
    return percentage.toFixed(fixedAmount).replace('.', ',');
}

export function formatAmortizeLength(amortizeLength) {
    const years = Math.floor(amortizeLength / 12);
    const months = amortizeLength % 12;

    if (years && months) {
        return `${years} år ${months} mån`;
    } else if (years && !months) {
        return `${years} år`;
    } else {
        return `${months} mån`;
    }
}
