import MortgageCommitmentReceipts from 'APP/config/mortgageCommitmentReceipts';
import MortgageGreenLoanInfo from 'APP/config/mortgageGreenLoanInfo';
import {
    APPLICATION_STATUS,
    OFFER_STATES,
    PARTNER_ADDRESS_INFO,
    PROPERTY_TYPE_NAMES,
    PURPOSE,
    REVISION_STATUS,
    USPS,
} from 'APP/constants/MortgageConstants';
import { isBefore } from 'APP/helpers/DateTimeHelpers';

import { OfferHelpers } from './OfferHelpers';

export default {
    isNewLoanApplication(application) {
        return application?.mortgage_purpose === PURPOSE.NEW;
    },

    isRefinanceLoanApplication(application) {
        return application?.mortgage_purpose === PURPOSE.REFINANCE;
    },

    isExpandLoanApplication(application) {
        return application?.mortgage_purpose === PURPOSE.EXPAND;
    },

    isLoanPromiseApplication(application) {
        return application?.mortgage_purpose === PURPOSE.LOAN_PROMISE;
    },

    getApplicationStatus(application, offers, withdrawnOffers) {
        if (!application) {
            return APPLICATION_STATUS.NO_APPLICATION;
        }

        if (
            application.status === REVISION_STATUS.DENIED ||
            application.status === REVISION_STATUS.CANCELLED ||
            application.status === REVISION_STATUS.CREATED ||
            application.status === REVISION_STATUS.CHANGE_CREATED
        ) {
            return APPLICATION_STATUS.WINDOW_CLOSED_NO_OFFERS;
        }

        const numberOfOffers = this.getNumberOfOffers(offers) + this.getNumberOfWithdrawnOffers(withdrawnOffers);

        const selectedOffer = this.getSelectedOffer(offers);

        if (
            isBefore(application.responses_expired_at) &&
            OfferHelpers.isAllOffersExpired(offers) &&
            selectedOffer?.state !== OFFER_STATES.ACCEPTED
        ) {
            return numberOfOffers > 0 ? APPLICATION_STATUS.OFFERS_EXPIRED : APPLICATION_STATUS.WINDOW_CLOSED_NO_OFFERS;
        } else if (application.status === REVISION_STATUS.USER_ACCEPTED_OFFER && numberOfOffers > 0 && selectedOffer) {
            return APPLICATION_STATUS.APPLICATION_ACCEPTED;
        } else {
            return numberOfOffers > 0
                ? APPLICATION_STATUS.WINDOW_OPEN_WITH_OFFERS
                : APPLICATION_STATUS.WINDOW_OPEN_NO_OFFERS;
        }
    },

    getPurpose(application) {
        return application?.mortgage_purpose;
    },

    getOffer(offers, id) {
        if (offers?.length > 0 && id) {
            return offers.find(offer => offer.id === id);
        }

        return null;
    },

    getNumberOfOffers(offers) {
        return offers ? offers.length : 0;
    },

    getNumberOfWithdrawnOffers(withdrawnOffers) {
        return withdrawnOffers ? withdrawnOffers.length : 0;
    },

    getPropertyType(property) {
        return property?.type ? property.type : '';
    },

    getPropertyTypeName(property) {
        return PROPERTY_TYPE_NAMES[this.getPropertyType(property)];
    },

    getProductName(product) {
        return product?.name;
    },

    getPartnerName(product) {
        return product?.partner_information.name;
    },

    getPartnerInternalName(product) {
        return product?.partner_information?.internal_name;
    },

    getInterest(offer) {
        if (offer?.interests.length) {
            return offer.interests.find(interest => Number(interest.binding_time_months) === 3);
        }
        return null;
    },

    getSelectedOffer(offers) {
        const selectedOfferStates = [
            OFFER_STATES.ACCEPTED,
            OFFER_STATES.AWAITING_COMPLEMENTS,
            OFFER_STATES.AWAITING_COMPLEMENTS_APPROVAL,
            OFFER_STATES.AWAITING_FINAL_ACCEPTANCE,
            OFFER_STATES.AWAITING_BILL_OF_DEBT,
            OFFER_STATES.BILL_OF_DEBT_SENT,
            OFFER_STATES.AWAITING_PAYMENT,
            OFFER_STATES.PAID,
            OFFER_STATES.DENIED_BY_CUSTOMER,
        ];

        if (offers?.length > 0) {
            return offers.find(offer => selectedOfferStates.includes(offer.state));
        }
        return null;
    },

    getFeeValue(type, fees) {
        if (type && fees?.length > 0) {
            const singleFee = fees.find(fee => fee.type === type);
            if (singleFee) {
                return `${singleFee.amount} ${singleFee.one_time ? 'kr' : 'kr/mån'}`;
            }
        }
        return '-';
    },

    getPartnerAddressInfo(partnerInternalName) {
        if (PARTNER_ADDRESS_INFO.hasOwnProperty(partnerInternalName)) {
            return PARTNER_ADDRESS_INFO[partnerInternalName];
        }

        return '';
    },

    isApplicantBankIdVerified(applicant) {
        if (applicant?.hasOwnProperty('bank_id_challenge_reference')) {
            return typeof applicant.bank_id_challenge_reference === 'string'
                ? !!applicant.bank_id_challenge_reference.trim()
                : false;
        }

        return false;
    },

    isMortgageTypeAvailable(availableApplicationsByType) {
        return availableApplicationsByType?.mortgage;
    },

    isPrivateOrBusinessTypeAvailable(availableApplicationsByType) {
        return availableApplicationsByType?.private || availableApplicationsByType?.business;
    },

    getRejectedComplement(rejectedComplements, prerequisiteId) {
        if (rejectedComplements?.length > 0 && prerequisiteId) {
            return rejectedComplements.find(complement => complement.prerequisite_id === prerequisiteId);
        }

        return null;
    },

    getDefaultOfferReceiptComponent(partner) {
        const partnerReceipts = MortgageCommitmentReceipts[partner];

        if (partnerReceipts?.defaultReceipt) {
            return partnerReceipts?.defaultReceipt.component;
        }

        return null;
    },

    getLoanPromiseOfferReceiptComponent(partner) {
        const partnerReceipts = MortgageCommitmentReceipts[partner];

        if (partnerReceipts?.loanPromiseReceipt) {
            return partnerReceipts?.loanPromiseReceipt.component;
        } else if (partnerReceipts?.defaultReceipt) {
            return partnerReceipts?.defaultReceipt.component;
        }

        return null;
    },

    getDocumentsApprovedConfirmation(partner) {
        const partnerReceipts = MortgageCommitmentReceipts[partner];

        if (partnerReceipts?.documentsApprovedConfirmation) {
            return partnerReceipts?.documentsApprovedConfirmation.component;
        }

        return null;
    },

    getGreenLoanInfo(partner) {
        const greenLoanInfo = MortgageGreenLoanInfo[partner]?.greenLoanInfo;

        if (partner && greenLoanInfo) {
            return greenLoanInfo.component;
        }

        return null;
    },

    getTotalLoanAmount(data) {
        if (data?.existing_loan_amount && data.mortgage_purpose === PURPOSE.EXPAND) {
            return data.loan_amount + data.existing_loan_amount;
        } else {
            return data.loan_amount;
        }
    },

    getEnabledPartnerUsps(product) {
        return product?.product_settings?.unique_selling_points?.filter(usp => usp.enabled);
    },

    greenLoanEnabledUsp(product) {
        const usps = this.getEnabledPartnerUsps(product);

        return usps?.length > 0 && usps.find(usp => usp.name === USPS.GREEN_LOAN);
    },

    getSbabLoanPromisePrivateLoanAmount(additional_information) {
        return additional_information?.sbab?.loan_promise_with_unsecured_loan?.unsecured_loan_amount || 0;
    },

    getSbabLoanPromiseMaxApprovedAmount(offer) {
        const maxApprovedAmountWithUnsecuredLoan =
            offer?.additional_information?.sbab?.loan_promise_with_unsecured_loan?.max_approved_amount;
        return maxApprovedAmountWithUnsecuredLoan > 0 ? maxApprovedAmountWithUnsecuredLoan : offer.amount;
    },
};
