/**
 * Reads from the cookie.
 * @param {string} cookieName
 * @returns {string | undefined}
 */
export function getCookie(cookieName) {
    const name = cookieName + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return undefined;
}

/**
 * Sets a cookie
 * @param {string} cookieName
 * @param {string} cookieValue
 * @param {number} expirationDays
 * @param {string} [domain] - Optional
 */
export function setCookie(cookieName, cookieValue, expirationDays, domain) {
    const d = new Date();
    d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    let cookieString = `${cookieName}=${cookieValue};${expires}`;
    if (domain) {
        cookieString = `${cookieString};domain=${domain}`;
    }
    document.cookie = `${cookieString};path=/`;
}
