export const Routes = {
    getRoot(): string {
        return '/';
    },

    getApplicationsRoute(): string {
        return '/applications';
    },

    getApplicationRoute(applicationId: string): string {
        return `/applications/${applicationId}`;
    },

    getMortgageRoute(): string {
        return `/applications/mortgage`;
    },

    getMortgageApplicationRoute(applicationId: string): string {
        return `/applications/mortgage/${applicationId}`;
    },

    getMortgageOfferRoute(applicationId: string, offerId: string): string {
        return `/applications/mortgage/${applicationId}/offers/${offerId}`;
    },

    getMortgageCommitmentRoute(applicationId: string, offerId: string): string {
        return `/applications/mortgage/${applicationId}/offers/${offerId}/commitment/`;
    },

    getMortgageComplementsRoute(applicationId: string, offerId: string): string {
        return `/applications/mortgage/${applicationId}/offers/${offerId}/complements/`;
    },

    getMortgageDocumentsRoute(applicationId: string, offerId: string): string {
        return `/applications/mortgage/${applicationId}/offers/${offerId}/documents/`;
    },

    getMortgageProductsFilteredOutRoute(applicationId: string): string {
        return `/applications/mortgage/${applicationId}/productsFilteredOut`;
    },

    getAcceptanceRoute(applicationId: string): string {
        return `/applications/${applicationId}/acceptance`;
    },

    getRevisionRoute(applicationId: string): string {
        return `/applications/${applicationId}/revision`;
    },

    getPropertyQuestionsRoute(applicationId: string): string {
        return `/applications/${applicationId}/properties`;
    },

    getOfferRoute(applicationId: string, offerId: string): string {
        return `/applications/${applicationId}/offers/${offerId}`;
    },
};
