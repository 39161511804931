import { Box, Text } from '@lendoab/aphrodite';
import React from 'react';

import { CircleLoader } from './CircleLoader';

export function LoginLoader() {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            style={{ height: '100%', flexGrow: 1 }}
        >
            <CircleLoader />
            <Text marginTop="2xl" align="center" size="medium" weight="bold">
                Loggar in
            </Text>
        </Box>
    );
}
