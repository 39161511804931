import {
    Bluestep,
    Hypoteket,
    Landshypotek,
    Marginalen,
    Nordax,
    Sbab,
    Skandia,
    Svea,
} from 'APP/components/Applications/Mortgage/MortgageCommitmentReceipts/PartnerSpecificCommitmentReceipts';
import { PARTNER_INTERNAL_NAME } from 'APP/constants/MortgageConstants';
import { TEMP_MORTGAGE_SBAB_LOAN_PROMISE_FLOW } from 'APP/featureFlags';

export default {
    [PARTNER_INTERNAL_NAME.HYPOTEKET]: {
        defaultReceipt: {
            component: Hypoteket.HypoteketOfferReceipt,
        },
        // loanPromiseReceipt: {
        //     component: Hypoteket.HypoteketLoanPromise,
        // },
        documentsApprovedConfirmation: {
            component: Hypoteket.HypoteketDocumentsApprovedConfirmation,
        },
    },
    [PARTNER_INTERNAL_NAME.BLUESTEP]: {
        defaultReceipt: {
            component: Bluestep.BluestepOfferReceipt,
        },
        loanPromiseReceipt: {
            component: Bluestep.BluestepLoanPromiseOfferReceipt,
        },
        documentsApprovedConfirmation: {
            component: Bluestep.BluestepDocumentsApprovedConfirmation,
        },
    },
    [PARTNER_INTERNAL_NAME.LANDSHYPOTEK]: {
        defaultReceipt: {
            component: Landshypotek.LandshypotekOfferReceipt,
        },
        loanPromiseReceipt: {
            component: Landshypotek.LandshypotekLoanPromiseOfferReceipt,
        },
        documentsApprovedConfirmation: {
            component: Landshypotek.LandshypotekDocumentsApprovedConfirmation,
        },
    },
    [PARTNER_INTERNAL_NAME.MARGINALEN]: {
        defaultReceipt: {
            component: Marginalen.MarginalenOfferReceipt,
        },
        loanPromiseReceipt: {
            component: Marginalen.MarginalenLoanPromiseOfferReceipt,
        },
        documentsApprovedConfirmation: {
            component: Marginalen.MarginalenDocumentsApprovedConfirmation,
        },
    },
    [PARTNER_INTERNAL_NAME.NORDAX]: {
        defaultReceipt: {
            component: Nordax.NordaxOfferReceipt,
        },
        loanPromiseReceipt: {
            component: Nordax.NordaxLoanPromiseOfferReceipt,
        },
        documentsApprovedConfirmation: {
            component: Nordax.NordaxDocumentsApprovedConfirmation,
        },
    },
    [PARTNER_INTERNAL_NAME.SBAB]: {
        defaultReceipt: {
            component: Sbab.SbabOfferReceipt,
        },
        loanPromiseReceipt: {
            component: TEMP_MORTGAGE_SBAB_LOAN_PROMISE_FLOW
                ? Sbab.TempSbabLoanPromiseOfferReceipt
                : Sbab.SbabLoanPromiseOfferReceipt,
        },
        documentsApprovedConfirmation: {
            component: Sbab.SbabDocumentsApprovedConfirmation,
        },
    },
    [PARTNER_INTERNAL_NAME.SKANDIA]: {
        defaultReceipt: {
            component: Skandia.SkandiaOfferReceipt,
        },
        // loanPromiseReceipt: {
        //     component: Skandia.SkandiaLoanPromise,
        // },
        documentsApprovedConfirmation: {
            component: Skandia.SkandiaDocumentsApprovedConfirmation,
        },
    },
    [PARTNER_INTERNAL_NAME.SVEA]: {
        defaultReceipt: {
            component: Svea.SveaOfferReceipt,
        },
        loanPromiseReceipt: {
            component: Svea.SveaLoanPromiseOfferReceipt,
        },
        documentsApprovedConfirmation: {
            component: Svea.SveaDocumentsApprovedConfirmation,
        },
    },
};
