import { createSlice } from '@reduxjs/toolkit';

import { businessFormInitialState } from './initialState';

const initialState = businessFormInitialState;

export const properties = createSlice({
    name: 'properties',
    initialState,

    reducers: {
        updateProperty: (state, action) => {
            state.property = action.payload;
        },
    },
});

export const { updateProperty } = properties.actions;
export default properties.reducer;
