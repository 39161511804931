import { Card, Stack, Typography } from '@lendoab/aphrodite';
import NextSteps from 'APP/components/Acceptances/NextSteps';
import { Divider } from 'APP/components/Divider';
import ProductLogo from 'APP/components/ProductLogo/ProductLogo';
import PropTypes from 'prop-types';
import React from 'react';

export default function BluestepOfferReceipt(props) {
    const { offer } = props;

    return (
        <Card borderRadius="small" paddingY={['xl', '2xl']} paddingX="none">
            <Stack fluid space="xs" paddingX={['xl', '2xl']}>
                {offer?.product && <ProductLogo product={offer.product} justifyContent="center" />}
                <Typography.Title
                    color="gray-10"
                    level={3}
                    fontWeight="bold"
                    align="center"
                    style={{ marginBottom: 0 }}
                >
                    Du har valt ett erbjudande!
                </Typography.Title>
            </Stack>

            <Divider marginTop={['medium', '2xl']} marginBottom={['medium', '2xl']} />

            <Stack fluid space={['medium', '2xl']} paddingX={['xl', '2xl']}>
                <NextSteps
                    steps={[
                        'En handläggare från Lendo ringer dig för att säkerställa dina uppgifter.',
                        'Sedan ringer Bluestep Bank dig så snart som möjligt.',
                        'Du kommer att få all information om vad som händer nu och vilka kompletteringar som behövs för att färdigställa din ansökan.',
                    ]}
                />

                <Typography.Body>
                    Har du frågor angående din ansökan? Kontakta Lendos kundtjänst på
                    <Typography.Link style={{ whiteSpace: 'nowrap' }} fontWeight="medium" href="tel:0771 13 13 10">
                        0771 13 13 10
                    </Typography.Link>
                    .
                </Typography.Body>
            </Stack>
        </Card>
    );
}

BluestepOfferReceipt.propTypes = {
    offer: PropTypes.object.isRequired,
};
