export function createPositionFn(containerRect: { left: number; width: number }) {
    return function positionFn(triggerRect: { top: number }, tooltipRect: { height: number }) {
        return {
            left: `${containerRect.left}px`,
            width: `${containerRect.width}px`,
            top: `${triggerRect.top - tooltipRect.height - 12 + window.pageYOffset}px`,
        };
    };
}

export function createPositionFnBanky(containerRect: { left: number; width: number }) {
    return function positionFn(triggerRect: { top: number }, tooltipRect: { height: number }) {
        return {
            left: `${containerRect.left - 228}px`,
            width: `480px`,
            top: `${triggerRect.top - tooltipRect.height - 12 + window.pageYOffset}px`,
        };
    };
}
