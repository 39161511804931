import { OfferHelpers } from 'APP/helpers/OfferHelpers';

export const sorters = {
    highestAmount: {
        id: 'HIGHEST_AMOUNT_BUSINESS',
        label: 'Högsta lånesumma',
        trackingLabel: 'highest loan amount',
        sorter: OfferHelpers.sortByBusinessHighestAmount,
    },
    lowestTotalCost: {
        id: 'LOWEST_TOTAL_COST_BUSINESS',
        label: 'Lägsta totalkostnad',
        trackingLabel: 'lowest total cost',
        sorter: OfferHelpers.sortByLowestBusinessTotalCost,
    },
    lowestMonthlyCost: {
        id: 'LOWEST_MONTHLY_COST_BUSINESS',
        label: 'Lägsta månadskostnad',
        trackingLabel: 'lowest monthly cost',
        sorter: OfferHelpers.sortByLowestBusinessMonthlyCost,
    },
};
