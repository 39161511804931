import { BankIdColorIcon, Box, Button, Card, Stack, Typography } from '@lendoab/aphrodite';
import { isNullOrUndefined } from '@lendoab/lendose-shared';
import NextSteps from 'APP/components/Acceptances/NextSteps';
import { Divider } from 'APP/components/Divider';
import ProductLogo from 'APP/components/ProductLogo/ProductLogo';
import { ApplicantConstants } from 'APP/constants/ApplicantConstants';
import { OfferHelpers } from 'APP/helpers/OfferHelpers';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import SbabSigningModal from './BankId/SbabSigningModal';

export default function SbabOfferReceipt(props) {
    const { offer, applicant } = props;
    const isOfferSigned = OfferHelpers.isOfferSignedByApplicant(offer, applicant.ssn);

    if (isNullOrUndefined(offer.external_id)) {
        return <Idle offer={offer} />;
    } else if (isOfferSigned) {
        return <Signed offer={offer} />;
    } else {
        return <WaitingToBeSigned offer={offer} applicant={applicant} />;
    }
}

SbabOfferReceipt.propTypes = {
    offer: PropTypes.object.isRequired,
    applicant: PropTypes.object.isRequired,
};

function Idle(props) {
    const { offer } = props;

    return (
        <Card borderRadius="small" paddingY={['xl', '2xl']} paddingX="none">
            <Stack fluid space="xs" paddingX={['xl', '2xl']}>
                {offer?.product && <ProductLogo product={offer.product} justifyContent="center" />}
                <Typography.Title color="gray-10" level={3} fontWeight="bold" align="center">
                    Du har valt ett erbjudande!
                </Typography.Title>
            </Stack>

            <Divider marginTop={['medium', '2xl']} marginBottom={['medium', '2xl']} />

            <Stack fluid space={['medium', '2xl']} paddingX={['xl', '2xl']}>
                <Typography.Body>
                    Just nu behöver du inte göra något. Nästa steg är att signera erbjudandet med BankID. Vi kommer att
                    kontakta dig via e-post eller telefon när det är dags.
                </Typography.Body>

                <Typography.Body>
                    Har du frågor angående din ansökan? Kontakta Lendos kundtjänst på
                    <Typography.Link marginLeft="base" style={{ whiteSpace: 'nowrap' }} href="tel:0771 13 13 10">
                        0771 - 13 13 10
                    </Typography.Link>
                    .
                </Typography.Body>
            </Stack>
        </Card>
    );
}

Idle.propTypes = {
    offer: PropTypes.object.isRequired,
};

function Signed(props) {
    const { offer } = props;

    return (
        <Card borderRadius="small" paddingY={['xl', '2xl']} paddingX="none">
            <Stack fluid space="xs" paddingX={['xl', '2xl']}>
                {offer?.product && <ProductLogo product={offer.product} justifyContent="center" />}
                <Typography.Title
                    color="gray-10"
                    level={3}
                    fontWeight="bold"
                    align="center"
                    style={{ marginBottom: 0 }}
                >
                    Du har valt ett erbjudande!
                </Typography.Title>
            </Stack>

            <Divider marginTop={['medium', '2xl']} marginBottom={['medium', '2xl']} />

            <Stack fluid space={['medium', '2xl']} paddingX={['xl', '2xl']}>
                <NextSteps
                    steps={[
                        'En handläggare från Lendo ringer dig för att säkerställa dina uppgifter.',
                        'Din handläggare kommer att hålla dig uppdaterad om vad som händer härnäst.',
                        'Om din ansökan blir godkänd kommer du behöva logga in på SBAB för att skriva ut och signera låneavtalet.',
                    ]}
                />

                <Typography.Body>
                    Har du frågor angående din ansökan? Kontakta Lendos kundtjänst på
                    <Typography.Link marginLeft="base" style={{ whiteSpace: 'nowrap' }} href="tel:0771 13 13 10">
                        0771 - 13 13 10
                    </Typography.Link>
                    .
                </Typography.Body>
            </Stack>
        </Card>
    );
}

Signed.propTypes = {
    offer: PropTypes.object.isRequired,
};

function WaitingToBeSigned(props) {
    const { offer, applicant } = props;
    const { reload } = useRouter();
    const [showSignWithBankIdModal, setShowSignWithBankIdModal] = useState(false);

    return (
        <>
            <Card borderRadius="small" paddingY={['xl', '2xl']} paddingX="none">
                <Stack fluid space="xs" paddingX={['xl', '2xl']}>
                    {offer?.product && <ProductLogo product={offer.product} justifyContent="center" />}
                    <Typography.Title
                        color="gray-10"
                        level={3}
                        fontWeight="bold"
                        align="center"
                        style={{ marginBottom: 0 }}
                    >
                        Du har valt ett erbjudande!
                    </Typography.Title>
                </Stack>

                <Divider marginTop={['medium', '2xl']} marginBottom={['medium', '2xl']} />

                <Stack fluid space={['medium', '2xl']} paddingX={['xl', '2xl']}>
                    <NextSteps
                        steps={[
                            ...[
                                applicant.type === ApplicantConstants.TYPES.CO
                                    ? 'Huvudsökande måste logga in på Mina sidor och signera erbjudandet för att fortsätta processen.'
                                    : 'Signera erbjudandet hos SBAB nedan för att fortsätta processen.',
                                'En handläggare från Lendo ringer dig för att säkerställa dina uppgifter.',
                                'Din handläggare kommer att hålla dig uppdaterad om vad som händer härnäst.',
                                'Om din ansökan blir godkänd kommer du behöva logga in på SBAB för att skriva ut och signera låneavtalet.',
                            ],
                        ]}
                    />

                    <Typography.Body>
                        Har du frågor angående din ansökan? Kontakta Lendos kundtjänst på
                        <Typography.Link marginLeft="base" style={{ whiteSpace: 'nowrap' }} href="tel:0771 13 13 10">
                            0771 - 13 13 10
                        </Typography.Link>
                        .
                    </Typography.Body>
                </Stack>

                {applicant.type !== ApplicantConstants.TYPES.CO ? (
                    <>
                        <Divider marginTop={['medium', '2xl']} marginBottom={['medium', '2xl']} />
                        <Box paddingX={['medium', '2xl']}>
                            <Button type="button" fluid primary onClick={() => setShowSignWithBankIdModal(true)}>
                                Signera erbjudandet hos SBAB
                                <BankIdColorIcon size="medium" />
                            </Button>
                        </Box>
                    </>
                ) : null}
            </Card>

            {showSignWithBankIdModal && (
                <SbabSigningModal
                    open={showSignWithBankIdModal}
                    applicant={applicant}
                    offer={offer}
                    onClose={() => {
                        setShowSignWithBankIdModal(false);
                    }}
                    onSuccess={() => {
                        setShowSignWithBankIdModal(false);
                        reload();
                    }}
                />
            )}
        </>
    );
}

WaitingToBeSigned.propTypes = {
    offer: PropTypes.object.isRequired,
    applicant: PropTypes.object.isRequired,
};
