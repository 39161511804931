import { Qr } from '@frontend/bank-id/components/Qr/Qr';
import { ArrowLeftIcon, BankIdColorIcon, Box, Button, Stack, Typography } from '@lendoab/aphrodite';
import React, { ReactElement } from 'react';

import styles from './QrCodePrompt.module.scss';

export interface QrCodePollingProps {
    qrData?: string | null;
    onBack: () => void;
    openOnThisDevice?: () => void;
    isMobileOrTablet: boolean;
}

export default function QrCodePrompt(props: QrCodePollingProps): ReactElement {
    const { qrData, openOnThisDevice, onBack, isMobileOrTablet } = props;

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            paddingX={['medium', 'medium', '3xl']}
            paddingTop={['medium', 'medium', '3xl']}
            paddingBottom={['xl', 'xl', '3xl']}
            style={{ minHeight: '100%' }}
        >
            <Box>
                {isMobileOrTablet && (
                    <Button
                        borderless
                        startIcon={<ArrowLeftIcon />}
                        size="small"
                        onClick={onBack}
                        alignSelf="flex-start"
                        marginBottom="medium"
                    >
                        Tillbaka
                    </Button>
                )}
                <Stack space={['medium', 'medium', 'large']}>
                    <Box display="flex" justifyContent="center">
                        <BankIdColorIcon size={['3xl', '3xl', 'xl']} />
                    </Box>

                    <Stack space={['xs', 'xs', 'small']}>
                        <Typography.Title level={3} align="center" weight="bold" color="gray-10">
                            Skanna QR-koden
                        </Typography.Title>

                        <Stack
                            space={['xs', 'xs', 'small']}
                            alignItems="center"
                            component="ol"
                            className={styles.listWrapper}
                        >
                            <Typography.Title level={5} color="gray-30" fontWeight="book">
                                Starta BankID appen i din mobil
                            </Typography.Title>
                            <Typography.Title level={5} color="gray-30" fontWeight="book">
                                Tryck på knappen skanna QR-kod
                            </Typography.Title>
                            <Typography.Title level={5} color="gray-30" fontWeight="book">
                                Rikta kameran mot den här sidan
                            </Typography.Title>
                        </Stack>
                    </Stack>

                    <Box display="flex" justifyContent="center">
                        <Qr value={qrData} />
                    </Box>

                    {isMobileOrTablet && (
                        <Stack space="xs" alignItems="center" paddingTop="xl">
                            <Typography.Title level={5} align="center">
                                Har du BankID på den här enheten?
                            </Typography.Title>

                            <Button borderless onClick={openOnThisDevice}>
                                Öppna BankID på den här enheten
                            </Button>
                        </Stack>
                    )}
                </Stack>
            </Box>
            {!isMobileOrTablet && (
                <Button fluid onClick={onBack} marginTop={['medium', 'medium', 'large']}>
                    <ArrowLeftIcon />
                    <Typography.Body color="gray-10" weight="bold" component="span">
                        Tillbaka
                    </Typography.Body>
                </Button>
            )}
        </Box>
    );
}
