export const ApplicantConstants = {
    TYPES: {
        MAIN: 'main',
        CO: 'co',
    },
    CIVIL_STATUS: { SINGLE: 'single', LIVING_TOGETHER: 'live_together', MARRIED: 'married', DIVORCED: 'divorced' },
    RELATIONSHIP_STATUS: {
        HUSBAND_OR_WIFE: 'married',
        PARENT: 'parent',
        CHILD: 'child',
        OTHER_RELATIONSHIP: 'other',
    },
    OCCUPATION: {
        MORTGAGE_EMPLOYMENT_TYPES: {
            PERMANENT_EMPLOYMENT: 'permanent_employment',
            SELF_EMPLOYMENT: 'self_employment',
            TEMPORARY_EMPLOYMENT: 'temporary_employment',
            SUBSTITUTE_EMPLOYMENT: 'substitute_employment',
            PROBATIONARY_EMPLOYMENT: 'probationary_employment',
            HOURLY_EMPLOYMENT: 'hourly_employment',
            RETIREMENT: 'retirement',
            EARLY_RETIREMENT: 'early_retirement',
            DISABILITY_RETIREMENT: 'disability_retirement',
            STUDENT: 'student',
            NO_EMPLOYMENT: 'no_employment',
        },
    },
};
