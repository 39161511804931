import { createUUID } from '@frontend/shared/utils';
import { createInstance, enums, OptimizelyDecideOption, OptimizelyProvider, setLogger } from '@optimizely/react-sdk';
import { getCookie, setCookie } from 'APP/helpers/CookieHelpers';
import {
    getBrowserName,
    getDeviceType,
    GetHasMultipleActiveOffers,
    getHostName,
    getOsName,
    GetProductName,
    isSafetyInsuranceShowedOnOfferPage,
} from 'APP/helpers/OptimizelyHelpers';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { IRootState } from './interfaces/general.interfaces';

setLogger(null);

interface DecisionInfo {
    userId: string;
    enabled: boolean;
    flagKey: string;
    variationKey: string;
    type: string;
    decisionEventDispatched: boolean;
}

interface UserAttributes {
    Device: string;
    Browser: string | undefined;
    OperatingSystem: string | undefined;
    NewVisitor: boolean;
    ReturningVisitor: boolean;
    HostName: string;
    Product: string;
    HasMultipleActiveOffers: string; // if relevant application has multiple active offers
    SafetyInsuranceShowedOnOfferPage: string;
}

interface User {
    id: string;
    attributes: UserAttributes;
}

function onDecision({
    type,
    userId,
    decisionInfo,
}: {
    type: string;
    userId: string;
    decisionInfo: DecisionInfo;
}): void {
    // Add a DECISION Notification Listener for type FLAG
    if (type === 'flag') {
        if (!Array.isArray(window.dataLayer)) window.dataLayer = [];

        window.dataLayer.push({
            event: 'optimizelyDecision',
            optimizelyInfo: {
                userId: userId,
                enabled: decisionInfo['enabled'],
                flagKey: decisionInfo['flagKey'],
                variationKey: decisionInfo['variationKey'],
                type: decisionInfo['decisionEventDispatched'] === true ? 'Experiment' : 'TargetDelivery/Flag',
            },
        });
    }
}

function OptimizelyWrapper({ children }: { children: React.ReactNode }): React.ReactNode {
    let optimizelyCookie;
    let consentCookie: undefined | string;
    let userId = '';
    let isNewVisitor = false;
    const relevantApplication = useSelector(
        (reduxState: IRootState) => reduxState.applicationsSlice?.relevantApplication
    );
    const offer = useSelector((reduxState: IRootState) => reduxState.offerSlice.activeOffer);

    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        consentCookie = getCookie('consent');
        optimizelyCookie = getCookie('optimizelyEndUserIdInbox') || undefined;
        isNewVisitor = !optimizelyCookie;
        userId = optimizelyCookie || createUUID();
    }

    // initial optimizely
    // We disable sending data if we don't have concent on reload of the page

    const optimizely = createInstance({
        sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_ID,
        defaultDecideOptions:
            consentCookie && JSON.parse(consentCookie).product === false
                ? [OptimizelyDecideOption.DISABLE_DECISION_EVENT]
                : [],
    });

    useEffect(() => {
        if (!consentCookie || (JSON.parse(consentCookie).product === true && userId)) {
            setCookie('optimizelyEndUserIdInbox', userId, 30);
        }
    }, [consentCookie, userId]);

    useEffect(() => {
        const notificationId = optimizely.notificationCenter.addNotificationListener(
            enums.NOTIFICATION_TYPES.DECISION,
            onDecision
        );
        return () => {
            optimizely.notificationCenter.removeNotificationListener(notificationId);
        };
    }, [optimizely.notificationCenter]);

    const attributes: UserAttributes = {
        Device: getDeviceType(), // mobile tablet desktop
        Browser: getBrowserName(),
        OperatingSystem: getOsName(),
        NewVisitor: isNewVisitor,
        ReturningVisitor: !isNewVisitor,
        HostName: getHostName(), // Prod Stage or localhost
        Product: GetProductName(relevantApplication), // Consumer loan or Business or mortgage
        HasMultipleActiveOffers: GetHasMultipleActiveOffers(relevantApplication), // if relevant application has multiple active offers
        SafetyInsuranceShowedOnOfferPage: isSafetyInsuranceShowedOnOfferPage(relevantApplication, offer), // check insurance type audience
    };

    return (
        <OptimizelyProvider
            optimizely={optimizely}
            user={
                {
                    id: userId,
                    attributes: attributes,
                } as User
            }
        >
            {children}
        </OptimizelyProvider>
    );
}

OptimizelyWrapper.propTypes = {
    children: PropTypes.any,
};

export default OptimizelyWrapper;
