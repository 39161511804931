import { Transport } from 'APP/actions/transport';

export const RevisionActions = {
    create(applicationId: string, data: unknown) {
        return Transport.put(`applications/private/${applicationId}/customer-revision`, { data });
    },
    /**
     * @param {string} applicationId
     * @param {number} revisionId
     */
    async get(applicationId: string, revisionId: number) {
        return Transport.get(
            `smart-services/consumer/application/${applicationId}/revision/${revisionId}/revision-recommendation`
        );
    },
    /**
     * @param {string} applicationId
     * @param {string} userId
     * @param {string} eventName
     * @param {number} revision
     */
    async post(applicationId: string, userId: string, eventName: string, revision: number) {
        const data = {
            application_id: applicationId,
            user_id: userId,
            event_name: eventName,
            revision,
        };
        return Transport.post(`events`, { data });
    },
};
