import { OfferHelpers } from 'APP/helpers/OfferHelpers';

export const sorters = {
    lowestEffectiveInterestRate: {
        id: 'LOWEST_EFFECTIVE_INTEREST_RATE_CONSUMER',
        label: 'Lägsta effektiva ränta',
        trackingLabel: 'lowest effective rate',
        sorter: OfferHelpers.sortByLowestEffectiveInterestRate,
    },
    lowestMonthlyCost: {
        id: 'LOWEST_MONTHLY_COST_CONSUMER',
        label: 'Lägsta månadskostnad',
        trackingLabel: 'lowest monthly cost',
        sorter: OfferHelpers.sortByLowestMonthlyCostAverage,
    },
    highestAmount: {
        id: 'HIGHEST_AMOUNT_CONSUMER',
        label: 'Högsta lånesumma',
        trackingLabel: 'highest loan amount',
        sorter: OfferHelpers.sortByPrivateHighestAmount,
    },
};

export const sortersIncludeSmartSorting = {
    lowestEffectiveInterestRate: {
        id: 'LOWEST_EFFECTIVE_INTEREST_RATE_CONSUMER',
        label: 'Lägsta effektiva ränta',
        trackingLabel: 'lowest effective rate',
        sorter: OfferHelpers.sortByLowestEffectiveInterestRate,
    },
    smartSorting: {
        id: 'HIGHEST_CHANCE_PAYOUT_CONSUMER',
        label: 'Ökad sannolikhet till utbetalt lån',
        trackingLabel: 'highest chance payout',
        sorter: OfferHelpers.sortBySmartSorting,
    },
    lowestMonthlyCost: {
        id: 'LOWEST_MONTHLY_COST_CONSUMER',
        label: 'Lägsta månadskostnad',
        trackingLabel: 'lowest monthly cost',
        sorter: OfferHelpers.sortByLowestMonthlyCostAverage,
    },
};
