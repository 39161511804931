import React from 'react';

import styles from './CircleLoader.module.scss';

export function CircleLoader() {
    return (
        <svg
            viewBox="0 0 96 96"
            style={{
                display: 'block',
                width: '96px',
                margin: '0 auto',
            }}
        >
            <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="#059142" />
                    <stop offset="100%" stopColor="#29C466" />
                </linearGradient>
            </defs>
            <g className={styles.circleContainer}>
                <circle
                    className={styles.circle}
                    stroke="url(#gradient)"
                    strokeWidth="8"
                    fill="none"
                    transform="rotate(90 48 48)"
                    cx="48"
                    cy="48"
                    r="44"
                ></circle>
            </g>
        </svg>
    );
}
