/* eslint-disable react-hooks/exhaustive-deps */
import { BankIdProps } from '@frontend/bank-id/BankId/BankId';
import DevicePrompt from '@frontend/bank-id/components/DevicePrompt/DevicePrompt';
import QrCodePrompt from '@frontend/bank-id/components/QrCodePrompt/QrCodePrompt';
import SsnPrompt from '@frontend/bank-id/components/SsnPrompt/SsnPrompt';
import ErrorView from '@frontend/bank-id/components/Status/Error/Error';
import Pending from '@frontend/bank-id/components/Status/Pending/Pending';
import { POLL_START_COUNT, POLL_TIMEOUT_TIME } from '@frontend/bank-id/constants';
import TEXTS from '@frontend/bank-id/constants/texts';
import { ACTIONS, PROGRESS, TESTING_TYPE, TRACKING_METHODS, VIEWS } from '@frontend/bank-id/enums';
import { EVENTS } from '@frontend/bank-id/enums/events';
import {
    cancelAction,
    GetResponseInterface,
    pollAction,
    PostResponseInterface,
    requestAction,
} from '@frontend/bank-id/feature/actions';
import { resetState, setView } from '@frontend/bank-id/feature/slice';
import { useDispatch, useSelector } from '@frontend/bank-id/feature/store';
import { createReturnUrl, getBankIdAppUrl } from '@frontend/bank-id/helpers';
import { DeviceInterface } from '@frontend/bank-id/hooks/useDevice';
import { ErrorResponseData } from '@frontend/shared';
import React, { ReactElement, useEffect, useRef } from 'react';

export interface FlowProps extends BankIdProps {
    devMode?: boolean;
    device: DeviceInterface;
}

export default function Flow(props: FlowProps): ReactElement | null {
    const { device, endpoint, onVerified, onError, onEvent, onCancel, onRetry, onBack, action, devMode } = props;
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>();
    const { view, qrData, reference, pollCount, progress, details } = useSelector(state => state);
    const dispatch = useDispatch();

    const trackingMethod = useRef<TRACKING_METHODS | undefined>();
    const postReference = useRef<string | null | undefined>(); // used only when unmounting the component (cleaning up function inside useEffect)
    postReference.current = reference;

    const initialView = device.isMobileOrTablet ? VIEWS.DEVICE_PROMPT : VIEWS.QR_CODE_PROMPT;

    useEffect(() => clearAndCancel, []);

    useEffect(() => {
        if (view) {
            onEvent?.({
                event: EVENTS.VIEW_CHANGED,
                payload: { view, trackingMethod: trackingMethod.current },
            });
        }
    }, [view]);

    useEffect(() => {
        if (progress === PROGRESS.COMPLETE) {
            onEvent?.({ event: EVENTS.VERIFIED, payload: { trackingMethod: trackingMethod.current! } });
        }
    }, [progress]);

    useEffect(() => {
        if (devMode) {
            clearTimeout(timeoutRef.current);
            dispatch(setView(VIEWS.SSN_PROMPT));
        } else {
            setInitialView();
        }
    }, [dispatch, devMode]);

    useEffect(() => {
        if (pollCount > POLL_START_COUNT && reference) {
            triggerPoll(reference);
        }
    }, [pollCount, reference]);

    async function requestInitialData(): Promise<PostResponseInterface | undefined> {
        try {
            onEvent?.({ event: EVENTS.INITIATED, payload: { trackingMethod: trackingMethod.current! } });
            const sameDevice = trackingMethod.current === TRACKING_METHODS.SSN;

            return await dispatch(requestAction({ endpoint, sameDevice })).unwrap();
        } catch (error) {
            handleOnError(error as Error);
            return undefined;
        }
    }

    async function startPolling(ref: string): Promise<GetResponseInterface | undefined> {
        try {
            return await dispatch(pollAction({ endpoint, reference: ref })).unwrap();
        } catch (error) {
            handleOnError(error as Error);
            return undefined;
        }
    }

    async function handleOpenOnThisDevice(): Promise<void> {
        try {
            dispatch(setView(VIEWS.PENDING));
            trackingMethod.current = TRACKING_METHODS.SSN;
            onEvent?.({ event: EVENTS.OPEN_ON_THIS_DEVICE_CLICKED });

            const auto_start_token = (await requestInitialData())?.data?.auto_start_token;

            if (!auto_start_token) {
                throw new Error('No auto_start_token');
            }

            const testingType = action === ACTIONS.AUTH ? TESTING_TYPE.IDENTIFY : TESTING_TYPE.SIGN;
            const redirectUrl = createReturnUrl(device, testingType);
            const bankIdAppUrl = getBankIdAppUrl(auto_start_token, redirectUrl);

            window.open(bankIdAppUrl, '_top');
        } catch (error) {
            handleOnError(error as Error);
        }
    }

    function setInitialView(): void {
        if (device.isMobileOrTablet) {
            dispatch(setView(VIEWS.DEVICE_PROMPT));
        } else {
            initQrCodeView();
        }
    }

    function handleSsnSubmit(ssn: string): void {
        dispatch(setView(VIEWS.PENDING));

        try {
            dispatch(requestAction({ endpoint, ssn }));
        } catch (error) {
            handleOnError(error as Error);
        }
    }

    function handleOnError(error: Error | ErrorResponseData): void {
        clearTimeout(timeoutRef.current);
        dispatch(setView(VIEWS.ERROR));

        const responseError = error as ErrorResponseData;
        const jsError = error as Error;

        // Making TS happy.
        //  Cant use optional chaining with 'unknown' type
        let responseErrorDataMessage = '';
        const responseErrorData = 'data' in responseError ? responseError.data : {};
        if (responseErrorData && typeof responseErrorData === 'object' && 'message' in responseErrorData) {
            responseErrorDataMessage = responseErrorData.message as string;
        }

        const statusCode = responseError?.statusCode?.toString();

        const errorMessage = responseErrorDataMessage || statusCode || jsError?.message;

        onEvent?.({
            event: EVENTS.ERROR_OCCURRED,
            payload: { error: errorMessage, trackingMethod: trackingMethod.current! },
        });

        onError?.(error);
    }

    function triggerPoll(ref: string): void {
        timeoutRef.current = setTimeout(() => startPolling(ref), POLL_TIMEOUT_TIME);
    }

    function handleRetry(): void {
        dispatch(resetState(initialView));
        onRetry?.();
        onEvent?.({ event: EVENTS.RETRY_CLICKED });

        if (!device.isMobileOrTablet) {
            requestInitialData();
        }
    }

    function handleOnCancel(): void {
        dispatch(resetState(initialView));
        clearTimeout(timeoutRef.current);
        onCancel?.();
        onEvent?.({ event: EVENTS.CANCEL_CLICKED });

        if (!device.isMobileOrTablet) {
            requestInitialData();
        }

        clearAndCancel();
    }

    function handleOnBack(): void {
        dispatch(resetState(initialView));
        clearTimeout(timeoutRef.current);
        onEvent?.({ event: EVENTS.BACK_CLICKED });

        if (!device.isMobileOrTablet) {
            onBack();
        }

        clearAndCancel();
    }

    function initQrCodeView(): void {
        trackingMethod.current = TRACKING_METHODS.QR;
        dispatch(setView(VIEWS.QR_CODE_PROMPT));
        requestInitialData();
    }

    function clearAndCancel(): void {
        clearTimeout(timeoutRef.current);

        if (postReference?.current) {
            dispatch(cancelAction({ endpoint, reference: postReference.current }));
            postReference.current = null;
        }
    }

    if (progress === PROGRESS.COMPLETE) {
        return (onVerified(details) as ReactElement) || null;
    }

    return (
        <>
            {view === VIEWS.SSN_PROMPT ? <SsnPrompt onSubmit={handleSsnSubmit} onBack={onBack} /> : null}

            {view === VIEWS.DEVICE_PROMPT ? (
                <DevicePrompt
                    action={action}
                    onBack={() => {
                        onEvent?.({ event: EVENTS.BACK_CLICKED });
                        onBack();
                    }}
                    openOnThisDevice={handleOpenOnThisDevice}
                    openOnAnotherDevice={() => {
                        onEvent?.({ event: EVENTS.OPEN_ON_ANOTHER_DEVICE_CLICKED });
                        initQrCodeView();
                    }}
                    onTooltipClicked={() => {
                        onEvent?.({ event: EVENTS.TOOLTIP_CLICKED });
                    }}
                />
            ) : null}

            {view === VIEWS.QR_CODE_PROMPT ? (
                <QrCodePrompt
                    qrData={qrData}
                    openOnThisDevice={handleOpenOnThisDevice}
                    isMobileOrTablet={device.isMobileOrTablet}
                    onBack={handleOnBack}
                />
            ) : null}

            {view === VIEWS.PENDING ? <Pending message="Väntar svar från BankID..." onCancel={handleOnCancel} /> : null}

            {view === VIEWS.POLLING ? (
                <Pending message="Skriv in din säkerhetskod i BankID-appen" onCancel={handleOnCancel} />
            ) : null}

            {view === VIEWS.ERROR ? (
                <ErrorView
                    title={TEXTS[action].errorViewTitle}
                    message="Vänligen försök igen."
                    onRetry={handleRetry}
                    onCancel={handleOnCancel}
                />
            ) : null}
        </>
    );
}
