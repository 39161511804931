import { IComplement } from 'APP/interfaces/private/offers.interfaces';

export const BusinessComplementTypes = {
    ONE_GUARANTOR: 'one_guarantor',
    TWO_GUARANTORS: 'two_guarantors',
    EU_LOAN_GUARANTOR: 'eu_loan_guarantor',
    EU_LOAN_100_GUARANTOR: 'eu_loan_100_guarantor',
    LIMITED_GUARANTOR: 'limited_guarantor',
    PL_STATEMENT: 'pl_statement',
    BALANCE_SHEET: 'balance_sheet',
    BANK_STATEMENTS: 'bank_statements',
    INSTANTOR_SCRAPING: 'instantor_scraping',
    BILLING_AND_ACCOUNTING_DATA: 'billing_and_accounting_data',
    ID_COPY: 'id_copy',
    AUTOGIRO: 'autogiro',
    BENEFICIAL_OWNER_AS_GUARANTOR: 'beneficial_owner_as_guarantor',
    COMPANY_MORTGAGE: 'company_mortgage',
    LIQUIDITY_BUDGET: 'liquidity_budget',
    PROPERTY_COLLATERAL: 'property_collateral',
    INVOICE_COLLATERAL: 'invoice_collateral',
    EXTERNAL_PROPERTY_VALUATION: 'external_property_valuation',
    PROPERTY_COLLATERAL_SEPARATE_COMPANY: 'property_collateral_separate_company',
};

export const BusinessComplementTypesMeta = {
    [BusinessComplementTypes.PL_STATEMENT]: { ranking: 1, mustBeVisible: false },
    [BusinessComplementTypes.BALANCE_SHEET]: { ranking: 2, mustBeVisible: false },
    [BusinessComplementTypes.ONE_GUARANTOR]: { ranking: 3, mustBeVisible: false },
    [BusinessComplementTypes.TWO_GUARANTORS]: { ranking: 3, mustBeVisible: false },
    [BusinessComplementTypes.EU_LOAN_GUARANTOR]: { ranking: 3, mustBeVisible: false },
    [BusinessComplementTypes.EU_LOAN_100_GUARANTOR]: { ranking: 3, mustBeVisible: false },
    [BusinessComplementTypes.LIMITED_GUARANTOR]: { ranking: 3, mustBeVisible: false },
    [BusinessComplementTypes.BENEFICIAL_OWNER_AS_GUARANTOR]: { ranking: 3, mustBeVisible: false },
    [BusinessComplementTypes.INSTANTOR_SCRAPING]: { ranking: 4, mustBeVisible: false },
    [BusinessComplementTypes.BANK_STATEMENTS]: { ranking: 5, mustBeVisible: false },
    [BusinessComplementTypes.BILLING_AND_ACCOUNTING_DATA]: { ranking: 6, mustBeVisible: false },
    [BusinessComplementTypes.LIQUIDITY_BUDGET]: { ranking: 7, mustBeVisible: false },
    [BusinessComplementTypes.PROPERTY_COLLATERAL]: { ranking: 8, mustBeVisible: false },
    [BusinessComplementTypes.INVOICE_COLLATERAL]: { ranking: 8, mustBeVisible: false },
    [BusinessComplementTypes.COMPANY_MORTGAGE]: { ranking: 8, mustBeVisible: false },
    [BusinessComplementTypes.AUTOGIRO]: { ranking: 9, mustBeVisible: false },
    [BusinessComplementTypes.ID_COPY]: { ranking: 10, mustBeVisible: false },
    [BusinessComplementTypes.EXTERNAL_PROPERTY_VALUATION]: { ranking: 10, mustBeVisible: false },
    [BusinessComplementTypes.PROPERTY_COLLATERAL_SEPARATE_COMPANY]: { ranking: 10, mustBeVisible: false },
};

const shortBusinessComplementTexts = {
    [BusinessComplementTypes.ONE_GUARANTOR]: 'En borgensman',
    [BusinessComplementTypes.TWO_GUARANTORS]: 'Två borgensmän',
    [BusinessComplementTypes.EU_LOAN_GUARANTOR]: '20% borgensman',
    [BusinessComplementTypes.LIMITED_GUARANTOR]: 'Begränsad borgen',
    [BusinessComplementTypes.PL_STATEMENT]: 'Resultaträkning',
    [BusinessComplementTypes.BALANCE_SHEET]: 'Balansräkning',
    [BusinessComplementTypes.BANK_STATEMENTS]: 'Utdrag från företagets bankkontotransaktioner',
    [BusinessComplementTypes.INSTANTOR_SCRAPING]: 'Digital bankkontoanalys',
    [BusinessComplementTypes.BILLING_AND_ACCOUNTING_DATA]: 'Bokföringsunderlag',
    [BusinessComplementTypes.ID_COPY]: 'Kopia på id-handling',
    [BusinessComplementTypes.AUTOGIRO]: 'Endast autogirobetalning',
    [BusinessComplementTypes.BENEFICIAL_OWNER_AS_GUARANTOR]: 'Personlig borgen av verklig huvudman',
    [BusinessComplementTypes.COMPANY_MORTGAGE]: 'Företagsinteckning',
    [BusinessComplementTypes.LIQUIDITY_BUDGET]: 'Enkel budget',
    [BusinessComplementTypes.PROPERTY_COLLATERAL]: 'Fastighetspant krävs för detta lån',
    [BusinessComplementTypes.INVOICE_COLLATERAL]: 'Fakturor som pant',
    [BusinessComplementTypes.EXTERNAL_PROPERTY_VALUATION]: 'Extern fastighetsvärdering',
    [BusinessComplementTypes.PROPERTY_COLLATERAL_SEPARATE_COMPANY]: 'Fastighetspant via separat bolag',
    [BusinessComplementTypes.EU_LOAN_100_GUARANTOR]: '100% borgensman',
};

const longBusinessComplementTexts = {
    [BusinessComplementTypes.ONE_GUARANTOR]:
        'En person behöver gå i personlig borgen, en kreditupplysning kommer tas på personen.',
    [BusinessComplementTypes.EU_LOAN_100_GUARANTOR]:
        'En person behöver gå i personlig borgen, en kreditupplysning kommer tas på personen.',
    [BusinessComplementTypes.TWO_GUARANTORS]:
        'Två personer behöver gå i personlig borgen, en kreditupplysning kommer tas på dessa personerna.',
    [BusinessComplementTypes.EU_LOAN_GUARANTOR]:
        'Endast 20% personlig borgen av lånebeloppet krävs. En kreditupplysning kommer tas på personen.',
    [BusinessComplementTypes.LIMITED_GUARANTOR]:
        'Begränsad personlig borgen av lånebeloppet krävs, från 30% av lånebeloppet. En kreditupplysning kommer tas på personen.',
    [BusinessComplementTypes.PL_STATEMENT]: 'Företagets senaste Resultaträkning.',
    [BusinessComplementTypes.BALANCE_SHEET]: 'Företagets senaste Balansräkning.',
    [BusinessComplementTypes.BANK_STATEMENTS]: 'Kontoutdrag hämtat från din bank.',
    [BusinessComplementTypes.INSTANTOR_SCRAPING]:
        'Inhämtning av transaktionshistorik från företagets konto, godkännande ges via BankID.',
    [BusinessComplementTypes.BILLING_AND_ACCOUNTING_DATA]: 'Koppla på företagets bokföringssystem hos långivaren.',
    [BusinessComplementTypes.ID_COPY]: 'Kopia på giltig ID-handling.',
    [BusinessComplementTypes.AUTOGIRO]: 'Autogiro betalning av kostnaderna för lånet krävs.',
    [BusinessComplementTypes.BENEFICIAL_OWNER_AS_GUARANTOR]:
        'Bolagets Verkliga Huvudman behöver gå i personlig borgen, en kreditupplysning kommer tas på personen.',
    [BusinessComplementTypes.COMPANY_MORTGAGE]:
        'Som säkerhet för lånet krävs en företagsinteckning, inteckningen måste vara registrerad hos och godkänd av bolagsverket.',
    [BusinessComplementTypes.LIQUIDITY_BUDGET]: 'Enkel budget för kommande 12 månader',
    [BusinessComplementTypes.PROPERTY_COLLATERAL]: 'Fastighetspant krävs för detta lån',
    [BusinessComplementTypes.INVOICE_COLLATERAL]: 'Pant av ditt företags kundfakturor krävs som säkerhet.',
    [BusinessComplementTypes.EXTERNAL_PROPERTY_VALUATION]: 'Extern fastighetsvärdering krävs.',
    [BusinessComplementTypes.PROPERTY_COLLATERAL_SEPARATE_COMPANY]:
        'Fastighetspant krävs via separat bolag där sökande har bolagsengagemang.',
};

interface ComplementType {
    type: string;
}

function sortByRank(a: ComplementType, b: ComplementType): number {
    const aMeta = BusinessComplementTypesMeta[a.type];
    const bMeta = BusinessComplementTypesMeta[b.type];
    if (!aMeta) {
        return 1;
    } else if (!bMeta) {
        return -1;
    } else if (aMeta.ranking < bMeta.ranking) {
        return -1;
    } else if (aMeta.ranking > bMeta.ranking) {
        return 1;
    } else {
        return 0;
    }
}

export interface IFileUploadComplements {
    text: string;
    type: string;
    meta: {
        ranking: number;
        mustBeVisible: boolean;
    };
}
export function getShortBusinessComplementsTexts(complements: IComplement[]): IFileUploadComplements[] {
    const complementsList = [...complements];
    return complementsList
        .sort(sortByRank)
        .map(complement => ({
            text: shortBusinessComplementTexts[complement.type],
            type: complement.type,
            meta: BusinessComplementTypesMeta[complement.type],
        }))
        .filter(complement => Boolean(complement.text) && Boolean(complement.type) && Boolean(complement.meta));
}

export function getLongBusinessComplementsTexts({ complements }: { complements: IComplement[] }): string[] {
    const complementsList = [...complements];
    return complementsList
        .sort(sortByRank)
        .map(complement => longBusinessComplementTexts[complement.type])
        .filter(complement => Boolean(complement));
}
