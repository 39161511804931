import { Box, Button } from '@lendoab/aphrodite';
import { useAuth } from 'APP/Authentication';
import { classNames } from 'APP/helpers/css';
import React, { ReactElement } from 'react';

import styles from './SubNav.module.scss';

export interface IMobileSubNavBarProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    isAuthenticated: boolean;
}
export function SubNav(props: IMobileSubNavBarProps): ReactElement {
    return <SubNavMobile {...props} />;
}

function SubNavMobile(props: IMobileSubNavBarProps): ReactElement {
    const { open, setOpen, isAuthenticated } = props;
    const subNavMobileClassNames = classNames(styles.subNavMobile, open && styles.open);

    return (
        <Box display={['block', 'none', 'none']} className={subNavMobileClassNames}>
            <Box fluid className={styles.content}>
                {isAuthenticated && (
                    <Box
                        display={['block', 'none']}
                        marginX="medium"
                        paddingY="medium"
                        style={{ borderBottom: '1px solid #999999' }}
                    >
                        <LogOutButton onClick={(): void => setOpen(false)} />
                    </Box>
                )}
                <div className={styles.gradient}></div>
            </Box>
        </Box>
    );
}

function LogOutButton(props: { onClick: () => void }): ReactElement {
    const { onClick } = props;
    const { unAuthenticate } = useAuth();

    function handleClick(): void {
        unAuthenticate();
        onClick();
    }

    return (
        <Button onClick={handleClick} size="medium" primary fluid>
            Logga ut
        </Button>
    );
}
